import React from "react";

const Google = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6562 8.92969C17.6562 8.36719 17.5859 7.94531 17.5156 7.48828H9.21875V10.4766H14.1406C13.9648 11.7773 12.6641 14.2383 9.21875 14.2383C6.23047 14.2383 3.80469 11.7773 3.80469 8.71875C3.80469 3.83203 9.57031 1.58203 12.6641 4.57031L15.0547 2.28516C13.543 0.878906 11.5391 0 9.21875 0C4.36719 0 0.5 3.90234 0.5 8.71875C0.5 13.5703 4.36719 17.4375 9.21875 17.4375C14.2461 17.4375 17.6562 13.9219 17.6562 8.92969Z"
        fill="#111928"
      />
    </svg>
  );
};

export default Google;
