import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicyPage = () => {
  return (
    <div className="bg-white rounded-md shadow">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <h2 className="mb-8 text-4xl tracking-tight font-extrabold text-gray-900">
          Privacy policy
        </h2>
        <div className="grid pt-8 text-left border-t border-gray-200 md:gap-16 md:grid-cols-2">
          <div>
            <div className="mb-10">
              <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900">
                Introduction
              </h3>
              <p className="text-gray-500 ">
                This Privacy Policy will help you better understand how we
                collect, use, and share your personal information. If we change
                our privacy practices, we may update this privacy policy. If any
                changes are significant, we will let you know.
              </p>
            </div>
            <div className="mb-10">
              <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 ">
                Our values
              </h3>
              <div className="text-gray-500">
                Trust is the foundation of the Hefe platform and includes
                trusting us to do the right thing with your information. Three
                main values guide us as we develop our products and services.
                These values should help you better understand how we think
                about your information and privacy.
                <br />
                <br />
                <p>
                  - we carefully analyze what types of information we need to
                  provide our services, and we try to limit the information we
                  collect to only what we really need. Where possible, we delete
                  or anonymize this information when we no longer need it. When
                  building and improving our products, our engineers work
                  closely with our privacy and security teams to build with
                  privacy in mind. In all of this work our guiding principle is
                  that your information belongs to you, and we aim to only use
                  your information to your benefit.
                </p>
                <p>
                  - if a third party requests your personal information, we will
                  refuse to share it unless you give us permission or we are
                  legally required. When we are legally required to share your
                  personal information, we will tell you in advance, unless we
                  are aalegally forbidden.
                </p>
              </div>
            </div>
            <div className="mb-10">
              <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900">
                Why we process your information
              </h3>
              <p className="text-gray-500">
                We generally process your information when we need to do so to
                fulfill a contractual obligation , or where we or someone we
                work with needs to use your personal information for a reason
                related to their business (for example, to provide you with a
                service). European law calls these reasons “legitimate
                interests.” These “legitimate interests” include:
              </p>
              <br />
              <p className="text-gray-500">
                - preventing risk and fraud <br />- answering questions or
                providing other types of support <br /> - helping merchants find
                and use apps through our app store
                <br />- providing and improving our products and services
                <br />- providing reporting and analytics
                <br />- testing out features or additional services
                <br />- assisting with marketing, advertising, or other
                communications
              </p>
              <br />
              <p className="text-gray-500">
                We only process personal information for these “legitimate
                interests” after considering the potential risks to your
                privacy—for example, by providing clear transparency into our
                privacy practices, offering you control over your personal
                information where appropriate, limiting the information we keep,
                limiting what we do with your information, who we send your
                information to, how long we keep your information, or the
                technical measures we use to protect your information.
              </p>

              <p className="text-gray-500">
                One of the ways in which we are able to help merchants using
                Hefe is by using techniques like “machine learning” (European
                law refers to this as “automated decision-making”) to help us
                improve our services. When we use machine learning, we either:
                (1) still have a human being involved in the process (and so are
                not fully automated); or (2) use machine learning in ways that
                don’t have significant privacy implications (for example,
                reordering how apps might appear when you visit the app store).
                <br />
                We may process your personal information where you have provided
                your consent. In particular, where we cannot rely on an
                alternative legal basis for processing, where your data is
                sourced and it already comes with consent or where we are
                required by law to ask for your consent in the context of some
                of our sales and marketing activities. At any time, you have a
                right to withdraw your consent by changing your communication
                choices, opting out from our communications or by contacting us.
              </p>
            </div>
            <div className="mb-10">
              <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900">
                Your rights over your information
              </h3>
              <p className="text-gray-500">
                We believe you should be able to access and control your
                personal information no matter where you live. Depending on how
                you use Hefe, you may have the right to request access to,
                correct, amend, delete, port to another service provider,
                restrict, or object to certain uses of your personal information
                (for example, direct marketing). We will not charge you more or
                provide you with a different level of service if you exercise
                any of these rights.
              </p>
              <p className="text-gray-500">
                If you are not happy with our response to a request, you can
                contact us to resolve the issue. You also have the right to
                contact your local data protection or privacy authority at any
                time.
              </p>
              <p className="text-gray-500">
                Finally, because there is no common understanding about what a
                “Do Not Track” signal is supposed to mean, we don’t respond to
                those signals in any particular way.
              </p>
            </div>
          </div>
          <div>
            <div className="mb-10">
              <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900">
                Where we send your information
              </h3>
              <p className="text-gray-500">
                We are a US company, but we work with and process data about
                individuals across the world. To operate our business, we may
                send your personal information outside of your state or country.
                This data may be subject to the laws of the countries where we
                send it. When we send your information across borders, we take
                steps to protect your information, and we try to only send your
                information to countries that have strong data protection laws.
              </p>
            </div>
            <div className="mb-10">
              <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900">
                How we protect your information
              </h3>
              <p className="text-gray-500">
                Our teams work tirelessly to protect your information, and to
                ensure the security and integrity of our platform. We also have
                independent auditors assess the security of our data storage and
                systems that process financial information. However, we all know
                that no method of transmission over the Internet, and method of
                electronic storage, can be 100% secure. This means we cannot
                guarantee the absolute security of your personal information.
              </p>
            </div>
            <div className="mb-10">
              <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900">
                Changes to this Policy
              </h3>
              <p className="text-gray-500">
                We may update this Privacy Policy from time to time. We will
                notify our users of any changes by posting the updated policy on
                our website.
              </p>
            </div>
            <div className="mb-10">
              <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900">
                Contact Us
              </h3>
              <p className="text-gray-500">
                If you have any questions or concerns about this Privacy Policy,
                please{" "}
                <Link
                  to="/contact-us"
                  className="text-primary-100 font-semibold"
                >
                  contact us
                </Link>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
