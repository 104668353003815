import React from "react";

const BlackBadgeCalendar = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.79998 0.600098C3.5878 0.600098 3.38432 0.684383 3.23429 0.834412C3.08426 0.984441 2.99998 1.18792 2.99998 1.4001V2.2001H2.19998C1.77563 2.2001 1.36866 2.36867 1.0686 2.66873C0.768546 2.96878 0.599976 3.37575 0.599976 3.8001V11.8001C0.599976 12.2244 0.768546 12.6314 1.0686 12.9315C1.36866 13.2315 1.77563 13.4001 2.19998 13.4001H11.8C12.2243 13.4001 12.6313 13.2315 12.9313 12.9315C13.2314 12.6314 13.4 12.2244 13.4 11.8001V3.8001C13.4 3.37575 13.2314 2.96878 12.9313 2.66873C12.6313 2.36867 12.2243 2.2001 11.8 2.2001H11V1.4001C11 1.18792 10.9157 0.984441 10.7657 0.834412C10.6156 0.684383 10.4121 0.600098 10.2 0.600098C9.9878 0.600098 9.78432 0.684383 9.63429 0.834412C9.48426 0.984441 9.39997 1.18792 9.39997 1.4001V2.2001H4.59998V1.4001C4.59998 1.18792 4.51569 0.984441 4.36566 0.834412C4.21563 0.684383 4.01215 0.600098 3.79998 0.600098ZM3.79998 4.6001C3.5878 4.6001 3.38432 4.68438 3.23429 4.83441C3.08426 4.98444 2.99998 5.18792 2.99998 5.4001C2.99998 5.61227 3.08426 5.81575 3.23429 5.96578C3.38432 6.11581 3.5878 6.2001 3.79998 6.2001H10.2C10.4121 6.2001 10.6156 6.11581 10.7657 5.96578C10.9157 5.81575 11 5.61227 11 5.4001C11 5.18792 10.9157 4.98444 10.7657 4.83441C10.6156 4.68438 10.4121 4.6001 10.2 4.6001H3.79998Z"
        fill="#1F2A37"
      />
    </svg>
  );
};

export default BlackBadgeCalendar;
