import React from "react";

const OpenEye = ({ setIsVisiblePassword }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute top-11 right-4"
      onClick={() => setIsVisiblePassword(false)}
      style={{ cursor: "pointer" }}
    >
      <path
        d="M10.591 10.591C11.0129 10.169 11.25 9.59674 11.25 9C11.25 8.40326 11.0129 7.83097 10.591 7.40901C10.169 6.98705 9.59674 6.75 9 6.75C8.40326 6.75 7.83097 6.98705 7.40901 7.40901C6.98705 7.83097 6.75 8.40326 6.75 9C6.75 9.59674 6.98705 10.169 7.40901 10.591C7.83097 11.0129 8.40326 11.25 9 11.25C9.59674 11.25 10.169 11.0129 10.591 10.591Z"
        stroke="#374151"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.84326 9C2.79876 5.95725 5.64201 3.75 8.99976 3.75C12.3583 3.75 15.2008 5.95725 16.1563 9C15.2008 12.0428 12.3583 14.25 8.99976 14.25C5.64201 14.25 2.79876 12.0428 1.84326 9Z"
        stroke="#1F2A37"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default OpenEye;
