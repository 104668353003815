import React from "react";

const TotalDonationAnalyticIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="8" fill="#206B9F" fillOpacity="0.1" />
      <path
        d="M9 18V21C9 22.657 12.134 24 16 24C19.866 24 23 22.657 23 21V18C23 19.657 19.866 21 16 21C12.134 21 9 19.657 9 18Z"
        fill="#206B9F"
      />
      <path
        d="M9 13V16C9 17.657 12.134 19 16 19C19.866 19 23 17.657 23 16V13C23 14.657 19.866 16 16 16C12.134 16 9 14.657 9 13Z"
        fill="#206B9F"
      />
      <path
        d="M23 11C23 12.657 19.866 14 16 14C12.134 14 9 12.657 9 11C9 9.343 12.134 8 16 8C19.866 8 23 9.343 23 11Z"
        fill="#206B9F"
      />
    </svg>
  );
};

export default TotalDonationAnalyticIcon;
