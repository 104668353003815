import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  changeDonorPassword,
  changeFundraiserPassword,
} from "../../store/reducers/resetPasswordSlice";
import BackArrow from "../../svg/arrows/BackArrow";
import userRoles from "../../constans/userRoles";

const EnterEmail = ({ emailForReset, setEmailForReset }) => {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const userRole = localStorage.getItem("userRole");
  const dispatch = useDispatch();

  const onSuccessSubmit = () => {
    navigate("/resetPassword/verification");
  };

  const onFailure = () => {
    setError("This email does not exist.");
  };

  const submit = () => {
    if (userRole.includes(userRoles.DONOR)) {
      dispatch(
        changeDonorPassword({
          email: { email: emailForReset },
          onSuccess: () => onSuccessSubmit(),
          onFailure: () => onFailure(),
        }),
      );
    }
    if (userRole.includes(userRoles.FUNDRAISER)) {
      dispatch(
        changeFundraiserPassword({
          email: { email: emailForReset },
          onSuccess: () => onSuccessSubmit(),
          onFailure: () => onFailure(),
        }),
      );
    }
  };

  return (
    <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
      <div className="space-y-6 p-6">
        <button
          className="flex items-center cursor-pointer"
          type="button"
          onClick={() => navigate(-1)}
        >
          <BackArrow />
          <h3 className="pl-4 font-medium text-base text-gray-800">Back</h3>
        </button>
        <div>
          <h1 className="text-2xl font-bold leading-tight tracking-tight text-gray-900 pb-2">
            Reset password
          </h1>
          <h2 className="font-medium text-base text-gray-500 text">
            We’ll email you instructions to reset your password.
          </h2>
        </div>
        {error && (
          <div
            className="p-4 mb-6 text-sm text-red-700 bg-red-100 rounded-lg"
            role="alert"
          >
            <span className="font-medium">{error}</span>
          </div>
        )}
        <div>
          <label
            htmlFor="email"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Email
          </label>
          <input
            type="email"
            name="email"
            onChange={(e) => setEmailForReset(e.target.value)}
            value={emailForReset}
            className="text-base bg-gray-50 border border-gray-300 text-gray-500 rounded-lg focus:ring-gray-300 focus:border-gray-300 block w-full p-2.5"
            placeholder="Enter your email"
            required
          />
        </div>
        <button
          type="submit"
          className={
            !emailForReset || !emailForReset.match(/.+@.+\..+/i)
              ? "w-full focus:outline-none text-gray-900 bg-gray-300 hover:bg-gray-400 font-medium rounded-lg text-base py-3 mb-4"
              : "w-full focus:outline-none text-white bg-primary-100 hover:bg-green-500 font-medium rounded-lg text-base py-3 mb-4"
          }
          disabled={!emailForReset || !emailForReset.match(/.+@.+\..+/i)}
          onClick={() => {
            submit();
          }}
        >
          Reset password
        </button>
      </div>
    </div>
  );
};

export default EnterEmail;
