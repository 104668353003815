import React from "react";

const TopDonateCountry = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="8" fill="#206B9F" fillOpacity="0.1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 24C18.1217 24 20.1566 23.1571 21.6569 21.6569C23.1571 20.1566 24 18.1217 24 16C24 13.8783 23.1571 11.8434 21.6569 10.3431C20.1566 8.84285 18.1217 8 16 8C13.8783 8 11.8434 8.84285 10.3431 10.3431C8.84285 11.8434 8 13.8783 8 16C8 18.1217 8.84285 20.1566 10.3431 21.6569C11.8434 23.1571 13.8783 24 16 24ZM10.332 14.027C10.7033 12.9637 11.3658 12.0261 12.244 11.321C12.512 11.73 12.974 12 13.5 12C13.8978 12 14.2794 12.158 14.5607 12.4393C14.842 12.7206 15 13.1022 15 13.5V14C15 14.5304 15.2107 15.0391 15.5858 15.4142C15.9609 15.7893 16.4696 16 17 16C17.5304 16 18.0391 15.7893 18.4142 15.4142C18.7893 15.0391 19 14.5304 19 14C18.9998 13.5524 19.1498 13.1177 19.4259 12.7655C19.702 12.4132 20.0883 12.1637 20.523 12.057C21.4773 13.1487 22.0022 14.55 22 16C22 16.34 21.972 16.675 21.917 17H21C20.4696 17 19.9609 17.2107 19.5858 17.5858C19.2107 17.9609 19 18.4696 19 19V21.197C18.0883 21.7245 17.0533 22.0015 16 22V20C16 19.4696 15.7893 18.9609 15.4142 18.5858C15.0391 18.2107 14.5304 18 14 18C13.4696 18 12.9609 17.7893 12.5858 17.4142C12.2107 17.0391 12 16.5304 12 16C12.0002 15.527 11.8327 15.0692 11.5273 14.708C11.222 14.3468 10.7984 14.1055 10.332 14.027Z"
        fill="#206B9F"
      />
    </svg>
  );
};

export default TopDonateCountry;
