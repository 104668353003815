/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { useNavigate } from "react-router-dom";
import { formatDistanceToNowStrict, fromUnixTime } from "date-fns";
import Img from "react-cool-img";
import BlackBadgeCalendar from "../../svg/badgeCalendar/BlackBadgeCalendar";
import LocationMarker from "../../svg/locationMarker/LocationMarker";
import Logo from "../../svg/logo/Logo";

const ExploreFundraiseCard = ({ fundraise }) => {
  const navigate = useNavigate();

  return (
    <div className="2xl:w-[345px] lg:w-[300px] md:w-[280px] w-full h-full md:h-auto cursor-pointer">
      <div
        className="relative"
        onClick={() => navigate(`/explore/fundraise/${fundraise.id}`)}
      >
        {fundraise?.photos?.length ? (
          <Img
            src={fundraise?.photos[0]?.url}
            alt="fundraise img"
            className="rounded-t-lg h-[168px] object-cover w-full"
          />
        ) : (
          <div className="rounded-t-lg h-[168px] w-full bg-white flex items-center justify-center">
            <Logo />
          </div>
        )}

        {fundraise?.categories?.length ? (
          <div className="absolute bottom-[10px] left-[25px] flex gap-5">
            <span className="text-sm font-normal text-white bg-primary-100 px-2.5 rounded-md select-none flex items-center">
              {fundraise?.categories[0]?.name}
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="px-6 bg-white rounded-b-lg shadow flex flex-col">
        <div onClick={() => navigate(`/explore/fundraise/${fundraise.id}`)}>
          <div className="flex flex-col gap-2 items-start pb-3 pt-5">
            <div className="flex items-center">
              {fundraise?.organizationCountry ? (
                <span className="text-sm font-medium text-gray-800 bg-gray-100 px-2.5 py-0.5 rounded-md select-none flex items-center gap-2">
                  <LocationMarker />
                  {fundraise?.organizationCountry || "No country"}
                </span>
              ) : (
                <span className="text-sm font-medium text-gray-800 bg-gray-100 px-2.5 py-0.5 rounded-md select-none flex items-center gap-2">
                  <LocationMarker />
                  {fundraise?.fundraiser?.country || "No country"}
                </span>
              )}
            </div>

            <div className="flex items-center">
              {fundraise.deadlineDays ? (
                <span className="text-sm font-medium text-gray-800 bg-gray-100 px-2.5 py-0.5 rounded-md select-none flex items-center gap-2">
                  <BlackBadgeCalendar />
                  {fundraise.deadlineDays} days left
                </span>
              ) : (
                <span className="text-sm font-medium text-gray-800 bg-gray-100 px-2.5 py-0.5 rounded-md select-none flex items-center gap-2">
                  <BlackBadgeCalendar />
                  {formatDistanceToNowStrict(
                    new Date(fromUnixTime(fundraise.deadlineTimestamp)),
                  )}{" "}
                  left
                </span>
              )}
            </div>
          </div>

          <div className="mb-5 ">
            <h2 className="font-normal text-xl text-gray-800 text-ellipsis whitespace-nowrap overflow-hidden">
              {fundraise.title}
            </h2>
            <h3 className="font-normal text-base text-gray-800 text-ellipsis whitespace-nowrap overflow-hidden">
              {fundraise.description}
            </h3>
          </div>
          <div className="flex items-center gap-2 mb-4">
            <h2 className="text-gray-800 font-semibold text-base">
              {Number(fundraise.fundraise.raisedAmount).toFixed(3)}{" "}
              {fundraise?.fundraise.currency}
            </h2>
            <h2 className="text-sm font-normal text-gray-500">
              raised of {fundraise.goal}
            </h2>
          </div>
          <div className="w-full bg-gray-100 mb-5 rounded-md">
            <div
              className="bg-primary-100 h-1.5 rounded-md"
              style={{
                width: `${
                  (fundraise.fundraise.raisedAmount * 100) /
                  fundraise.fundraise.goal
                }%`,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreFundraiseCard;
