/* eslint-disable */
import React, { useState } from "react";
import { DayPicker, Row } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import FundingInfoValidation from "./config/FundingInfoValidation";
import {
  format,
  differenceInCalendarDays,
  getUnixTime,
  fromUnixTime,
} from "date-fns";
import deadlineOptions from "../../constans/deadlineOptions";
import createFundraiseSteps from "../../constans/createFundraiseSteps";
import { setFundingInfo } from "../../store/reducers/fundraiseSlice";

const FundingInfo = ({ setCreateFundraiseStep }) => {
  const [goalType, setGoalType] = useState("");
  const [isVisibleDatePicker, setIsVisibleDatePicker] = useState(false);
  const [deadlineTimestamp, setDeadlineTimestamp] = useState(0);
  const [deadlineType, setDeadlineType] = useState("");

  const [goalCurrency, setGoalCurrency] = useState("ETH");

  const dispatch = useDispatch();

  const datePickerCss = `
  .my-selected:not([disabled]) { 
    font-weight: bold; 
    border: #29B277;
    color: white;
    background-color: #29B277;
  } 
  .my-today { 
    font-weight: bold;
  }
`;

  const isPastDate = (date) => {
    return differenceInCalendarDays(date, new Date()) < 0;
  };

  const OnlyFutureRow = (props) => {
    const isPastRow = props.dates.every(isPastDate);
    if (isPastRow) return <></>;
    return <Row {...props} />;
  };

  return (
    <div className="relative p-4 w-full max-w-3xl h-full md:h-auto">
      <div className="relative p-4 bg-white rounded-lg shadow sm:p-5">
        <Formik
          initialValues={{
            goal: "",
            deadlineDays: 0,
          }}
          onSubmit={(values) => {
            dispatch(
              setFundingInfo({
                goal: values.goal,
                goalCurrency,
                goalType,
                deadlineType,
                deadlineDays: values.deadlineDays,
                deadlineTimestamp,
              }),
            );
            setCreateFundraiseStep(createFundraiseSteps.PREVIEW);
          }}
          validationSchema={FundingInfoValidation}
        >
          {({ values, handleChange, handleBlur, errors }) => (
            <Form>
              <>
                <div className="flex-col">
                  <div className="mb-6">
                    <label
                      htmlFor="goal"
                      className="block text-base font-medium text-gray-800 mb-2"
                    >
                      Amount
                    </label>
                    <div className="flex relative">
                      <input
                        type="text"
                        name="goal"
                        className="bg-gray-50 border border-gray-300 text-gray-400 text-base rounded-lg focus:ring-gray-300 focus:border-gray-300 block w-full p-2.5 "
                        placeholder="Enter goal amount"
                        required=""
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.goal}
                      />
                      <select
                        value={goalCurrency}
                        onChange={(e) => setGoalCurrency(e.target.value)}
                        className="right-0 w-[85px] h-full absolute bg-gray-50 border border-gray-300 text-gray-400 text-base inline-flex items-center text-base text-gray-800 font-medium px-3 bg-gray-100 border-l-0 rounded-r-lg focus:ring-0 focus:border-gray-300"
                      >
                        <option value={"ETH"}>ETH</option>
                        <option value={"USDT"}>USDT</option>
                        <option value={"USDC"}>USDC</option>
                      </select>
                    </div>
                  </div>
                  <ul className="mb-6">
                    <li>
                      <label
                        className="flex p-5 rounded bg-gray-50 items-center mb-6 cursor-pointer border border-gray-50 hover:border-primary-100"
                        onClick={() => setGoalType("FLEXIBLE")}
                        htmlFor="helper-radio-1"
                      >
                        <input
                          id="helper-radio-1"
                          name="helper-radio"
                          type="radio"
                          value=""
                          className="w-4 h-4 text-primary-100 focus:ring-primary-100 cursor-pointer"
                        />
                        <div className="ml-4">
                          <p className="text-xl font-semibold text-gray-800">
                            Flexible goal
                          </p>
                          <p
                            id="helper-radio-text"
                            className="font-medium text-base text-gray-500"
                          >
                            Any amount fundraised will go to the fundraiser,
                            whether the goal is met or not.
                          </p>
                        </div>
                      </label>
                    </li>
                    <li>
                      <label
                        htmlFor="helper-radio-2"
                        className="flex p-5 rounded bg-gray-50 items-center cursor-pointer border border-gray-50 hover:border-primary-100"
                        onClick={() => setGoalType("FIXED")}
                      >
                        <input
                          id="helper-radio-2"
                          name="helper-radio"
                          type="radio"
                          value=""
                          className="w-4 h-4 text-primary-100 focus:ring-primary-100 cursor-pointer"
                        />
                        <div className="ml-4">
                          <p className="text-xl font-semibold text-gray-800">
                            Fixed goal
                          </p>
                          <p
                            id="helper-radio-text"
                            className="font-medium text-base text-gray-500"
                          >
                            Must fundraise entire amount declared in goal (or
                            more), or else the funds do not get released to the
                            fundraiser and are returned to the donors.
                          </p>
                        </div>
                      </label>
                    </li>
                  </ul>

                  <div className="col-span-2 pb-5">
                    <h3 className="block text-base mb-4 font-medium text-gray-900">
                      Fundraising deadline
                    </h3>
                    <div className="mb-4">
                      <input
                        id="helper-radio-3"
                        name="helper-radio-deadline"
                        type="radio"
                        value=""
                        className="w-4 h-4 text-primary-100 focus:ring-primary-100 cursor-pointer"
                        onClick={() =>
                          !isVisibleDatePicker && setDeadlineType("DAYS")
                        }
                        disabled={isVisibleDatePicker}
                      />
                      <label
                        className="ml-2 text-base font-medium text-gray-800 cursor-pointer"
                        onClick={() =>
                          !isVisibleDatePicker && setDeadlineType("DAYS")
                        }
                        htmlFor="helper-radio-3"
                      >
                        Run for
                      </label>
                      {deadlineType === "DAYS" && (
                        <select
                          name="deadlineDays"
                          value={values.deadlineDays}
                          onChange={(e) => {
                            setDeadlineTimestamp(0);
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          className="cursor-pointer mt-4 max-w-[360px] text-base bg-gray-50 border border-gray-300 text-gray-500 rounded-lg focus:ring-gray-300 focus:border-gray-300 block w-full p-2.5"
                        >
                          <option value="" className="text-base text-gray-800">
                            Number of days for the fundraising
                          </option>
                          {deadlineOptions.map((days) => (
                            <option
                              key={days.select}
                              value={days.select}
                              className="text-base text-gray-800 hover:bg-primary-100"
                            >
                              {days.select} days
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                    <div className="relative">
                      <input
                        id="helper-radio-4"
                        name="helper-radio-deadline"
                        type="radio"
                        value=""
                        className="w-4 h-4 text-primary-100 focus:ring-primary-100 cursor-pointer"
                        onClick={() => setDeadlineType("DATE")}
                      />
                      <label
                        htmlFor="helper-radio-4"
                        className="ml-2 text-base font-medium text-gray-800 cursor-pointer"
                        onClick={() => setDeadlineType("DATE")}
                      >
                        Run until
                      </label>
                      {deadlineType === "DATE" && (
                        <div
                          onClick={() => setIsVisibleDatePicker(true)}
                          className="cursor-pointer mt-4 max-w-[360px] relative py-[22px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full"
                        >
                          <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                            <svg
                              aria-hidden="true"
                              className="w-5 h-5 text-gray-500"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                clipRule="evenodd"
                              />
                            </svg>
                            <p className="p-3 text-base font-base text-gray-400">
                              {deadlineTimestamp
                                ? format(
                                    new Date(fromUnixTime(deadlineTimestamp)),
                                    "MM/dd/yyyy",
                                  )
                                : "Choose a date"}
                            </p>
                          </div>
                        </div>
                      )}
                      {isVisibleDatePicker && (
                        <div className="absolute bottom-[40px] left-[50px] bg-white rounded-lg shadow">
                          <style>{datePickerCss}</style>
                          <DayPicker
                            fromDate={new Date()}
                            components={{ Row: OnlyFutureRow }}
                            hidden={isPastDate}
                            showOutsideDays
                            mode="single"
                            selected={fromUnixTime(deadlineTimestamp)}
                            onSelect={(e) => {
                              values.deadlineDays = 0;
                              setDeadlineTimestamp(getUnixTime(e));
                            }}
                            modifiersClassNames={{
                              selected: "my-selected",
                              today: "my-today",
                            }}
                            modifiersStyles={{
                              disabled: { fontSize: "75%" },
                            }}
                          />
                          <div className="pb-4 flex gap-4 px-4">
                            <button
                              type="button"
                              className="py-2 w-full text-sm font-medium text-gray-800 focus:outline-none rounded-lg border border-gray-200 hover:bg-gray-100"
                              onClick={() => {
                                !deadlineTimestamp && setDeadlineTimestamp(0);
                                setIsVisibleDatePicker(false);
                              }}
                            >
                              Cancel
                            </button>

                            <button
                              type="submit"
                              className="py-2 w-full text-sm font-medium text-white focus:outline-none bg-primary-100 rounded-lg border border-gray-200"
                              onClick={() => setIsVisibleDatePicker(false)}
                            >
                              Ok
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="pt-5 bg-white flex justify-between">
                  <button
                    type="button"
                    className="py-2 px-5 text-sm font-medium text-gray-800 focus:outline-none rounded-lg border border-gray-200 hover:bg-gray-100"
                    onClick={() => {
                      setCreateFundraiseStep(createFundraiseSteps.GENERALINFO);
                    }}
                  >
                    Back
                  </button>

                  <button
                    type="submit"
                    className={
                      errors.goal ||
                      !goalType ||
                      (!deadlineTimestamp && errors.deadlineDays)
                        ? "py-2 px-5 text-sm font-medium text-white focus:outline-none bg-gray-300 rounded-lg border border-gray-200"
                        : "py-2 px-5 text-sm font-medium text-white focus:outline-none bg-primary-100 rounded-lg border border-gray-200"
                    }
                    disabled={
                      errors.goal ||
                      !goalType ||
                      (!deadlineTimestamp && errors.deadlineDays)
                    }
                  >
                    Continue
                  </button>
                </div>
              </>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default FundingInfo;
