import React from "react";

const AverageDonate = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="8" fill="#206B9F" fillOpacity="0.1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 9C8.73478 9 8.48043 9.10536 8.29289 9.29289C8.10536 9.48043 8 9.73478 8 10C8 10.2652 8.10536 10.5196 8.29289 10.7071C8.48043 10.8946 8.73478 11 9 11V19C9 19.5304 9.21071 20.0391 9.58579 20.4142C9.96086 20.7893 10.4696 21 11 21H13.586L12.293 22.293C12.1975 22.3852 12.1213 22.4956 12.0689 22.6176C12.0165 22.7396 11.9889 22.8708 11.9877 23.0036C11.9866 23.1364 12.0119 23.2681 12.0622 23.391C12.1125 23.5138 12.1867 23.6255 12.2806 23.7194C12.3745 23.8133 12.4861 23.8875 12.609 23.9378C12.7319 23.9881 12.8636 24.0134 12.9964 24.0123C13.1292 24.0111 13.2604 23.9835 13.3824 23.9311C13.5044 23.8787 13.6148 23.8025 13.707 23.707L16 21.414L18.293 23.707C18.4816 23.8892 18.7342 23.99 18.9964 23.9877C19.2586 23.9854 19.5094 23.8802 19.6948 23.6948C19.8802 23.5094 19.9854 23.2586 19.9877 22.9964C19.99 22.7342 19.8892 22.4816 19.707 22.293L18.414 21H21C21.5304 21 22.0391 20.7893 22.4142 20.4142C22.7893 20.0391 23 19.5304 23 19V11C23.2652 11 23.5196 10.8946 23.7071 10.7071C23.8946 10.5196 24 10.2652 24 10C24 9.73478 23.8946 9.48043 23.7071 9.29289C23.5196 9.10536 23.2652 9 23 9H9ZM20.707 13.707C20.8892 13.5184 20.99 13.2658 20.9877 13.0036C20.9854 12.7414 20.8802 12.4906 20.6948 12.3052C20.5094 12.1198 20.2586 12.0146 19.9964 12.0123C19.7342 12.01 19.4816 12.1108 19.293 12.293L16 15.586L14.707 14.293C14.5195 14.1055 14.2652 14.0002 14 14.0002C13.7348 14.0002 13.4805 14.1055 13.293 14.293L11.293 16.293C11.1975 16.3852 11.1213 16.4956 11.0689 16.6176C11.0165 16.7396 10.9889 16.8708 10.9877 17.0036C10.9866 17.1364 11.0119 17.2681 11.0622 17.391C11.1125 17.5139 11.1867 17.6255 11.2806 17.7194C11.3745 17.8133 11.4861 17.8875 11.609 17.9378C11.7319 17.9881 11.8636 18.0134 11.9964 18.0123C12.1292 18.0111 12.2604 17.9835 12.3824 17.9311C12.5044 17.8787 12.6148 17.8025 12.707 17.707L14 16.414L15.293 17.707C15.4805 17.8945 15.7348 17.9998 16 17.9998C16.2652 17.9998 16.5195 17.8945 16.707 17.707L20.707 13.707Z"
        fill="#206B9F"
      />
    </svg>
  );
};

export default AverageDonate;
