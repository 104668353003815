import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "flowbite-react";
import { ModalHeader } from "flowbite-react/lib/esm/components/Modal/ModalHeader";
import { ModalBody } from "flowbite-react/lib/esm/components/Modal/ModalBody";
import countries from "../../constans/countries";
import fundraiseTypes from "../../constans/fundraiseTypes";
import {
  clearFilters,
  getAllFundraisers,
  setFiltersByAll,
} from "../../store/reducers/fundraiseSlice";

const FilterModal = ({ isOpenModal, setIsOpenModal }) => {
  const filtersFromRTK = useSelector((store) => store.fundraise.filtersByAll);
  const [country, setCountry] = useState("");
  const [categoriesIds, setCategoriesIds] = useState(
    filtersFromRTK.categoriesIds,
  );
  const [ownerType, setOwnerType] = useState(filtersFromRTK.ownerType);
  const [closeToGoal, setCloseToGoal] = useState(filtersFromRTK.closeToGoal);
  const [expiresInNext24Hours, setExpiresInNext24Hours] = useState(
    filtersFromRTK.expiresInNext24Hours,
  );

  const categoriesDB = useSelector((state) => state.fundraise.categories);
  const searchFundraiseTitleValue = useSelector(
    (state) => state.fundraise.filtersByAll.searchByTitleValue,
  );

  const dispatch = useDispatch();

  return (
    <Modal show={isOpenModal} size="2xl" onClose={() => setIsOpenModal(false)}>
      <ModalHeader>
        <div className="flex items-center">
          <button
            type="button"
            className="text-base font-normal mr-[210px]"
            onClick={() => {
              setCountry("");
              setCategoriesIds([]);
              setOwnerType("");
              setCloseToGoal(false);
              setExpiresInNext24Hours(false);
              dispatch(clearFilters());
              dispatch(
                getAllFundraisers({
                  search: searchFundraiseTitleValue,
                }),
              );
            }}
          >
            Clear all
          </button>
          <h2 className="text-2xl font-bold text-center">Filters</h2>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="mb-6">
          <label
            htmlFor="name"
            className="block text-base font-medium text-gray-900 mb-4"
          >
            Country
          </label>
          <select
            name="country"
            className="cursor-pointer bg-gray-50 border border-gray-300 text-gray-500 text-base rounded-lg focus:ring-gray-300 focus:border-gray-300 block w-full p-2.5 "
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          >
            <option value="">Chose country</option>
            {countries.map((el) => (
              <option key={el.id} value={el.en}>
                {el.en}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-6">
          <h3 className="block text-base font-medium text-gray-900 mb-4">
            Category
          </h3>
          <div className="flex flex-wrap gap-4">
            {categoriesDB.map((category) => (
              /* eslint-disable jsx-a11y/no-static-element-interactions */
              <span
                key={category.id}
                className={
                  categoriesIds.find((el) => el.id === category.id)
                    ? "cursor-pointer text-sm font-medium text-white bg-primary-100 px-2.5 py-0.5 rounded-md select-none"
                    : "cursor-pointer text-sm font-medium text-gray-800 bg-gray-100 px-2.5 py-0.5 rounded-md select-none"
                }
                onClick={() => {
                  if (categoriesIds.find((el) => el.id === category.id)) {
                    setCategoriesIds(
                      categoriesIds.filter((el) => el.id !== category.id),
                    );
                  } else {
                    setCategoriesIds([...categoriesIds, category]);
                  }
                }}
              >
                {category.name}
              </span>
            ))}
          </div>
        </div>
        <div className="mb-6 flex justify-between items-center">
          <h3 className="text-base font-medium text-gray-900">Close to goal</h3>
          <label className="inline-flex relative items-center mr-5 cursor-pointer">
            <input
              type="checkbox"
              checked={closeToGoal}
              value=""
              className="sr-only peer"
              onChange={() => setCloseToGoal(!closeToGoal)}
            />
            <div className="w-9 h-5 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-primary-100" />
          </label>
        </div>

        <div className="mb-6 flex justify-between items-center">
          <h3 className="text-base font-medium text-gray-900">
            Expiring in the next 24 hours
          </h3>
          <label className="inline-flex relative items-center mr-5 cursor-pointer">
            <input
              type="checkbox"
              checked={expiresInNext24Hours}
              value=""
              className="sr-only peer"
              onChange={() => setExpiresInNext24Hours(!expiresInNext24Hours)}
            />
            <div className="w-9 h-5 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-primary-100" />
          </label>
        </div>

        <div className="mb-6 flex flex-col justify-start items-start">
          <h3 className="block text-base font-medium text-gray-900 mb-4">
            Organization type
          </h3>
          <div
            className="flex items-center mb-2.5 cursor-pointer"
            onClick={() => setOwnerType(fundraiseTypes.PERSONAL)}
          >
            <input
              id="default-checkbox1"
              type="checkbox"
              name="organizationType"
              value=""
              checked={ownerType === fundraiseTypes.PERSONAL}
              onChange={() => setOwnerType(fundraiseTypes.PERSONAL)}
              className="w-4 h-4 text-primary-100 focus:ring-0 focus:ring-white bg-gray-100 border-gray-100 rounded cursor-pointer"
            />
            <label
              htmlFor="default-checkbox1"
              className="ml-2 text-base font-normal text-gray-900 cursor-pointer"
            >
              Personal
            </label>
          </div>
          <div
            className="flex items-center mb-2.5 cursor-pointer"
            onClick={() => setOwnerType(fundraiseTypes.ORGANIZATION)}
          >
            <input
              id="default-checkbox2"
              type="checkbox"
              name="organizationType"
              value=""
              checked={ownerType === fundraiseTypes.ORGANIZATION}
              onChange={() => setOwnerType(fundraiseTypes.ORGANIZATION)}
              className="w-4 h-4 text-primary-100 focus:ring-0 focus:ring-white bg-gray-100 border-gray-100 rounded cursor-pointer"
            />
            <label
              htmlFor="default-checkbox2"
              className="ml-2 text-base font-normal text-gray-900 cursor-pointer"
            >
              Organization
            </label>
          </div>
        </div>
        <div className="flex justify-end items-center">
          <div className="w-2/5 flex gap-4">
            <button
              type="button"
              className="text-base font-medium flex justify-center text-center border border-gray-300 text-gray-800 rounded-md w-full py-1"
              onClick={() => setIsOpenModal(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="text-base font-medium flex justify-center text-center bg-primary-100 text-white rounded-md w-full py-1"
              onClick={() => {
                dispatch(
                  getAllFundraisers({
                    country,
                    categoriesIds,
                    closeToGoal,
                    expiresInNext24Hours,
                    ownerType,
                    search: searchFundraiseTitleValue,
                  }),
                );
                dispatch(
                  setFiltersByAll({
                    country,
                    categoriesIds,
                    closeToGoal,
                    expiresInNext24Hours,
                    ownerType,
                  }),
                );
                setIsOpenModal(false);
              }}
            >
              Filter
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default FilterModal;
