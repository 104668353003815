import React from "react";
import { Outlet } from "react-router-dom";
import AuthHeader from "../AuthHeader";
import Footer from "../Footer";

const AuthLayout = () => {
  return (
    <>
      <AuthHeader />
      <main>
        <section className="flex flex-col items-center justify-center">
          <Outlet />
        </section>
      </main>
      <Footer />
    </>
  );
};

export default AuthLayout;
