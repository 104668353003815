import "./App.css";
import React from "react";
import { Route, Routes } from "react-router-dom";
import UserRoleForm from "./components/auth/UserRoleForm";
import SignUpPage from "./pages/signUp/SignUpPage";
import LoginPage from "./pages/login/LoginPage";
import ResetPasswordPage from "./pages/resetPassword/ResetPasswordPage";
import CreateGoalPage from "./pages/createGoal/CreateGoalPage";
import AuthLayout from "./components/routesLayouts/AuthLayout";
import ExplorePage from "./pages/explore/ExplorePage";
import AnalyticsPage from "./pages/analytics/AnalyticsPage";
import ProtectedMainLayout from "./components/routesLayouts/ProtectedMainLayout";
import MyFundraise from "./components/myFundraisers/MyFundraise";
import MyApprovedFundraisersPage from "./pages/myFundraisers/MyApprovedFundraisersPage";
import MyWaitingForApprovalFundraisersPage from "./pages/myFundraisers/MyWaitingForApprovalFundraisersPage";
import ExploreFundraise from "./components/exploreFundraisers/ExploreFundraise";
import PageNotFound from "./pages/pageNotFound/PageNotFound";
import AnalyticOfFundraise from "./components/analytics/AnalyticOfFundraise";
import TermsOfUsePage from "./pages/termsOfUse/TermsOfUsePage";
import PrivacyPolicyPage from "./pages/privacyPolicy/PrivacyPolicyPage";
import ContactUsPage from "./pages/contactUs/ContactUsPage";
import UserProfile from "./pages/profile/UserProfile";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<AuthLayout />}>
        <Route index element={<UserRoleForm />} />
        <Route path="login/fundraiser/*" element={<LoginPage />} />
        <Route path="login/donor/*" element={<LoginPage />} />
        <Route path="signup/fundraiser/*" element={<SignUpPage />} />
        <Route path="signup/donor/*" element={<SignUpPage />} />
        <Route path="resetPassword/*" element={<ResetPasswordPage />} />
      </Route>

      <Route path="/my-fundraisers/*" element={<ProtectedMainLayout />}>
        <Route path="approved" element={<MyApprovedFundraisersPage />} />
        <Route
          path="waiting-for-approval"
          element={<MyWaitingForApprovalFundraisersPage />}
        />
        <Route path="create-goal" element={<CreateGoalPage />} />
        <Route path="fundraise/:id" element={<MyFundraise />} />
      </Route>

      <Route path="/explore/*" element={<ProtectedMainLayout />}>
        <Route index element={<ExplorePage />} />
        <Route path="fundraise/:id" element={<ExploreFundraise />} />
      </Route>

      <Route path="/analytics/*" element={<ProtectedMainLayout />}>
        <Route index element={<AnalyticsPage />} />
        <Route path="fundraise/:id" element={<AnalyticOfFundraise />} />
      </Route>

      <Route path="/terms-of-use" element={<ProtectedMainLayout />}>
        <Route index element={<TermsOfUsePage />} />
      </Route>

      <Route path="/privacy-policy" element={<ProtectedMainLayout />}>
        <Route index element={<PrivacyPolicyPage />} />
      </Route>

      <Route path="/contact-us" element={<ProtectedMainLayout />}>
        <Route index element={<ContactUsPage />} />
      </Route>

      <Route path="/profile" element={<ProtectedMainLayout />}>
        <Route index element={<UserProfile />} />
      </Route>

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
export default App;
