import React, { useState } from "react";
import uuid from "react-uuid";
import { format, fromUnixTime } from "date-fns";

const AnalyticOfFundraiseTable = ({
  limitedDonations,
  allDonations,
  fundraise,
}) => {
  const [isShowMore, setIsShowMore] = useState(false);

  return (
    <div className="shadow p-8 rounded-md bg-white">
      <h2 className="font-bold text-2xl pb-8 text-gray-800">
        Donation history
      </h2>
      <div className="relative overflow-x-auto rounded-md shadow">
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-gray-500 bg-gray-50 border-b">
            <tr>
              <th scope="col" className="px-6 py-3 font-normal text-sm">
                Donors
              </th>
              <th scope="col" className="px-6 py-3 font-normal text-sm">
                Countries
              </th>
              <th scope="col" className="px-6 py-3 font-normal text-sm">
                Date
              </th>
              <th scope="col" className="px-6 py-3 font-normal text-sm">
                Amount ({fundraise?.goalCurrency})
              </th>
            </tr>
          </thead>
          <tbody>
            {!isShowMore
              ? limitedDonations?.rows.map((donation) => {
                  return (
                    <tr className="bg-white border-b" key={uuid()}>
                      <th
                        scope="row"
                        className="px-6 py-4 font-normal text-base text-gray-800 whitespace-nowrap"
                      >
                        <div className="flex items-center gap-3 text-ellipsis whitespace-nowrap overflow-hidden max-w-[230px]">
                          {donation?.wallet?.donor?.avatarUrl ? (
                            <img
                              className="w-[32px] h-[32px] rounded-full object-cover"
                              src={donation?.wallet?.donor?.avatarUrl}
                              alt="donor avatar"
                            />
                          ) : (
                            <div className="h-[32px] w-[32px] rounded-full bg-gray-500 flex items-center justify-center text-center text-xs font-normal text-white">
                              {donation.wallet.donor.firstName
                                .split("")
                                .shift() +
                                donation.wallet.donor.lastName
                                  .split("")
                                  .shift()}
                            </div>
                          )}
                          {donation?.wallet?.donor?.firstName &&
                          donation?.wallet?.donor?.lastName ? (
                            <span>
                              {donation?.wallet?.donor?.firstName}{" "}
                              {donation?.wallet?.donor?.lastName}
                            </span>
                          ) : (
                            <span>Anonymous</span>
                          )}
                        </div>
                      </th>
                      <td className="px-6 py-4 text-gray-500 font-normal text-base">
                        {donation?.wallet?.donor?.country}
                      </td>
                      <td className="px-6 py-4 text-gray-500 font-normal text-base">
                        {format(
                          new Date(fromUnixTime(donation?.createdAt)),
                          "LLL dd, yyyy",
                        )}
                      </td>
                      <td className="px-6 py-4 text-gray-500 font-normal text-base">
                        {donation?.amount}
                      </td>
                    </tr>
                  );
                })
              : allDonations?.rows.map((donation) => {
                  return (
                    <tr className="bg-white border-b" key={uuid()}>
                      <th
                        scope="row"
                        className="px-6 py-4 font-normal text-base text-gray-800 whitespace-nowrap"
                      >
                        <div className="flex items-center gap-3 text-ellipsis whitespace-nowrap overflow-hidden max-w-[230px]">
                          {donation?.wallet?.donor?.avatarUrl ? (
                            <img
                              className="w-[32px] h-[32px] rounded-full object-cover"
                              src={donation?.wallet?.donor?.avatarUrl}
                              alt="donor avatar"
                            />
                          ) : (
                            <div className="h-[32px] w-[32px] rounded-full bg-gray-500 flex items-center justify-center text-center text-xs font-normal text-white">
                              {donation.wallet.donor.firstName
                                .split("")
                                .shift() +
                                donation.wallet.donor.lastName
                                  .split("")
                                  .shift()}
                            </div>
                          )}
                          {donation?.wallet?.donor?.firstName}{" "}
                          {donation?.wallet?.donor?.lastName}
                        </div>
                      </th>
                      <td className="px-6 py-4 text-gray-500 font-normal text-base">
                        {donation?.wallet?.donor?.country}
                      </td>
                      <td className="px-6 py-4 text-gray-500 font-normal text-base">
                        {format(
                          new Date(fromUnixTime(donation?.createdAt)),
                          "LLL dd, yyyy",
                        )}
                      </td>
                      <td className="px-6 py-4 text-gray-500 font-normal text-base">
                        {donation?.amount}
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>
      <div className="flex items-center justify-end mt-4">
        {!isShowMore && allDonations?.rows?.length > 5 && (
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 mr-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700"
            onClick={() => setIsShowMore(true)}
          >
            Show more
          </button>
        )}
      </div>
    </div>
  );
};

export default AnalyticOfFundraiseTable;
