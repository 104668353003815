import React from "react";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <section className="bg-gray-200 h-screen">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-8 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <img
            className="mx-auto mb-4"
            src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/404/404-computer.svg"
            alt="404 Not Found"
          />
          <h1 className="mb-4 text-2xl font-extrabold text-primary-600">
            404 Not Found
          </h1>
          <p className="mb-12 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl">
            Whoops! That page doesn’t exist.
          </p>
          <button
            className="py-[12px] px-4 text-white bg-primary-100 rounded-lg font-semibold w-[50%] hover:shadow rotate-12 hover:rotate-0 duration-100"
            type="button"
            onClick={() => navigate(-1)}
          >
            Go back
          </button>
        </div>
      </div>
    </section>
  );
};

export default PageNotFound;
