import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "flowbite-react";
import { getCurrentUser } from "../../store/reducers/userSlice";
import {
  donorCategoriesAnalytic,
  donorCountriesAnalytic,
  donorMonthlyAnalytic,
  donorTotalAnalytic,
  fundraiserTotalAnalytic,
} from "../../store/reducers/analyticSlice";
import TotalDonationAnalyticIcon from "../../svg/analytics/TotalDonationAnalyticIcon";
import AnalyticCard from "../../components/analytics/AnalyticCard";
import WeeksDonationAnalyticIcon from "../../svg/analytics/WeeksDonationAnalyticIcon";
import AverageDonate from "../../svg/analytics/AverageDonate";
import DonationProjects from "../../svg/analytics/DonationProjects";
import DonorAnalyticTable from "../../components/analytics/DonorAnalyticTable";
import { AnalyticColumnChart } from "../../components/analytics/AnalyticColumnChart";
import { AnalyticDonutChart } from "../../components/analytics/AnalyticDonutChart";
import userRoles from "../../constans/userRoles";
import TopDonate from "../../svg/analytics/TopDonate";
import TopDonateCountry from "../../svg/analytics/TopDonateCountry";
import TotalDonors from "../../svg/analytics/TotalDonors";
import FundraiserAnalyticTable from "../../components/analytics/FundraiserAnalyticTable";
import { getAllDonations } from "../../store/reducers/donationsSlice";

/* eslint-disable */

const AnalyticsPage = () => {
  const [currency, setCurrency] = useState("ETH");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);

  const { currentUser } = useSelector((state) => state.user);

  useEffect(() => {
    if (currentUser?.role === userRoles.FUNDRAISER) {
      dispatch(fundraiserTotalAnalytic(currency));
    }

    if (currentUser?.role === userRoles.DONOR) {
      dispatch(donorTotalAnalytic(currency));
      dispatch(donorMonthlyAnalytic());
      dispatch(donorCountriesAnalytic());
      dispatch(donorCategoriesAnalytic());
      dispatch(
        getAllDonations({
          ignoreFundraiseAttributes: false,
          donorUuid: currentUser?.uuid,
        }),
      );
    }
  }, [dispatch, currency, currentUser]);

  const { fundraiserTotal } = useSelector((state) => state.analytic);
  const { donorTotal } = useSelector((state) => state.analytic);
  const { monthlyAnalytic } = useSelector((state) => state.analytic);
  const { countriesAnalytic } = useSelector((state) => state.analytic);
  const { categoriesAnalytic } = useSelector((state) => state.analytic);
  const allDonationsByDonor = useSelector(
    (state) => state.donations.allDonationsByFundraise,
  );

  // const countriesDonutChartData = useMemo(() => {
  //   countriesAnalytic.filter(({ country }) => country !== null);
  // }, [countriesAnalytic]);

  return (
    <div className="w-4/5 mx-auto">
      <div className="flex justify-end mb-6">
        <Dropdown label={`Currency: ${currency}`} inline={true}>
          <Dropdown.Item onClick={() => setCurrency("ETH")}>ETH</Dropdown.Item>
          <Dropdown.Item onClick={() => setCurrency("USDT")}>
            USDT
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setCurrency("USDC")}>
            USDC
          </Dropdown.Item>
        </Dropdown>
      </div>

      {currentUser?.role === userRoles.DONOR ? (
        <>
          <div className="flex gap-6 justify-center mb-8">
            <AnalyticCard
              title={`Total donation (${currency})`}
              amount={donorTotal?.totalDonationsAmount || 0}
              icon={<TotalDonationAnalyticIcon />}
            />
            <AnalyticCard
              title={`Week's donation (${currency})`}
              amount={donorTotal?.weekDonationsAmount || 0}
              icon={<WeeksDonationAnalyticIcon />}
            />
            <AnalyticCard
              title={`Average donate (${currency})`}
              amount={donorTotal?.averageDonationAmount || 0}
              icon={<AverageDonate />}
            />
            <AnalyticCard
              title="Donation projects"
              amount={donorTotal?.donationProjects || 0}
              icon={<DonationProjects />}
            />
          </div>
          <div className="mb-8 flex flex-wrap gap-8 justify-center">
            {monthlyAnalytic?.months?.length &&
            (countriesAnalytic?.length || categoriesAnalytic?.length) ? (
              <div className="shadow p-2 rounded-md bg-white">
                <h2 className="text-center text-2xl font-bold text-gray-800 py-4">
                  Monthly donation
                </h2>
                <AnalyticColumnChart
                  eth={monthlyAnalytic?.eth}
                  usdt={monthlyAnalytic?.usdt}
                  usdc={[0, 0, 0, 0.01, 0, 0, 0, 0, 0, 0, 0.02, 0]}
                  months={monthlyAnalytic?.months.map((month) =>
                    month.slice(0, 3),
                  )}
                />
              </div>
            ) : (
              ""
            )}
            {countriesAnalytic?.length || categoriesAnalytic?.length ? (
              <div className="flex flex-wrap gap-4 w-3/6 items-center justify-center">
                {countriesAnalytic?.length ? (
                  <div className="w-full shadow p-2 rounded-md bg-white">
                    <h2 className="text-center text-2xl font-bold text-gray-800 py-4">
                      Donation countries
                    </h2>
                    <AnalyticDonutChart
                      dataArr={countriesAnalytic
                        .filter(({ country }) => country !== null)
                        .map(({ percent }) => Math.round(Number(percent)))}
                      margin={20}
                      labels={countriesAnalytic
                        .filter(({ country }) => country !== null)
                        .map(({ country }) => country)}
                    />
                  </div>
                ) : (
                  ""
                )}
                {categoriesAnalytic?.length ? (
                  <div className="w-full shadow p-2 rounded-md bg-white">
                    <h2 className="text-center text-2xl font-bold text-gray-800 py-4">
                      Donation categories
                    </h2>
                    <AnalyticDonutChart
                      dataArr={categoriesAnalytic.map(({ percent }) =>
                        Math.round(Number(percent) * 100),
                      )}
                      margin={20}
                      labels={categoriesAnalytic.map(
                        ({ category }) => category,
                      )}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          {currentUser?.role === userRoles.DONOR &&
          allDonationsByDonor?.rows?.length ? (
            <DonorAnalyticTable allDonationsByDonor={allDonationsByDonor} />
          ) : (
            ""
          )}
        </>
      ) : (
        <>
          <div className="flex gap-8 justify-center mb-8">
            <AnalyticCard
              title={`Total donation ${currency}`}
              amount={fundraiserTotal?.raisedAmountTotal || 0}
              icon={<TotalDonationAnalyticIcon />}
            />
            <AnalyticCard
              title={`Top donate ${currency}`}
              amount={fundraiserTotal?.maxDonation || 0}
              icon={<TopDonate />}
            />
            <AnalyticCard
              title="Top donate country"
              amount={fundraiserTotal?.topCountry || "-"}
              icon={<TopDonateCountry />}
            />
            <AnalyticCard
              title="Total donors"
              amount={fundraiserTotal?.donorsCount || 0}
              icon={<TotalDonors />}
            />
          </div>
          {currentUser?.role === userRoles.FUNDRAISER && (
            <FundraiserAnalyticTable />
          )}
        </>
      )}
    </div>
  );
};

export default AnalyticsPage;
