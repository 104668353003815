/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ethers } from "ethers";
import detectEthereumProvider from "@metamask/detect-provider";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../../store/reducers/userSlice";
import ApiService from "../../../store/middleware/api";
import userRoles from "../../../constans/userRoles";

const WalletAddress = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);

  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.user.currentUser);
  let provider;
  let signer;
  let accounts;

  const [isMetaMask, setIsMetaMask] = useState(true);
  const [isVisibleInfoPopover, setIsVisibleInfoPopover] = useState(false);

  useMemo(async () => {
    const metaMaskProvider = await detectEthereumProvider();
    return metaMaskProvider ? setIsMetaMask(true) : setIsMetaMask(false);
  }, []);

  const validateWallet = () => {
    signer
      .signMessage(`Sign message. Nonce: ${currentUser.nonce}`)
      .then(async (msg) => {
        await ApiService.apiCall({
          endpoint: "/auth/verifyaddress/fundraiser",
          method: "POST",
          query: { address: accounts[0], signedMessage: msg },
          withCredentials: true,
        });
        if (currentUser.role === userRoles.FUNDRAISER) {
          navigate("/my-fundraisers/approved");
        }

        if (currentUser.role === userRoles.DONOR) {
          navigate("/explore");
        }
      })
      .catch((e) => toast.error(e.response?.data.message));
  };

  const connectMetamask = async () => {
    await detectEthereumProvider().then(async () => {
      provider = new ethers.providers.Web3Provider(window.ethereum);
      signer = provider.getSigner();
      await provider.send("eth_requestAccounts", []).then((acc) => {
        accounts = acc;
      });
    });
    await validateWallet();
  };

  return (
    <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 relative">
      <div className="space-y-2 p-6">
        {isMetaMask ? (
          <>
            <h1 className="text-2xl font-bold leading-tight tracking-tight text-gray-900 text-center">
              Connect and validate your MetaMask
            </h1>
            <img src="/images/metaMask.png" alt="MetaMask" />
            <button
              type="button"
              className="w-full focus:outline-none text-white bg-primary-100 hover:bg-green-500 font-medium rounded-lg text-base py-3 mb-4"
              onClick={async () => {
                await connectMetamask();
              }}
            >
              Click me
            </button>
          </>
        ) : (
          <>
            {" "}
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 text-center flex items-center justify-center">
              Looks like you don&apos;t have MetaMask
            </h1>{" "}
            <img
              className="absolute top-0 right-2"
              src="/images/info.png"
              alt="info"
              onMouseEnter={(e) => {
                e.stopPropagation();
                setIsVisibleInfoPopover(true);
              }}
              onMouseOut={(e) => {
                e.stopPropagation();
                setIsVisibleInfoPopover(false);
              }}
            />
            {isVisibleInfoPopover && (
              <div
                style={{ position: "absolute" }}
                className="inline-block absolute top-6 right-6 z-10 w-[250px] text-base text-gray-800 bg-white rounded-md border border-gray-200 shadow-sm duration-300"
              >
                <div id="tooltip-dark" role="tooltip" className="px-4 py-2">
                  If you already download and install your MetaMask - refresh
                  this page
                </div>
              </div>
            )}
            <img src="/images/metaMask.png" alt="MetaMask" />
            <a
              target="_blank"
              rel="noreferrer"
              className="text-center flex items-center justify-center focus:outline-none text-white bg-primary-100 hover:bg-green-500 font-medium rounded-lg text-base py-3 mb-4"
              href="https://metamask.io/"
            >
              Download MetaMask
            </a>
          </>
        )}
        <ToastContainer autoClose={3000} hideProgressBar pauseOnHover={false} />
      </div>
    </div>
  );
};

export default WalletAddress;
