import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import userRoles from "../../constans/userRoles";

const UserRoleForm = () => {
  const [role, setRole] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = () => {
    if (role === userRoles.DONOR) {
      navigate("/login/donor/form");
    } else {
      navigate("/login/fundraiser/form");
    }
  };

  return (
    <div className="bg-white rounded p-6">
      <div className="font-bold text-2xl text-gray-900">Choose your role</div>
      <ul className="py-5">
        <li>
          <label
            className="flex p-5 rounded bg-gray-50 items-center mb-6 cursor-pointer border border-gray-50 hover:border-primary-100"
            htmlFor="helper-radio-4"
            onClick={() => {
              localStorage.setItem("userRole", userRoles.DONOR);
              setRole(userRoles.DONOR);
            }}
          >
            <input
              id="helper-radio-4"
              name="helper-radio"
              type="radio"
              value=""
              className="w-4 h-4 text-primary-100 focus:ring-primary-100"
            />
            <div className="ml-4">
              <p className="text-xl font-semibold text-gray-800">Donor</p>
              <p
                id="helper-radio-text-4"
                className="font-medium text-base text-gray-600"
              >
                Donate funds to a charity or fundraiser.
              </p>
            </div>
          </label>
        </li>
        <li>
          <label
            className="flex p-5 rounded bg-gray-50 items-center cursor-pointer border border-gray-50 hover:border-primary-100"
            htmlFor="helper-radio-5"
            onClick={() => {
              localStorage.setItem("userRole", userRoles.FUNDRAISER);
              setRole(userRoles.FUNDRAISER);
            }}
          >
            <input
              id="helper-radio-5"
              name="helper-radio"
              type="radio"
              value=""
              className="w-4 h-4 text-primary-100 focus:ring-primary-100"
            />
            <div className="ml-4">
              <p className="text-xl font-semibold text-gray-800">Fundraiser</p>
              <p
                id="helper-radio-text-5"
                className="font-medium text-base text-gray-600"
              >
                Receive funds from donors.
              </p>
            </div>
          </label>
        </li>
      </ul>
      <button
        type="button"
        className={
          role
            ? "w-full focus:outline-none text-white bg-primary-100 hover:bg-green-500 font-medium rounded-lg text-base py-3"
            : "w-full focus:outline-none text-gray-900 bg-gray-300 hover:bg-gray-400 font-medium rounded-lg text-base py-3"
        }
        disabled={!role}
        onClick={handleSubmit}
      >
        Continue
      </button>
    </div>
  );
};

export default UserRoleForm;
