import React from "react";

const CreateFundraiseMedal = () => {
  return (
    <svg
      width="88"
      height="95"
      viewBox="0 0 88 95"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5008 54L43.9737 64.1435L23.3185 93L20.1788 77.435L2.99365 78.4843L21.5008 54Z"
        fill="url(#paint0_linear_1565_49139)"
        stroke="#206B9F"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <path
        d="M67.4463 54L44.9734 64.1435L65.6287 93L68.7683 77.435L85.9535 78.4843L67.4463 54Z"
        fill="url(#paint1_linear_1565_49139)"
        stroke="#206B9F"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <path
        d="M56.785 4.51657L60.8238 13.4506C61.6129 15.1963 63.082 16.5428 64.8889 17.1765L74.1352 20.4192C75.7251 20.9768 76.5454 22.7345 75.9516 24.3112L72.4956 33.4879C71.8211 35.279 71.9091 37.2688 72.7392 38.9944L76.9915 47.8342C77.722 49.3528 77.0606 51.1745 75.5267 51.8686L66.5982 55.9086C64.8553 56.6972 63.5117 58.1657 62.88 59.9724L59.6434 69.229C59.0873 70.8195 57.3313 71.639 55.7551 71.0436L46.5885 67.5814C44.7972 66.9048 42.8076 66.9918 41.0832 67.8222L32.2579 72.0718C30.7402 72.8026 28.9179 72.1391 28.2234 70.6027L24.1846 61.6686C23.3955 59.9229 21.9263 58.5764 20.1194 57.9427L10.8731 54.7C9.28323 54.1424 8.46303 52.3848 9.05673 50.808L12.5127 41.6313C13.1873 39.8402 13.0992 37.8504 12.2692 36.1248L8.01693 27.2851C7.28643 25.7664 7.94783 23.9447 9.48173 23.2506L18.4102 19.2106C20.1531 18.422 21.4967 16.9535 22.1284 15.1468L25.365 5.89023C25.9211 4.29979 27.677 3.48029 29.2532 4.07562L38.4198 7.53786C40.2111 8.21443 42.2007 8.12743 43.9252 7.29708L52.7505 3.04742C54.2682 2.31662 56.0905 2.98019 56.785 4.51657Z"
        fill="#29B277"
        stroke="#206B9F"
        strokeWidth="4"
      />
      <path
        d="M69.9601 37C69.9601 52.4649 57.4297 65 41.9747 65C26.5197 65 13.9893 52.4649 13.9893 37C13.9893 21.5351 26.5197 9 41.9747 9C57.4297 9 69.9601 21.5351 69.9601 37Z"
        fill="#30D893"
        stroke="#206B9F"
        strokeWidth="4"
      />
      <path
        d="M59.1889 37.2936C59.1889 46.8067 51.481 54.5169 41.9749 54.5169C32.4689 54.5169 24.761 46.8067 24.761 37.2936C24.761 27.7805 32.4689 20.0703 41.9749 20.0703C51.481 20.0703 59.1889 27.7805 59.1889 37.2936Z"
        fill="white"
        stroke="#206B9F"
        strokeWidth="4"
      />
      <path d="M24.9441 37.4414H59.0052" stroke="#206B9F" strokeWidth="4" />
      <path
        d="M40.3736 20.6934C37.0258 25.7904 32.8046 41.373 40.0827 53.4604"
        stroke="#206B9F"
        strokeWidth="4"
      />
      <path
        d="M42.9941 20.4004C46.3419 25.4975 50.7089 41.6625 43.4308 53.7499"
        stroke="#206B9F"
        strokeWidth="4"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1565_49139"
          x1="23.4837"
          y1="54"
          x2="23.4837"
          y2="89.3619"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#30D893" stopOpacity="0.6" />
          <stop offset="1" stopColor="#206B9F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1565_49139"
          x1="65.4634"
          y1="54"
          x2="65.4634"
          y2="89.3619"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#30D893" stopOpacity="0.6" />
          <stop offset="1" stopColor="#206B9F" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CreateFundraiseMedal;
