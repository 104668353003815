import React from "react";
import { ModalHeader } from "flowbite-react/lib/esm/components/Modal/ModalHeader";
import { ModalBody } from "flowbite-react/lib/esm/components/Modal/ModalBody";
import { Avatar, Modal, Spinner } from "flowbite-react";
import { formatDistanceToNowStrict, fromUnixTime } from "date-fns";

const DonorsDonationsModal = ({
  isOpenModal,
  setIsOpenModal,
  allDonations,
  fundraise,
}) => {
  return (
    <Modal show={isOpenModal} size="lg" onClose={() => setIsOpenModal(false)}>
      <ModalHeader>Donors</ModalHeader>
      <ModalBody>
        {allDonations?.rows?.length ? (
          allDonations?.rows.map((donation) => {
            return donation.wallet.donor ? (
              <div
                className="flex flex-col justify-start items-start mt-6"
                key={donation.createdAt}
              >
                <div className="pb-4 border-b-2 border-gray-200 w-full flex justify-between items-center">
                  <div className="flex justify-start gap-4 items-center">
                    <Avatar
                      rounded
                      size="md"
                      img={donation.wallet.donor.avatarUrl}
                    />
                    <div>
                      <h4 className="text-base font-semibold">
                        {donation.wallet.donor.firstName}{" "}
                        {donation.wallet.donor.lastName}
                      </h4>
                      <h5 className="text-xs font-normal text-gray-500">
                        {formatDistanceToNowStrict(
                          new Date(fromUnixTime(donation.createdAt)),
                        )}{" "}
                        ago
                      </h5>
                    </div>
                  </div>{" "}
                  <div className="text-base font-semibold">
                    {donation.amount} {fundraise.goalCurrency}
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="flex flex-col justify-start items-start mt-6"
                key={donation.createdAt}
              >
                <div className="pb-4 border-b-2 border-gray-200 w-full flex justify-between items-center">
                  <div className="flex justify-start gap-4 items-center">
                    <Avatar rounded size="md" />
                    <div>
                      <h4 className="text-base font-semibold">Anonymous</h4>
                      <h5 className="text-xs font-normal text-gray-500">
                        {formatDistanceToNowStrict(
                          new Date(fromUnixTime(donation.createdAt)),
                        )}{" "}
                        ago
                      </h5>
                    </div>
                  </div>{" "}
                  <div className="text-base font-semibold">
                    {donation.amount} {fundraise.goalCurrency}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="flex justify-center">
            <Spinner color="success" size="xl" />
          </div>
        )}
        <button
          type="button"
          className="text-base font-normal flex justify-center text-center mt-4 bg-primary-100 text-white rounded-md w-full py-2"
          onClick={() => setIsOpenModal(false)}
        >
          Close
        </button>
      </ModalBody>
    </Modal>
  );
};

export default DonorsDonationsModal;
