import React from "react";

const TopDonate = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="8" fill="#206B9F" fillOpacity="0.1" />
      <path
        d="M15.049 8.92708C15.349 8.00608 16.652 8.00608 16.951 8.92708L18.021 12.2191C18.0863 12.4196 18.2134 12.5943 18.384 12.7182C18.5547 12.8421 18.7601 12.9089 18.971 12.9091H22.433C23.402 12.9091 23.804 14.1491 23.021 14.7191L20.221 16.7531C20.05 16.8771 19.9227 17.0521 19.8573 17.2529C19.7919 17.4538 19.7918 17.6702 19.857 17.8711L20.927 21.1631C21.227 22.0841 20.172 22.8511 19.387 22.2811L16.587 20.2471C16.4162 20.1231 16.2105 20.0563 15.9995 20.0563C15.7884 20.0563 15.5828 20.1231 15.412 20.2471L12.612 22.2811C11.828 22.8511 10.774 22.0841 11.073 21.1631L12.143 17.8711C12.2081 17.6702 12.208 17.4538 12.1426 17.2529C12.0773 17.0521 11.9499 16.8771 11.779 16.7531L8.97997 14.7201C8.19697 14.1501 8.59997 12.9101 9.56797 12.9101H13.029C13.24 12.9101 13.4457 12.8434 13.6165 12.7195C13.7873 12.5955 13.9145 12.4207 13.98 12.2201L15.05 8.92808L15.049 8.92708Z"
        fill="#206B9F"
      />
    </svg>
  );
};

export default TopDonate;
