import React from "react";
import createGoalProgressBar from "../../heplers/createGoalProgressBar/createGoalProgressBar";
import fundraiseTypes from "../../constans/fundraiseTypes";

const CreateGoalBar = ({ createFundraiseStep, ownerType }) => {
  return (
    <div className="flex space-x-2 justify-center mb-6">
      <div className="min-w-[174px]">
        <h2 className="text-base font-medium text-gray-500 pb-4">Start</h2>
        <div className="w-full bg-gray-100 h-2.5 mb-4">
          <div
            className={createGoalProgressBar.start(createFundraiseStep)}
            style={{ width: "100%" }}
          />
        </div>
      </div>
      {ownerType === fundraiseTypes.ORGANIZATION && (
        <div className="min-w-[174px]">
          <h2 className="text-base font-medium text-gray-500 pb-4">Company</h2>
          <div className="w-full bg-gray-100 h-2.5 mb-4">
            <div
              className={createGoalProgressBar.company(createFundraiseStep)}
              style={{ width: "100%" }}
            />
          </div>
        </div>
      )}
      <div className="min-w-[174px]">
        <h2 className="text-base font-medium text-gray-500 pb-4">
          General info
        </h2>
        <div className="w-full bg-gray-100 h-2.5 mb-4">
          <div
            className={createGoalProgressBar.generalInfo(createFundraiseStep)}
            style={{ width: "100%" }}
          />
        </div>
      </div>
      <div className="min-w-[174px]">
        <h2 className="text-base font-medium text-gray-500 pb-4">
          Funding info
        </h2>
        <div className="w-full bg-gray-100 h-2.5 mb-4">
          <div
            className={createGoalProgressBar.fundingInfo(createFundraiseStep)}
            style={{ width: "100%" }}
          />
        </div>
      </div>
      <div className="min-w-[174px]">
        <h2 className="text-base font-medium text-gray-500 pb-4">Preview</h2>
        <div className="w-full bg-gray-100 h-2.5 mb-4">
          <div
            className={createGoalProgressBar.preview(createFundraiseStep)}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateGoalBar;
