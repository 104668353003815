import React from "react";
import { useNavigate } from "react-router-dom";
import userRoles from "../../constans/userRoles";

const PasswordUpdated = () => {
  const navigate = useNavigate();
  const userRole = localStorage.getItem("userRole");

  return (
    <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
      <form
        className="space-y-6 p-6"
        onSubmit={() => {
          if (userRole.includes(userRoles.DONOR)) {
            navigate("/login/donor/form");
          } else {
            navigate("/login/fundraiser/form");
          }
        }}
      >
        <div className="flex flex-col items-center">
          <h1 className="text-2xl font-bold leading-tight tracking-tight text-gray-900 pb-2 ">
            Password has been updated
          </h1>
          <h2 className="font-medium text-base text-gray-500 text">
            We will automatically redirect you to the login page.
          </h2>
        </div>
        <button
          type="submit"
          className="w-full focus:outline-none text-white bg-primary-100 hover:bg-green-500 font-medium rounded-lg text-base py-3 mb-4"
        >
          Go to Log in
        </button>
      </form>
    </div>
  );
};

export default PasswordUpdated;
