import React, { useCallback } from "react";
import ImageUploading from "react-images-uploading";
import uuid from "react-uuid";
/* eslint-disable */

const PhotoPreview = ({ images, setImages }) => {
  const maxNumber = 10;

  const onChange = useCallback((imageList) => {
    setImages([...imageList]);
  }, []);

  return (
    <div className="mb-6">
      <span className="block text-base font-medium text-gray-900 mb-2">
        Add images
      </span>
      <ImageUploading
        multiple
        value={images}
        onChange={(e) => {
          onChange(e);
        }}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        acceptType={["jpg", "png"]}
        resolutionType="less"
        maxFileSize={10485760}
      >
        {({ imageList, onImageUpload, dragProps, onImageRemove }) => (
          <button
            type="button"
            className="flex justify-center items-center w-full"
            onClick={onImageUpload}
            {...dragProps}
          >
            <div
              className={
                imageList?.length
                  ? "flex flex-wrap gap-4 justify-center items-center w-full h-fit p-4 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer "
                  : "flex flex-col justify-center items-center w-full h-32 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer "
              }
            >
              {imageList.length ? (
                imageList.map((image, index) => {
                  /* eslint-disable */

                  return (
                    <div className="relative" key={uuid()}>
                      <img
                        src={image.data_url}
                        alt="fundraise img"
                        className="rounded-lg max-w-[150px] max-h-[100px] z-10"
                      />
                      <svg
                        className="w-6 h-6 absolute right-[-10px] top-[-10px] bg-white rounded-full hover:bg-primary-100"
                        fill="white"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={(e) => {
                          e.stopPropagation();
                          onImageRemove(index);
                        }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </div>
                  );
                })
              ) : (
                <div className="flex flex-col justify-center items-center pt-5 pb-6">
                  <svg
                    aria-hidden="true"
                    className="mb-3 w-10 h-10 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 ">
                    Click to upload or drag and drop
                  </p>
                  <p className="text-sm text-gray-500 ">
                    All .jpg, .png files up to 10 MB are supported.
                  </p>
                </div>
              )}
            </div>
          </button>
        )}
      </ImageUploading>
      <p className="text-end text-gray-500 text-xs pt-2 font-medium">
        10 photos maximum
      </p>
    </div>
  );
};

export default React.memo(PhotoPreview);
