import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setCompanyInfo } from "../../store/reducers/fundraiseSlice";
import countries from "../../constans/countries";
import createFundraiseSteps from "../../constans/createFundraiseSteps";

const Company = ({ setCreateFundraiseStep }) => {
  const [organizationName, setOrganizationName] = useState("");
  const [organizationTaxId, setOrganizationTaxId] = useState("");
  const [organizationCountry, setOrganizationCountry] = useState("");

  const dispatch = useDispatch();

  return (
    <div className="relative p-4 w-full max-w-3xl h-full md:h-auto">
      <div className="relative p-4 bg-white rounded-lg shadow sm:p-5">
        <div className="mb-6">
          <label
            htmlFor="name"
            className="block text-base font-medium text-gray-900 mb-2"
          >
            Organization name
          </label>
          <input
            type="text"
            name="title"
            id="name"
            className="bg-gray-50 border border-gray-300 text-gray-500 text-base rounded-lg focus:ring-gray-300 focus:border-gray-300 block w-full p-2.5 "
            placeholder="Enter a title that inspires"
            required=""
            onChange={(e) => setOrganizationName(e.target.value)}
            value={organizationName}
          />
        </div>
        <div className="mb-6">
          <label
            htmlFor="name"
            className="block text-base font-medium text-gray-900 mb-2"
          >
            Tax ID
          </label>
          <input
            type="text"
            name="title"
            id="name"
            className="bg-gray-50 border border-gray-300 text-gray-500 text-base rounded-lg focus:ring-gray-300 focus:border-gray-300 block w-full p-2.5 "
            placeholder="Company tax ID"
            required=""
            onChange={(e) => setOrganizationTaxId(e.target.value)}
            value={organizationTaxId}
          />
        </div>
        <div className="mb-6">
          <label
            htmlFor="name"
            className="block text-base font-medium text-gray-900 mb-2"
          >
            Country of registration
          </label>
          <select
            name="country"
            className="cursor-pointer bg-gray-50 border border-gray-300 text-gray-500 text-base rounded-lg focus:ring-gray-300 focus:border-gray-300 block w-full p-2.5 "
            value={organizationCountry}
            onChange={(e) => setOrganizationCountry(e.target.value)}
          >
            <option value="">Chose your country</option>
            {countries.map((country) => (
              <option key={country.id} value={country.en}>
                {country.en}
              </option>
            ))}
          </select>
        </div>
        <div className="pt-5 bg-white flex justify-between">
          <button
            type="button"
            className="py-2 px-5 text-sm font-medium text-gray-800 focus:outline-none rounded-lg border border-gray-200 hover:bg-gray-100"
            onClick={() => {
              setCreateFundraiseStep(createFundraiseSteps.START);
            }}
          >
            Back
          </button>

          <button
            type="submit"
            className={
              !organizationName || !organizationCountry || !organizationTaxId
                ? "py-2 px-5 text-sm font-medium text-white focus:outline-none bg-gray-300 rounded-lg border border-gray-200"
                : "py-2 px-5 text-sm font-medium text-white focus:outline-none bg-primary-100 rounded-lg border border-gray-200"
            }
            disabled={
              !organizationName || !organizationCountry || !organizationTaxId
            }
            onClick={() => {
              dispatch(
                setCompanyInfo({
                  organizationName,
                  organizationTaxId,
                  organizationCountry,
                }),
              );
              setCreateFundraiseStep(createFundraiseSteps.GENERALINFO);
            }}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default Company;
