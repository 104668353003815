import axios from "axios";

const ApiService = {
  apiCall: ({
    endpoint = "",
    method = "GET",
    query = {},
    headers = {},
    qsParams = {},
    withCredentials = true,
  }) => {
    try {
      const api = axios.create({
        withCredentials: withCredentials || false,
        baseURL: "https://hefetest.com/api/v1",
        headers: headers || {},
        params: qsParams || {},
      });

      switch (method) {
        case "GET":
          return api.get(endpoint);
        case "POST":
          return api.post(endpoint, query);
        case "PATCH":
          return api.patch(endpoint, query);
        case "PUT":
          return api.put(endpoint, query);
        case "DELETE":
          return api.delete(endpoint, query);
        case "OPTIONS":
          return api.options(endpoint);
        default:
          return api.get(endpoint, query);
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
};

export default ApiService;
