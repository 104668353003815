import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

/* eslint-disable */

export class AnalyticColumnChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "Donation (ETH)",
          data: this.props.eth,
        },
        {
          name: "Donation (USDT)",
          data: this.props.usdt,
        },
        {
          name: "Donation (USDC)",
          data: this.props.usdc,
        },
      ],
      options: {
        chart: {
          type: "bar",
          width: 510,
          toolbar: {
            tools: {
              download: false,
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "90%",
            endingShape: "rounded",
            borderRadius: 2,
            colors: {
              backgroundBarColors: ["transparent"],
              backgroundBarOpacity: 1,
              backgroundBarRadius: 0,
            },
          },
        },
        grid: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 3,
          colors: ["transparent"],
        },
        xaxis: {
          categories: this.props.months,
        },
        yaxis: {
          title: {
            text: "",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      },
    };
  }
  render() {
    this.state = {
      series: [
        {
          name: "Donation (ETH)",
          data: this.props.eth,
        },
        {
          name: "Donation (USDT)",
          data: this.props.usdt,
        },
        {
          name: "Donation (USDC)",
          data: this.props.usdc,
        },
      ],
      options: { ...this.state.options, labels: this.props.months },
    };
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={540}
          width={510}
        />
      </div>
    );
  }
}
