import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  donorTwofaEnableSMS,
  fundraiserTwofaEnableSMS,
} from "../../../store/reducers/userSlice";
import userRoles from "../../../constans/userRoles";

const TwoFactorPhoneNumberInput = ({ phoneNumber, setPhoneNumber }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRole = localStorage.getItem("userRole");
  /* eslint-disable consistent-return */
  const handleSubmit = () => {
    if (userRole.includes(userRoles.FUNDRAISER)) {
      return dispatch(
        fundraiserTwofaEnableSMS({
          phone: `+${phoneNumber}`,
          onSuccess: () =>
            navigate("/signup/fundraiser/two-factor-options/phone/code"),
        }),
      );
    }
    if (userRole.includes(userRoles.DONOR)) {
      return dispatch(
        donorTwofaEnableSMS({
          phone: `+${phoneNumber}`,
          onSuccess: () =>
            navigate("/signup/donor/two-factor-options/phone/code"),
        }),
      );
    }
  };

  return (
    <div className="flex justify-center flex-col bg-white rounded p-6 shadow w-1/4 items-start">
      <div className="font-bold text-2xl text-gray-900 mb-2 ">
        Two-step verification
      </div>
      <div className="font-semibold text-base text-gray-500 mb-10">
        We will text a verification code to this number
      </div>
      <div className="mb-8 mx-auto">
        <PhoneInput
          country="us"
          onChange={(phone) => setPhoneNumber(phone)}
          inputClass="text-base bg-gray-50 border border-gray-300 text-gray-500 rounded-lg focus:ring-gray-300 focus:border-gray-300 block w-full p-2.5"
        />
      </div>
      <button
        type="button"
        className={
          phoneNumber
            ? "w-full focus:outline-none text-white bg-primary-100 hover:bg-green-500 font-medium rounded-lg text-base py-3 mb-4"
            : "w-full focus:outline-none text-gray-900 bg-gray-300 hover:bg-gray-400 font-medium rounded-lg text-base py-3 mb-4"
        }
        disabled={!phoneNumber}
        onClick={handleSubmit}
      >
        Continue
      </button>
    </div>
  );
};

export default TwoFactorPhoneNumberInput;
