import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import OpenEye from "../../svg/eye/OpenEye";
import ClosedEye from "../../svg/eye/ClosedEye";
import PasswordPopover from "../auth/popovers/PasswordPopover";
import ResetPasswordValidation from "./config/ResetPasswordValidation";
import ConfirmPasswordPopover from "./ConfirmPasswordPopover";
import userRoles from "../../constans/userRoles";
import {
  donorChangePassword,
  fundraiserChangePassword,
} from "../../store/reducers/resetPasswordSlice";

const NewPassword = () => {
  const [numberOfNewCharacters, setNumberOfNewCharacters] = useState(false);
  const [isNewLowerCase, setIsNewLowerCase] = useState(false);
  const [isNewSymbol, setIsNewSymbol] = useState(false);
  const [isNewUpperCase, setIsNewUpperCase] = useState(false);
  const [isNewNumber, setIsNewNumber] = useState(false);

  const [numberOfConfirmCharacters, setNumberOfConfirmCharacters] =
    useState(false);
  const [isConfirmLowerCase, setIsConfirmLowerCase] = useState(false);
  const [isConfirmSymbol, setIsConfirmSymbol] = useState(false);
  const [isConfirmUpperCase, setIsConfirmUpperCase] = useState(false);
  const [isConfirmNumber, setIsConfirmNumber] = useState(false);

  const [isVisibleNewPasswordPopover, setIsVisibleNewPasswordPopover] =
    useState(false);
  const [isVisibleConfirmPasswordPopover, setIsVisibleConfirmPasswordPopover] =
    useState(false);
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRole = localStorage.getItem("userRole");

  const newPasswordInputValidation = (e) => {
    if (e.target.value.match(/^.*[A-Z]/)) {
      setIsNewUpperCase(true);
    } else {
      setIsNewUpperCase(false);
    }
    if (e.target.value.length >= 8) {
      setNumberOfNewCharacters(true);
    } else {
      setNumberOfNewCharacters(false);
    }
    if (e.target.value.match(/^.*[a-z]/)) {
      setIsNewLowerCase(true);
    } else {
      setIsNewLowerCase(false);
    }
    if (e.target.value.match(/[0-9]/)) {
      setIsNewNumber(true);
    } else {
      setIsNewNumber(false);
    }
    if (e.target.value.match(/[#$&_~!><.,?/@%*)^:;|}{(+=-]/)) {
      setIsNewSymbol(true);
    } else {
      setIsNewSymbol(false);
    }
  };

  const confirmPasswordInputValidation = (e) => {
    if (e.target.value.match(/^.*[A-Z]/)) {
      setIsConfirmUpperCase(true);
    } else {
      setIsConfirmUpperCase(false);
    }
    if (e.target.value.length >= 8) {
      setNumberOfConfirmCharacters(true);
    } else {
      setNumberOfConfirmCharacters(false);
    }
    if (e.target.value.match(/^.*[a-z]/)) {
      setIsConfirmLowerCase(true);
    } else {
      setIsConfirmLowerCase(false);
    }
    if (e.target.value.match(/[0-9]/)) {
      setIsConfirmNumber(true);
    } else {
      setIsConfirmNumber(false);
    }
    if (e.target.value.match(/[#$&_~!><.,?/@%*)^:;|}{(+=-]/)) {
      setIsConfirmSymbol(true);
    } else {
      setIsConfirmSymbol(false);
    }
  };

  const newPasswordProgress = () => {
    const bar = [];
    if (numberOfNewCharacters) bar.push(numberOfNewCharacters);
    if (isNewUpperCase) bar.push(isNewUpperCase);
    if (isNewLowerCase) bar.push(isNewLowerCase);
    if (isNewNumber) bar.push(isNewNumber);
    if (isNewSymbol) bar.push(isNewSymbol);

    return bar.length;
  };

  const confirmPasswordProgress = () => {
    const bar = [];
    if (numberOfConfirmCharacters) bar.push(numberOfConfirmCharacters);
    if (isConfirmUpperCase) bar.push(isConfirmUpperCase);
    if (isConfirmLowerCase) bar.push(isConfirmLowerCase);
    if (isConfirmNumber) bar.push(isConfirmNumber);
    if (isConfirmSymbol) bar.push(isConfirmSymbol);

    return bar.length;
  };
  const [isError, setIsError] = useState("");
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
        <div className="p-6 ">
          <div className="space-y-2 mb-6">
            <h1 className="text-2xl font-bold leading-tight tracking-tight text-gray-900">
              Reset password
            </h1>
            <h2 className="font-medium text-base text-gray-500">
              We’ll email you instructions to reset your password.
            </h2>
          </div>
          <Formik
            initialValues={{ newPassword: "", confirmPassword: "" }}
            onSubmit={async (values) => {
              if (
                values.newPassword === values.confirmPassword &&
                userRole.includes(userRoles.DONOR)
              ) {
                setIsError("");
                dispatch(
                  donorChangePassword({
                    password: { password: values.newPassword },
                    onSuccess: () => navigate("/resetPassword/passwordUpdated"),
                  }),
                );
              } else {
                setIsError("Password must be the same!");
              }
              if (
                values.newPassword === values.confirmPassword &&
                userRole.includes(userRoles.FUNDRAISER)
              ) {
                setIsError("");
                dispatch(
                  fundraiserChangePassword({
                    password: { password: values.newPassword },
                    onSuccess: () => navigate("/resetPassword/passwordUpdated"),
                  }),
                );
              } else {
                setIsError("Password must be the same!");
              }
            }}
            validationSchema={ResetPasswordValidation}
          >
            {({ values, handleChange, handleBlur, errors, touched }) => (
              <Form className="min-w-[370px] mb-4">
                <div className="space-y-5 mb-6">
                  <div style={{ position: "relative" }}>
                    <label
                      htmlFor="newPassword"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      New password
                    </label>
                    <input
                      type={isVisiblePassword ? "text" : "password"}
                      name="newPassword"
                      id="newPassword"
                      placeholder="Enter your password"
                      className={
                        errors.newPassword && touched.newPassword
                          ? "text-base bg-gray-50 border border-red-300 text-gray-500 rounded-lg focus:ring-red-300 focus:border-red-300 block w-full p-2.5"
                          : "text-base bg-gray-50 border border-gray-300 text-gray-500 rounded-lg focus:ring-gray-300 focus:border-gray-300 block w-full p-2.5"
                      }
                      value={values.newPassword}
                      onChange={(e) => {
                        handleChange(e);
                        newPasswordInputValidation(e);
                      }}
                      onBlur={handleBlur}
                      onMouseEnter={() => setIsVisibleNewPasswordPopover(true)}
                      onMouseOut={() => setIsVisibleNewPasswordPopover(false)}
                    />
                    {isVisiblePassword ? (
                      <OpenEye setIsVisiblePassword={setIsVisiblePassword} />
                    ) : (
                      <ClosedEye setIsVisiblePassword={setIsVisiblePassword} />
                    )}

                    {isVisibleNewPasswordPopover && (
                      <PasswordPopover
                        numberOfCharacters={numberOfNewCharacters}
                        isLowerCase={isNewLowerCase}
                        isUpperCase={isNewUpperCase}
                        isSymbol={isNewSymbol}
                        isNumber={isNewNumber}
                        progress={newPasswordProgress()}
                      />
                    )}
                  </div>

                  <div style={{ position: "relative" }}>
                    <label
                      htmlFor="confirmPassword"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Confirm password
                    </label>
                    <input
                      type={isVisiblePassword ? "text" : "password"}
                      name="confirmPassword"
                      id="confirmPassword"
                      placeholder="Enter your password"
                      className={
                        errors.confirmPassword && touched.confirmPassword
                          ? "text-base bg-gray-50 border border-red-300 text-gray-500 rounded-lg focus:ring-red-300 focus:border-red-300 block w-full p-2.5"
                          : "text-base bg-gray-50 border border-gray-300 text-gray-500 rounded-lg focus:ring-gray-300 focus:border-gray-300 block w-full p-2.5"
                      }
                      value={values.confirmPassword}
                      onChange={(e) => {
                        handleChange(e);
                        confirmPasswordInputValidation(e);
                      }}
                      onBlur={handleBlur}
                      onMouseEnter={() =>
                        setIsVisibleConfirmPasswordPopover(true)
                      }
                      onMouseOut={() =>
                        setIsVisibleConfirmPasswordPopover(false)
                      }
                    />
                    {isVisiblePassword ? (
                      <OpenEye setIsVisiblePassword={setIsVisiblePassword} />
                    ) : (
                      <ClosedEye setIsVisiblePassword={setIsVisiblePassword} />
                    )}

                    {isVisibleConfirmPasswordPopover && (
                      <ConfirmPasswordPopover
                        numberOfCharacters={numberOfConfirmCharacters}
                        isLowerCase={isConfirmLowerCase}
                        isUpperCase={isConfirmUpperCase}
                        isSymbol={isConfirmSymbol}
                        isNumber={isConfirmNumber}
                        progress={confirmPasswordProgress()}
                      />
                    )}
                  </div>
                  {isError && (
                    <p className="text-red-500 text-base font-medium">
                      {isError}
                    </p>
                  )}
                </div>
                <button
                  type="submit"
                  className={
                    errors.newPassword || errors.confirmPassword
                      ? "w-full focus:outline-none text-gray-900 bg-gray-300 hover:bg-gray-400 font-medium rounded-lg text-base py-3"
                      : "w-full focus:outline-none text-white bg-primary-100 hover:bg-green-500 font-medium rounded-lg text-base py-3"
                  }
                  disabled={errors.newPassword || errors.confirmPassword}
                >
                  Reset password
                </button>
              </Form>
            )}
          </Formik>
          <div className="flex justify-between">
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="font-medium text-base text-primary-100 hover:underline"
            >
              Back
            </button>
            <button
              type="button"
              className="font-medium text-base text-primary-100 hover:underline"
              onClick={() => navigate("/contact-us")}
            >
              Contact us
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
