import React from "react";

const ClosedEye = ({ setIsVisiblePassword }) => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute top-11 right-4"
      onClick={() => setIsVisiblePassword(true)}
      style={{ cursor: "pointer" }}
    >
      <path
        d="M10.4063 13.1188C9.94266 13.2064 9.47185 13.2504 9.00002 13.25C5.64152 13.25 2.79902 11.0428 1.84277 8C2.10022 7.18108 2.49677 6.4126 3.01502 5.72825M7.40852 6.40925C7.83052 5.98726 8.40286 5.75018 8.99965 5.75018C9.59644 5.75018 10.1688 5.98726 10.5908 6.40925C11.0128 6.83124 11.2498 7.40359 11.2498 8.00037C11.2498 8.59716 11.0128 9.16951 10.5908 9.5915M7.40852 6.40925L10.5908 9.5915M7.40852 6.40925L10.59 9.59M10.5908 9.5915L13.059 12.059M7.41002 6.41L4.94252 3.9425M4.94252 3.9425L2.25002 1.25M4.94252 3.9425C6.15179 3.16218 7.56085 2.74805 9.00002 2.75C12.3585 2.75 15.201 4.95725 16.1573 8C15.6293 9.67284 14.5331 11.1084 13.0583 12.0582M4.94252 3.9425L13.0583 12.0582M13.0583 12.0582L15.75 14.75"
        stroke="#6B7280"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ClosedEye;
