import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ImageUploading from "react-images-uploading";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import CreateProfileValidation from "../config/CreateProfileValidation";
import {
  updateDonor,
  updateDonorAvatar,
} from "../../../store/reducers/userSlice";
import countries from "../../../constans/countries";

const CreateDonorProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSuccessSubmit = () => {
    navigate("/explore");
  };

  const [images, setImages] = useState([]);
  const onChange = (imageList) => setImages(imageList);

  const uploadAvatar = async () => {
    const formData = new FormData();
    formData.append("name", images[0].file.name);
    formData.append("avatar", images[0].file);
    await dispatch(updateDonorAvatar({ avatar: formData }));
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
        <div className="p-6 ">
          <div className="space-y-2 mb-6">
            <h1 className="text-2xl font-bold leading-tight tracking-tight text-gray-900">
              Let&apos;s get started
            </h1>
            <h2 className="font-medium text-base text-gray-500">
              Please enter the information below.
            </h2>
          </div>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              country: "",
            }}
            onSubmit={async (values) => {
              if (images.length) await uploadAvatar();
              await dispatch(
                updateDonor({
                  userData: values,
                  onSuccess: () => onSuccessSubmit(),
                }),
              );
            }}
            validationSchema={CreateProfileValidation}
          >
            {({
              handleSubmit,
              values,
              handleChange,
              handleBlur,
              errors,
              touched,
            }) => (
              <Form onSubmit={handleSubmit} className="min-w-[370px] mb-4">
                <div className="space-y-5 mb-6">
                  <div>
                    <label
                      htmlFor="firstName"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      First name
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      className={
                        errors.firstName && touched.firstName
                          ? "text-base bg-gray-50 border border-red-300 text-gray-500 rounded-lg focus:ring-red-300 focus:border-red-300 block w-full p-2.5"
                          : "text-base bg-gray-50 border border-gray-300 text-gray-500 rounded-lg focus:ring-gray-300 focus:border-gray-300 block w-full p-2.5"
                      }
                      placeholder="Your name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="lastName"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Last name
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Your last name"
                      className={
                        errors.lastName && touched.lastName
                          ? "text-base bg-gray-50 border border-red-300 text-gray-500 rounded-lg focus:ring-red-300 focus:border-red-300 block w-full p-2.5"
                          : "text-base bg-gray-50 border border-gray-300 text-gray-500 rounded-lg focus:ring-gray-300 focus:border-gray-300 block w-full p-2.5"
                      }
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div>
                    <span className="block mb-2 text-sm font-medium text-gray-900 ">
                      Add your photo
                    </span>
                    <ImageUploading
                      value={images}
                      onChange={onChange}
                      dataURLKey="data_url"
                      acceptType={["jpg", "png"]}
                    >
                      {({ imageList, onImageUpload, dragProps }) => (
                        <button
                          type="button"
                          className="flex justify-center items-center w-full"
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          <div
                            className={
                              imageList.length
                                ? "flex flex-wrap gap-4 justify-center items-center w-full h-64 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer "
                                : "flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer "
                            }
                          >
                            {imageList.length ? (
                              imageList.map((image, index) => {
                                /* eslint-disable */

                                return (
                                  <img
                                    src={image.data_url}
                                    key={index}
                                    alt="fundraise img"
                                    width="180px"
                                    className="rounded-lg max-h-[240px] max-w-[350px]"
                                  />
                                );
                              })
                            ) : (
                              <div className="flex flex-col justify-center items-center pt-5 pb-6">
                                <svg
                                  aria-hidden="true"
                                  className="mb-3 w-10 h-10 text-gray-400"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                  />
                                </svg>
                                <p className="mb-2 text-sm text-gray-500 ">
                                  Click to upload or drag and drop
                                </p>
                                <p className="text-xs text-gray-500 ">
                                  All .jpg, .png files up to 10 MB are
                                  supported.
                                </p>
                              </div>
                            )}
                          </div>
                        </button>
                      )}
                    </ImageUploading>
                  </div>
                  <div>
                    <label
                      htmlFor="country"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Country
                    </label>
                    <select
                      name="country"
                      className={
                        errors.country && touched.country
                          ? "text-base bg-gray-50 border border-red-300 text-gray-500 rounded-lg focus:ring-red-300 focus:border-red-300 block w-full p-2.5"
                          : "text-base bg-gray-50 border border-gray-300 text-gray-500 rounded-lg focus:ring-gray-300 focus:border-gray-300 block w-full p-2.5"
                      }
                      value={values.country}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Chose your country</option>
                      {countries.map((country) => (
                        <option key={country.id} value={country.en}>
                          {country.en}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <button
                  type="submit"
                  className={
                    errors.firstName || errors.lastName || errors.country
                      ? "w-full focus:outline-none text-gray-900 bg-gray-300 hover:bg-gray-400 font-medium rounded-lg text-base py-3"
                      : "w-full focus:outline-none text-white bg-primary-100 hover:bg-green-500 font-medium rounded-lg text-base py-3"
                  }
                  disabled={
                    errors.firstName || errors.lastName || errors.country
                  }
                >
                  Continue
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CreateDonorProfile;
