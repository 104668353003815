import React from "react";

const LocationMarker = () => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.03982 1.7399C3.09008 0.689638 4.51453 0.0996094 5.99982 0.0996094C7.48511 0.0996094 8.90956 0.689638 9.95982 1.7399C11.0101 2.79015 11.6001 4.21461 11.6001 5.69989C11.6001 7.18518 11.0101 8.60964 9.95982 9.65989L5.99982 13.6199L2.03982 9.65989C1.51975 9.13988 1.10721 8.52251 0.825743 7.84305C0.544281 7.16359 0.399414 6.43535 0.399414 5.69989C0.399414 4.96444 0.544281 4.2362 0.825743 3.55674C1.10721 2.87728 1.51975 2.25991 2.03982 1.7399ZM5.99982 7.29989C6.42416 7.29989 6.83113 7.13132 7.13119 6.83126C7.43125 6.53121 7.59982 6.12424 7.59982 5.69989C7.59982 5.27555 7.43125 4.86858 7.13119 4.56852C6.83113 4.26847 6.42416 4.0999 5.99982 4.0999C5.57547 4.0999 5.16851 4.26847 4.86845 4.56852C4.56839 4.86858 4.39982 5.27555 4.39982 5.69989C4.39982 6.12424 4.56839 6.53121 4.86845 6.83126C5.16851 7.13132 5.57547 7.29989 5.99982 7.29989Z"
        fill="#1F2A37"
      />
    </svg>
  );
};

export default LocationMarker;
