import React from "react";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  updateDonor,
  updateFundraiser,
} from "../../../store/reducers/userSlice";
import countries from "../../../constans/countries";
import CreateProfileWithSocialValidation from "../config/CreateProfileWithSocialValidation";
import userRoles from "../../../constans/userRoles";

const CreateProfileWithSocialNetwork = () => {
  const socialNetwork = localStorage.getItem("socialNetwork");
  const userRole = localStorage.getItem("userRole");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSuccessSubmit = () => {
    return userRole.includes(userRoles.DONOR)
      ? navigate("/explore")
      : navigate("/signup/fundraiser/connect-wallet");
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
        <div className="p-6 ">
          <div className="space-y-2 mb-6">
            <h1 className="text-2xl font-bold leading-tight tracking-tight text-gray-900">
              Let&apos;s get started
            </h1>
            <h2 className="font-medium text-base text-gray-500">
              Please enter the information below.
            </h2>
          </div>
          <Formik
            initialValues={{
              country: "",
              bio: "",
              email: "",
            }}
            onSubmit={async (values) => {
              /* eslint-disable no-unused-expressions */
              if (userRole.includes(userRoles.FUNDRAISER)) {
                socialNetwork === "facebook"
                  ? await dispatch(
                      updateFundraiser({
                        userData: {
                          country: values.country,
                          bio: values.bio,
                          email: values.email,
                        },
                        onSuccess: () => onSuccessSubmit(),
                      }),
                    )
                  : await dispatch(
                      updateFundraiser({
                        userData: { country: values.country, bio: values.bio },
                        onSuccess: () => onSuccessSubmit(),
                      }),
                    );
              } else {
                socialNetwork === "facebook"
                  ? await dispatch(
                      updateDonor({
                        userData: {
                          country: values.country,
                          email: values.email,
                        },
                        onSuccess: () => onSuccessSubmit(),
                      }),
                    )
                  : await dispatch(
                      updateDonor({
                        userData: { country: values.country },
                        onSuccess: () => onSuccessSubmit(),
                      }),
                    );
              }
            }}
            validationSchema={CreateProfileWithSocialValidation(socialNetwork)}
          >
            {({
              handleSubmit,
              values,
              handleChange,
              handleBlur,
              errors,
              touched,
            }) => (
              <Form onSubmit={handleSubmit} className="min-w-[370px] mb-4">
                <div className="space-y-5 mb-6">
                  {socialNetwork === "facebook" && (
                    <div>
                      <label
                        htmlFor="email"
                        className="block mb-2 text-sm font-medium text-gray-900"
                      >
                        Email:
                      </label>
                      <input
                        type="text"
                        name="email"
                        id="email"
                        className={
                          errors.email && touched.email
                            ? "text-base bg-gray-50 border-2 border-red-300 text-gray-500 rounded-lg focus:ring-red-300 focus:border-red-300 block w-full p-2.5"
                            : "text-base bg-gray-50 border border-gray-300 text-gray-500 rounded-lg focus:ring-gray-300 focus:border-gray-300 block w-full p-2.5"
                        }
                        placeholder="Enter your email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                    </div>
                  )}
                  <div>
                    <label
                      htmlFor="country"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Country
                    </label>
                    <select
                      name="country"
                      className={
                        errors.country && touched.country
                          ? "text-base bg-gray-50 border-2 border-red-300 text-gray-500 rounded-lg focus:ring-red-300 focus:border-red-300 block w-full p-2.5"
                          : "text-base bg-gray-50 border border-gray-300 text-gray-500 rounded-lg focus:ring-gray-300 focus:border-gray-300 block w-full p-2.5"
                      }
                      value={values.country}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Chose your country</option>
                      {countries.map((country) => (
                        <option key={country.id} value={country.en}>
                          {country.en}
                        </option>
                      ))}
                    </select>
                  </div>
                  {userRole.includes(userRoles.FUNDRAISER) && (
                    <div>
                      <label
                        htmlFor="bio"
                        className="block mb-2 text-sm font-medium text-gray-900"
                      >
                        A few words about you
                      </label>
                      <textarea
                        name="bio"
                        placeholder="Write text here ..."
                        className="text-base block pt-2.5 pb-14 w-full bg-gray-50 border border-gray-300 text-gray-500 rounded-lg focus:ring-gray-300 focus:border-gray-300 block"
                        value={values.bio}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  )}
                </div>
                <button
                  type="submit"
                  className={
                    errors.country || errors.email
                      ? "w-full focus:outline-none text-gray-900 bg-gray-300 hover:bg-gray-400 font-medium rounded-lg text-base py-3"
                      : "w-full focus:outline-none text-white bg-primary-100 hover:bg-green-500 font-medium rounded-lg text-base py-3"
                  }
                  disabled={errors.country || errors.email}
                >
                  Continue
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CreateProfileWithSocialNetwork;
