/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-debounce";
import { Spinner, Avatar } from "flowbite-react";
import Logo from "../svg/logo/Logo";
import { logout } from "../store/reducers/userSlice";
import { setSearchByTitleValue } from "../store/reducers/fundraiseSlice";
import userRoles from "../constans/userRoles";

const MainHeader = () => {
  const [searchValue, setSearchValue] = useState("");
  const [value] = useDebounce(searchValue, 1000);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    dispatch(setSearchByTitleValue(value));
  }, [value, dispatch, pathname]);

  return (
    <header className="border-b border-gray-100">
      <nav className="bg-white">
        <div className="flex flex-wrap justify-between items-center max-w-[1440px] max-2xl:mx-[50px] mx-auto">
          <div className="flex">
            <Link
              to={`${
                currentUser?.role === userRoles.FUNDRAISER
                  ? "/my-fundraisers/approved"
                  : "/explore"
              }`}
              className="flex items-center mr-8"
            >
              <Logo />
            </Link>
            <nav>
              <ul className="flex">
                <Link to="/explore">
                  <li
                    className={
                      pathname.includes("explore")
                        ? "text-base font-medium block py-5 mx-4 text-primary-100 border-primary-100 cursor-pointer border-b-2"
                        : "text-base font-medium block py-5 mx-4 text-gray-500 hover:border-b-2 hover:text-primary-100 hover:border-primary-100 cursor-pointer border-b-2 border-white"
                    }
                  >
                    Explore
                  </li>
                </Link>
                {currentUser && currentUser.role === userRoles.FUNDRAISER && (
                  <Link to="/my-fundraisers/approved">
                    <li
                      className={
                        pathname.includes("my-fundraisers")
                          ? "text-base font-medium block py-5 mx-4 text-primary-100 border-primary-100 cursor-pointer border-b-2"
                          : "text-base font-medium block py-5 mx-4 text-gray-500 hover:border-b-2 hover:text-primary-100 hover:border-primary-100 cursor-pointer border-b-2 border-white"
                      }
                    >
                      My fundraisers
                    </li>
                  </Link>
                )}
                <Link to="/analytics">
                  <li
                    className={
                      pathname.includes("analytics")
                        ? "text-base font-medium block py-5 mx-4 text-primary-100 border-primary-100 cursor-pointer border-b-2"
                        : "text-base font-medium block py-5 mx-4 text-gray-500 hover:border-b-2 hover:text-primary-100 hover:border-primary-100 cursor-pointer border-b-2 border-white"
                    }
                  >
                    Analytics
                  </li>
                </Link>
              </ul>
            </nav>
          </div>

          <div className="flex items-center justify-between">
            {!pathname.includes("analytics") && (
              <div className="mr-6">
                <label
                  htmlFor="search-bar"
                  className="mb-2 text-base font-normal text-gray-500 sr-only"
                >
                  Search
                </label>
                <div className="relative hidden lg:block">
                  <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg
                      className="w-5 h-5 text-gray-500"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="search-bar"
                    className="block py-2 px-4 pl-10 w-full text-base text-gray-500 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-100 focus:border-primary-100"
                    placeholder="Search"
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </div>
              </div>
            )}
            {!currentUser ? (
              <div className="h-[35px] w-[35px]">
                <Spinner
                  aria-label="Medium sized spinner example"
                  color="success"
                  size="lg"
                />
              </div>
            ) : (
              (!currentUser?.firstName || !currentUser?.lastName) &&
              !currentUser?.avatarUrl && (
                <Avatar
                  size="sm"
                  rounded
                  className="cursor-pointer"
                  onClick={() => navigate("/profile")}
                />
              )
            )}
            {currentUser?.avatarUrl ? (
              <img
                src={currentUser.avatarUrl}
                alt="avatar"
                className="object-cover rounded-full w-[35px] h-[35px] cursor-pointer"
                onClick={() => navigate("/profile")}
              />
            ) : (
              currentUser?.firstName &&
              currentUser?.lastName && (
                <div
                  className="h-[35px] w-[35px] rounded-full bg-gray-500 flex items-center justify-center text-center text-base font-medium text-white cursor-pointer"
                  onClick={() => navigate("/profile")}
                >
                  {currentUser.firstName.split("").shift() +
                    currentUser.lastName.split("").shift()}
                </div>
              )
            )}

            <button
              type="button"
              onClick={() =>
                dispatch(logout({ onSuccess: () => navigate("/") }))
              }
              className="text-primary-100 hover:bg-gray-100 font-medium rounded-lg text-base px-4 py-2 focus:outline-none cursor-pointer  ml-6"
            >
              Log out
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default MainHeader;
