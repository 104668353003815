import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import EnterEmail from "../../components/ressetPassword/EnterEmail";
import EmailVerification from "../../components/auth/EmailVerification";
import NewPassword from "../../components/ressetPassword/NewPassword";
import PasswordUpdated from "../../components/ressetPassword/PasswordUpdated";

const ResetPasswordPage = () => {
  const [emailForReset, setEmailForReset] = useState("");

  return (
    <Routes>
      <Route
        path="email"
        element={
          <EnterEmail
            emailForReset={emailForReset}
            setEmailForReset={setEmailForReset}
          />
        }
      />
      <Route
        path="verification"
        element={<EmailVerification emailForReset={emailForReset} />}
      />

      <Route path="newPassword" element={<NewPassword />} />
      <Route path="passwordUpdated" element={<PasswordUpdated />} />
    </Routes>
  );
};

export default ResetPasswordPage;
