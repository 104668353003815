import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import userRoles from "../../../constans/userRoles";

const TwoFactorAuthenticationOptions = () => {
  const [twoFactorOption, setTwoFactorOption] = useState("");

  const navigate = useNavigate();
  const userRole = localStorage.getItem("userRole");
  const socialNetwork = localStorage.getItem("socialNetwork");

  /* eslint-disable consistent-return */
  const handleSubmit = () => {
    if (userRole.includes(userRoles.FUNDRAISER)) {
      return twoFactorOption === "SMS"
        ? navigate("/signup/fundraiser/two-factor-options/phone")
        : navigate("/signup/fundraiser/two-factor-options/app");
    }
    if (userRole.includes(userRoles.DONOR)) {
      return twoFactorOption === "SMS"
        ? navigate("/signup/donor/two-factor-options/phone")
        : navigate("/signup/donor/two-factor-options/app");
    }
  };
  return (
    <div className="bg-white rounded p-6 w-[420px] flex flex-col items-center justify-center">
      <div className="font-bold text-2xl text-gray-900 mb-2">
        Choose your two-factor authentication method
      </div>
      <div className="font-semibold text-base text-gray-500">
        With two-factor authentication, you will protect your account.
      </div>
      <ul className="py-5 mb-6">
        <li>
          <label
            className="flex p-5 rounded bg-gray-50 items-center mb-6 cursor-pointer border border-gray-50 hover:border-primary-100"
            htmlFor="helper-radio-4"
            onClick={() => setTwoFactorOption("APP")}
          >
            <input
              id="helper-radio-4"
              name="helper-radio"
              type="radio"
              value=""
              className="w-4 h-4 text-primary-100 focus:ring-primary-100"
            />
            <div className="ml-4">
              <p className="text-xl font-semibold text-gray-800">
                Authentication app
              </p>
              <p
                id="helper-radio-text-4"
                className="font-medium text-base text-gray-600"
              >
                Get codes from a Google Authenticator app.
              </p>
            </div>
          </label>
        </li>
        <li>
          <label
            className="flex p-5 rounded bg-gray-50 items-center cursor-pointer border border-gray-50 hover:border-primary-100"
            htmlFor="helper-radio-5"
            onClick={() => setTwoFactorOption("SMS")}
          >
            <input
              id="helper-radio-5"
              name="helper-radio"
              type="radio"
              value=""
              className="w-4 h-4 text-primary-100 focus:ring-primary-100"
            />
            <div className="ml-4">
              <p className="text-xl font-semibold text-gray-800">SMS</p>
              <p
                id="helper-radio-text-5"
                className="font-medium text-base text-gray-600"
              >
                Receive a unique code via SMS.
              </p>
            </div>
          </label>
        </li>
      </ul>
      <button
        type="button"
        className={
          twoFactorOption
            ? "w-full focus:outline-none text-white bg-primary-100 hover:bg-green-500 font-medium rounded-lg text-base py-3 mb-4"
            : "w-full focus:outline-none text-gray-900 bg-gray-300 hover:bg-gray-400 font-medium rounded-lg text-base py-3 mb-4"
        }
        disabled={!twoFactorOption}
        onClick={handleSubmit}
      >
        Continue
      </button>
      <button
        type="button"
        className="text-primary-100 font-normal text-base"
        onClick={() => {
          if (socialNetwork) {
            return userRole.includes(userRoles.FUNDRAISER)
              ? navigate("/signup/fundraiser/socialAuth")
              : navigate("/signup/donor/socialAuth");
          }
          return userRole.includes(userRoles.FUNDRAISER)
            ? navigate("/signup/fundraiser/fundraiserProfile")
            : navigate("/signup/donor/donorProfile");
        }}
      >
        Do it later
      </button>
    </div>
  );
};

export default TwoFactorAuthenticationOptions;
