import React from "react";
import uuid from "react-uuid";
import fundraiseGoalStatuses from "../../constans/fundraiseGoalStatuses";

const DonorAnalyticTable = ({ allDonationsByDonor }) => {
  return (
    <div className="shadow p-8 rounded-md bg-white">
      <h2 className="font-bold text-2xl pb-8 text-gray-800">
        Donation history
      </h2>
      <div className="relative overflow-x-auto rounded-md shadow">
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-gray-500 bg-gray-50 border-b">
            <tr>
              <th scope="col" className="px-6 py-3 font-normal text-sm">
                Project name
              </th>
              <th scope="col" className="px-6 py-3 font-normal text-sm">
                Countries
              </th>
              <th scope="col" className="px-6 py-3 font-normal text-sm">
                Currency
              </th>
              <th scope="col" className="px-6 py-3 font-normal text-sm">
                Donate amount
              </th>
              <th scope="col" className="px-6 py-3 font-normal text-sm">
                Fundraise amount
              </th>
              <th scope="col" className="px-6 py-3 font-normal text-sm">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {allDonationsByDonor?.rows.map((donation) => {
              return (
                <tr className="bg-white border-b" key={uuid()}>
                  <th
                    scope="row"
                    className="px-6 py-4 font-normal text-base text-gray-800 whitespace-nowrap"
                  >
                    <div className="flex items-center gap-3 text-ellipsis whitespace-nowrap overflow-hidden max-w-[230px]">
                      {donation?.fundraise?.metadata?.title}
                    </div>
                  </th>
                  <td className="px-6 py-4 text-gray-500 font-normal text-base">
                    {donation?.fundraise?.wallet?.fundraiser?.country}
                  </td>
                  <td className="px-6 py-4 text-gray-500 font-normal text-base">
                    {donation?.fundraise?.currency}
                  </td>
                  <td className="px-6 py-4 text-gray-500 font-normal text-base">
                    {donation?.fundraise?.raisedAmount}
                  </td>
                  <td className="px-6 py-4 text-gray-500 font-normal text-base">
                    {donation?.fundraise?.goal}
                  </td>
                  <td className="px-6 py-4 text-gray-500 font-normal text-base">
                    {(donation?.fundraise?.status ===
                      fundraiseGoalStatuses.GOALREACHED ||
                      donation?.fundraise?.status ===
                        fundraiseGoalStatuses.DEADLINEPASSED ||
                      donation?.fundraise?.status ===
                        fundraiseGoalStatuses.ACTIVE) && (
                      <span className="py-[2px] px-[10px] bg-green-100 rounded-md text-green-800 text-base">
                        Funded
                      </span>
                    )}
                    {donation?.fundraise?.status ===
                      fundraiseGoalStatuses.WITHDRAWN && (
                      <span className="py-[2px] px-[10px] bg-red-100 rounded-md text-red-800 text-base">
                        Reimbursed
                      </span>
                    )}
                    {donation?.fundraise?.status ===
                      fundraiseGoalStatuses.GOALNOTREACHED && (
                      <span className="py-[2px] px-[10px] bg-yellow-100 rounded-md text-red-800 text-base">
                        Reimburse pending
                      </span>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DonorAnalyticTable;
