import * as Yup from "yup";

const ResetPasswordValidation = () =>
  Yup.object().shape({
    newPassword: Yup.string()
      .required()
      .min(8, "8 or more characters required")
      .matches(/^.*[A-Z]/, "upperCase required")
      .matches(/[a-z]/, "lowerCase required")
      .matches(/[0-9]/, "number required")
      .matches(/[#$&_"'!><.,?/@%*)^:;|}{(+=-]/, "symbol required")
      .matches(/^\S*$/, "withoutSpaceOnly"),

    confirmPassword: Yup.string()
      .required()
      .min(8, "8 or more characters required")
      .matches(/^.*[A-Z]/, "upperCase required")
      .matches(/[a-z]/, "lowerCase required")
      .matches(/[0-9]/, "number required")
      .matches(/[#$&_"'!><.,?/@%*)^:;|}{(+=-]/, "symbol required")
      .matches(/^\S*$/, "withoutSpaceOnly"),
  });

export default ResetPasswordValidation;
