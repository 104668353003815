import React, { useEffect, useState } from "react";
// import axios from "axios";
import { useNavigate } from "react-router-dom";
import { addDays, format, fromUnixTime } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { getMyApprovedFundraisers } from "../../store/reducers/fundraiseSlice";
/* eslint-disable */

const FundraiserAnalyticTable = () => {
  const [isActive, setIsActive] = useState(true);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const runningStatus = isActive ? ["ACTIVE"] : ["GOALREACHED", "WITHDRAWN"];

  useEffect(() => {
    dispatch(getMyApprovedFundraisers({ runningStatus }));
  }, [dispatch, isActive]);

  const myFundraisers = useSelector((state) => state.fundraise.myFundraisers);

  return (
    <div className="shadow p-8 rounded-md bg-white">
      <h2 className="font-bold text-2xl text-gray-800 pb-8">
        {myFundraisers.length ? "Your fundraising companies" : "No fundraisers"}
      </h2>

      <nav className="border-b border-gray-300 mb-4">
        <ul className="flex gap-4 p-[10px]">
          <li
            className={
              isActive
                ? "text-base font-semibold text-primary-100 cursor-pointer"
                : "text-base font-semibold text-gray-500 cursor-pointer"
            }
            onClick={() => setIsActive(true)}
          >
            Active
          </li>{" "}
          <li
            className={
              !isActive
                ? "text-base font-semibold text-primary-100 cursor-pointer"
                : "text-base font-semibold text-gray-500 cursor-pointer"
            }
            onClick={() => setIsActive(false)}
          >
            Archived
          </li>
        </ul>
      </nav>
      {myFundraisers?.length ? (
        <div className="relative overflow-x-auto rounded-md shadow">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-gray-500 bg-gray-50 border-b">
              <tr>
                <th scope="col" className="px-6 py-3 font-normal text-sm">
                  Goal name
                </th>
                <th scope="col" className="px-6 py-3 font-normal text-sm">
                  Published
                </th>
                <th scope="col" className="px-6 py-3 font-normal text-sm">
                  Ends
                </th>
                <th scope="col" className="px-6 py-3 font-normal text-sm">
                  Goal type
                </th>
                <th scope="col" className="px-6 py-3 font-normal text-sm">
                  Category
                </th>
                <th scope="col" className="px-6 py-3 font-normal text-sm">
                  Raised
                </th>
              </tr>
            </thead>
            <tbody>
              {myFundraisers.map((fundraise) => {
                return (
                  <tr
                    className="bg-white border-b hover:bg-gray-100 cursor-pointer"
                    onClick={() =>
                      navigate(`/analytics/fundraise/${fundraise.id}`)
                    }
                    key={fundraise.id}
                  >
                    <th
                      scope="row"
                      className="px-6 py-4 font-normal text-base text-gray-800 whitespace-nowrap"
                    >
                      <div className="text-ellipsis whitespace-nowrap overflow-hidden max-w-[230px]">
                        {fundraise?.title}
                      </div>
                    </th>
                    <td className="px-6 py-4 text-gray-500 font-normal text-base">
                      {fundraise?.fundraise?.createdAt &&
                        format(
                          new Date(fundraise?.fundraise?.createdAt),
                          "LLL dd, yyyy",
                        )}
                    </td>
                    <td className="px-6 py-4 text-gray-500 font-normal text-base">
                      {fundraise.deadlineDays
                        ? format(
                            addDays(new Date(), fundraise?.deadlineDays),
                            "LLL dd, yyyy",
                          )
                        : format(
                            new Date(
                              fromUnixTime(fundraise?.deadlineTimestamp),
                            ),
                            "LLL dd, yyyy",
                          )}
                    </td>
                    <td className="px-6 py-4 text-gray-500 font-normal text-base">
                      {fundraise?.goalType[0]}
                      {fundraise?.goalType.slice(1).toLowerCase()}
                    </td>
                    <td className="px-6 py-4 text-gray-500 font-normal text-base">
                      {fundraise?.categories?.[0].name}
                    </td>
                    <td className="px-6 py-4">
                      <div className="pb-3 text-sm font-normal text-gray-500">
                        <span className="text-sm font-medium text-gray-800">
                          {fundraise?.fundraise?.raisedAmount}{" "}
                          {fundraise?.goalCurrency}{" "}
                        </span>
                        raised of {fundraise?.fundraise?.goal}{" "}
                        {fundraise?.goalCurrency}
                      </div>
                      <div className="w-full bg-gray-100 rounded-md">
                        <div
                          className="bg-primary-100 h-1.5 rounded-md"
                          style={{
                            width: `${
                              (fundraise?.fundraise?.raisedAmount * 100) /
                              fundraise?.fundraise?.goal
                            }%`,
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default FundraiserAnalyticTable;
