import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

/* eslint-disable */

export class AnalyticDonutChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: this.props.dataArr,
      options: {
        chart: {
          type: "pie",
        },
        labels: this.props.labels,
        stroke: {
          show: true,
          width: 1,
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          custom: function ({ series, seriesIndex, w }) {
            return (
              '<div class="arrow_box">' +
              "<span>" +
              w.globals.labels[seriesIndex] +
              " - " +
              series[seriesIndex] +
              " " +
              "%" +
              "</span>" +
              "</div>"
            );
          },
        },
        legend: {
          position: "left",
          horizontalAlign: "left",
          floating: false,
          itemMargin: {
            vertical: 4,
            horizontal: this.props.margin,
          },
          formatter: function (seriesName, opts) {
            return [
              seriesName,
              " - ",
              opts.w.globals.series[opts.seriesIndex],
              "%",
            ];
          },
          fontSize: 16,
          fontWeight: "500",
        },
      },
    };
  }

  render() {
    this.state = {
      series: this.props.dataArr,
      options: { ...this.state.options, labels: this.props.labels },
    };
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="pie"
          width="100%"
          height={250}
        />
      </div>
    );
  }
}
