import React from "react";

const WhiteBadgeCalendar = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.66667 4.83333V1.5M12.3333 4.83333V1.5M4.83333 8.16667H13.1667M3.16667 16.5H14.8333C15.2754 16.5 15.6993 16.3244 16.0118 16.0118C16.3244 15.6993 16.5 15.2754 16.5 14.8333V4.83333C16.5 4.39131 16.3244 3.96738 16.0118 3.65482C15.6993 3.34226 15.2754 3.16667 14.8333 3.16667H3.16667C2.72464 3.16667 2.30072 3.34226 1.98816 3.65482C1.67559 3.96738 1.5 4.39131 1.5 4.83333V14.8333C1.5 15.2754 1.67559 15.6993 1.98816 16.0118C2.30072 16.3244 2.72464 16.5 3.16667 16.5Z"
        stroke="#4B5563"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WhiteBadgeCalendar;
