import React from "react";

const TotalDonors = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="8" fill="#206B9F" fillOpacity="0.1" />
      <path
        d="M15 12C15 12.7956 14.6839 13.5587 14.1213 14.1213C13.5587 14.6839 12.7956 15 12 15C11.2044 15 10.4413 14.6839 9.87868 14.1213C9.31607 13.5587 9 12.7956 9 12C9 11.2044 9.31607 10.4413 9.87868 9.87868C10.4413 9.31607 11.2044 9 12 9C12.7956 9 13.5587 9.31607 14.1213 9.87868C14.6839 10.4413 15 11.2044 15 12ZM23 12C23 12.394 22.9224 12.7841 22.7716 13.1481C22.6209 13.512 22.3999 13.8427 22.1213 14.1213C21.8427 14.3999 21.512 14.6209 21.1481 14.7716C20.7841 14.9224 20.394 15 20 15C19.606 15 19.2159 14.9224 18.8519 14.7716C18.488 14.6209 18.1573 14.3999 17.8787 14.1213C17.6001 13.8427 17.3791 13.512 17.2284 13.1481C17.0776 12.7841 17 12.394 17 12C17 11.2044 17.3161 10.4413 17.8787 9.87868C18.4413 9.31607 19.2044 9 20 9C20.7956 9 21.5587 9.31607 22.1213 9.87868C22.6839 10.4413 23 11.2044 23 12ZM18.93 23C18.976 22.673 19 22.34 19 22C19.0023 20.4289 18.4737 18.903 17.5 17.67C18.2601 17.2312 19.1223 17.0001 20 17.0001C20.8776 17.0001 21.7399 17.2311 22.4999 17.67C23.26 18.1088 23.8912 18.74 24.3301 19.5C24.7689 20.2601 25 21.1223 25 22V23H18.93ZM12 17C13.3261 17 14.5979 17.5268 15.5355 18.4645C16.4732 19.4021 17 20.6739 17 22V23H7V22C7 20.6739 7.52678 19.4021 8.46447 18.4645C9.40215 17.5268 10.6739 17 12 17Z"
        fill="#206B9F"
      />
    </svg>
  );
};

export default TotalDonors;
