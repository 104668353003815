import React from "react";

const StoryGeneralPopover = () => {
  return (
    <div
      id="popover-password"
      role="tooltip"
      style={{ position: "absolute" }}
      className="inline-block absolute left-[-150px] bottom-[30px] z-10 w-[400px] text-base text-gray-800 bg-white rounded-md border border-gray-200 shadow-sm duration-300"
    >
      <h2 className="font-semibold px-3 py-2">
        Few tips to tell a great story
      </h2>
      <div id="tooltip-dark" role="tooltip" className="px-3 py-2">
        1. Describe what challenge are you trying to solve. <br />
        2. Describe why is it important to solve this and what can happen if
        this challenge is not solved.
        <br />
        3. What positive change will happen? <br />
        4. Add gap or barrier to better outcomes. <br />
        5. Make supporters the heroes of the story by showing their impact.
        <br />
        6. Explain to the supporter what they will get in exchange for their
        donation.
      </div>
    </div>
  );
};

export default StoryGeneralPopover;
