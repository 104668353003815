import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setFundraiseType } from "../../store/reducers/fundraiseSlice";
import fundraiseTypes from "../../constans/fundraiseTypes";
import createFundraiseSteps from "../../constans/createFundraiseSteps";

const Start = ({ setCreateFundraiseStep, setOwnerType, ownerType }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="relative p-4 w-full max-w-3xl h-full md:h-auto">
      <div className="relative p-4 bg-white rounded-lg shadow sm:p-5">
        <h2 className="font-bold text-2xl text-gray-900">
          Who is the money for?
        </h2>
        <ul className="py-5">
          <li>
            <label
              className="flex p-5 rounded bg-gray-50 items-center mb-6 cursor-pointer border border-gray-50 hover:border-primary-100"
              htmlFor="helper-radio-1"
              onClick={() => {
                setOwnerType(fundraiseTypes.PERSONAL);
              }}
            >
              <input
                id="helper-radio-1"
                name="helper-radio"
                type="radio"
                value=""
                className="w-4 h-4 text-primary-100 focus:ring-primary-100 cursor-pointer"
                defaultChecked={ownerType === fundraiseTypes.PERSONAL}
              />
              <div className="ml-4">
                <p className="text-xl font-semibold text-gray-800">
                  You or someone you know
                </p>
                <p
                  id="helper-radio-text-1"
                  className="font-medium text-base text-gray-500"
                >
                  The money is for you, a family member, or a friend.
                </p>
              </div>
            </label>
          </li>
          <li>
            <label
              className="flex p-5 rounded bg-gray-50 items-center cursor-pointer border border-gray-50 hover:border-primary-100"
              htmlFor="helper-radio-2"
              onClick={() => {
                setOwnerType(fundraiseTypes.ORGANIZATION);
              }}
            >
              <input
                id="helper-radio-2"
                name="helper-radio"
                type="radio"
                value=""
                className="w-4 h-4 text-primary-100 focus:ring-primary-100 cursor-pointer"
                defaultChecked={ownerType === fundraiseTypes.ORGANIZATION}
              />
              <div className="ml-4">
                <p className="text-xl font-semibold text-gray-800">
                  An organization
                </p>
                <p
                  id="helper-radio-text-5"
                  className="font-medium text-base text-gray-500"
                >
                  The money is for a non-profit, business, group, or other
                  organization you&apos;re authorized to represent.
                </p>
              </div>
            </label>
          </li>
        </ul>
        <div className="pt-5 bg-white flex justify-between">
          <button
            type="button"
            className="py-2 px-5 text-sm font-medium text-gray-800 focus:outline-none rounded-lg border border-gray-200 hover:bg-gray-100"
            onClick={() => navigate(-1)}
          >
            Cancel
          </button>

          <button
            type="button"
            disabled={!ownerType}
            className={
              !ownerType
                ? "py-2 px-5 text-sm font-medium text-white focus:outline-none bg-gray-300 rounded-lg border border-gray-200"
                : "py-2 px-5 text-sm font-medium text-white focus:outline-none bg-primary-100 rounded-lg border border-gray-200"
            }
            onClick={() => {
              dispatch(setFundraiseType(ownerType));
              if (ownerType === fundraiseTypes.ORGANIZATION) {
                setCreateFundraiseStep(createFundraiseSteps.COMPANY);
              } else {
                setCreateFundraiseStep(createFundraiseSteps.GENERALINFO);
              }
            }}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default Start;
