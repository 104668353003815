import React from "react";

const Facebook = () => {
  return (
    <svg
      width="11"
      height="18"
      viewBox="0 0 11 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.53516 10.125L10.0273 6.89062H6.89844V4.78125C6.89844 3.86719 7.32031 3.02344 8.72656 3.02344H10.168V0.246094C10.168 0.246094 8.86719 0 7.63672 0C5.07031 0 3.38281 1.58203 3.38281 4.39453V6.89062H0.5V10.125H3.38281V18H6.89844V10.125H9.53516Z"
        fill="#1F2A37"
      />
    </svg>
  );
};

export default Facebook;
