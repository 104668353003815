import React from "react";

const CategoryGeneralPopover = () => {
  return (
    <div
      id="popover-password"
      role="tooltip"
      style={{ position: "absolute" }}
      className="inline-block absolute left-[-150px] bottom-[30px] z-10 w-[400px] text-base text-gray-800 bg-white rounded-md border border-gray-200 shadow-sm duration-300"
    >
      <div id="tooltip-dark" role="tooltip" className="px-4 py-2">
        Choose the category that best describes why you are fundraising. This
        will help donors find your fundraiser through filtering and sorting.
      </div>
    </div>
  );
};

export default CategoryGeneralPopover;
