/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { formatDistanceToNowStrict, fromUnixTime } from "date-fns";
import { Link } from "react-router-dom";
import Img from "react-cool-img";
import fundraiseStatuses from "../../constans/fundraiseStatuses";
import BlackBadgeCalendar from "../../svg/badgeCalendar/BlackBadgeCalendar";
import fundraiseTypes from "../../constans/fundraiseTypes";
import Logo from "../../svg/logo/Logo";

const MyFundraiseCard = ({ fundraise, currentUser, approvedMyFundraise }) => {
  return (
    <Link
      to={`/my-fundraisers/fundraise/${fundraise.id}`}
      state={{ approvedMyFundraise }}
    >
      <div className="2xl:w-[345px] lg:w-[300px] md:w-[280px] w-full h-full md:h-auto cursor-pointer">
        <div className="relative">
          {fundraise?.photos?.length ? (
            <Img
              src={fundraise?.photos[0]?.url}
              alt="fundraise img"
              className="rounded-t-lg h-[168px] object-cover w-full"
            />
          ) : (
            <div className="rounded-t-lg h-[168px] w-full bg-white flex items-center justify-center">
              <Logo />
            </div>
          )}
          <div className="absolute bottom-[10px] left-[25px] flex gap-5">
            {fundraise.goalType === "FLEXIBLE" ? (
              <span className="text-sm font-normal text-white bg-primary-100 px-2.5 rounded-md select-none flex items-center">
                Flexible
              </span>
            ) : (
              <span className="text-sm font-normal text-white bg-primary-100 px-2.5 rounded-md select-none flex items-center">
                Fixed
              </span>
            )}
            {fundraise.status === fundraiseStatuses.PENDING && (
              <span className="text-sm font-normal text-yellow-800 bg-yellow-100 px-2.5 rounded-md select-none flex items-center">
                Validation
              </span>
            )}
            {fundraise.status === fundraiseStatuses.DECLINED && (
              <span className="text-sm font-normal text-red-800 bg-red-100 px-2.5 rounded-md select-none flex items-center">
                Decline
              </span>
            )}
            {fundraise.status === fundraiseStatuses.APPROVED && (
              <span className="text-sm font-normal text-blue-800 bg-blue-100 px-2.5 rounded-md select-none flex items-center">
                Approved
              </span>
            )}
          </div>
        </div>
        <div className="px-6 bg-white rounded-b-lg shadow flex flex-col">
          <div className="pt-5 pb-3 flex items-center">
            {fundraise.deadlineDays ? (
              <span className="text-sm font-medium text-gray-800 bg-gray-100 px-2.5 py-0.5 rounded-md select-none flex items-center gap-2">
                <BlackBadgeCalendar />
                {fundraise.deadlineDays} days left
              </span>
            ) : (
              <span className="text-sm font-medium text-gray-800 bg-gray-100 px-2.5 py-0.5 rounded-md select-none flex items-center gap-2">
                <BlackBadgeCalendar />
                {formatDistanceToNowStrict(
                  new Date(fromUnixTime(fundraise.deadlineTimestamp)),
                )}{" "}
                left
              </span>
            )}
          </div>
          <div className="mb-5 ">
            <h2 className="font-normal text-xl text-gray-800 text-ellipsis whitespace-nowrap overflow-hidden">
              {fundraise.title}
            </h2>
            <h3 className="font-normal text-base text-gray-800 text-ellipsis whitespace-nowrap overflow-hidden">
              {fundraise.description}
            </h3>
          </div>
          <div className="flex items-center gap-2 mb-4">
            <h2 className="text-gray-800 font-semibold text-base">
              {fundraise.fundraise
                ? Number(fundraise.fundraise.raisedAmount).toFixed(3)
                : 0}{" "}
              {fundraise.goalCurrency}
            </h2>
            <h2 className="text-sm font-normal text-gray-500">
              raised of {fundraise.goal}
            </h2>
          </div>
          <div className="w-full bg-gray-100 mb-5 rounded-md">
            <div
              className="bg-primary-100 h-1.5 rounded-md"
              style={{
                width: `${
                  fundraise.fundraise
                    ? (fundraise.fundraise.raisedAmount * 100) / fundraise.goal
                    : 0
                }%`,
              }}
            />
          </div>
          <div className="flex gap-2 items-center mb-6">
            {currentUser?.avatarUrl ? (
              <img
                src={currentUser.avatarUrl}
                alt="avatar"
                className="h-[25px] w-[25px] rounded-full rounded-full object-cover"
              />
            ) : (
              currentUser?.firstName &&
              currentUser?.lastName && (
                <div className="h-[25px] w-[25px] rounded-full bg-gray-500 flex items-center justify-center text-center text-xs font-normal text-white">
                  {currentUser.firstName.split("").shift() +
                    currentUser.lastName.split("").shift()}
                </div>
              )
            )}
            <div>
              <p className="text-gray-800 text-base font-medium">
                {currentUser?.firstName} {currentUser?.lastName}
              </p>
              <p className="text-xs text-gray-500 font-medium">
                {fundraise.ownerType === fundraiseTypes.PERSONAL
                  ? "Personal fundraiser"
                  : "Organization fundraiser"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default MyFundraiseCard;
