import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ApiService from "../middleware/api";

const initialState = {
  fundraiserTotal: null,
  donorTotal: null,
  currentFundraise: null,
  countriesAnalytic: [],
  categoriesAnalytic: [],
  monthlyAnalytic: {},
};

export const fundraiserTotalAnalytic = createAsyncThunk(
  "analytic/fundraiserTotalAnalytic",
  async (currency) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/analytics/fundraiser/total/${currency}`,
        method: "GET",
      });
      return data;
    } catch (error) {
      return error;
    }
  },
);

export const donorTotalAnalytic = createAsyncThunk(
  "analytic/donorTotalAnalytic",
  async (currency) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/analytics/donor/total/${currency}`,
        method: "GET",
      });
      return data;
    } catch (error) {
      return error;
    }
  },
);

export const currentFundraiseAnalytic = createAsyncThunk(
  "analytic/currentFundraiseAnalytic",
  async (idAtContract) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/analytics/fundraiser/fundraise/${idAtContract}`,
        method: "GET",
      });
      return data;
    } catch (error) {
      return error;
    }
  },
);

export const currentFundraiseCountriesAnalytic = createAsyncThunk(
  "analytic/currentFundraiseCountriesAnalytic",
  async ({ idAtContract, onSuccess }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/analytics/fundraiser/fundraise/countries/${idAtContract}`,
        method: "GET",
      });
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      return error;
    }
  },
);

export const donorMonthlyAnalytic = createAsyncThunk(
  "analytic/donorMonthlyAnalytic",
  async () => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/analytics/donor/monthly`,
        method: "GET",
      });
      return data;
    } catch (error) {
      return error;
    }
  },
);

export const donorCountriesAnalytic = createAsyncThunk(
  "analytic/donorCountriesAnalytic",
  async () => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/analytics/donor/countries`,
        method: "GET",
      });
      return data;
    } catch (error) {
      return error;
    }
  },
);

export const donorCategoriesAnalytic = createAsyncThunk(
  "analytic/donorCategoriesAnalytic",
  async () => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/analytics/donor/categories`,
        method: "GET",
      });
      return data;
    } catch (error) {
      return error;
    }
  },
);

const userSlice = createSlice({
  name: "analytic",
  initialState,
  extraReducers: {
    [fundraiserTotalAnalytic.fulfilled]: (state, { payload }) => {
      state.fundraiserTotal = { ...payload };
    },
    [currentFundraiseAnalytic.fulfilled]: (state, { payload }) => {
      state.currentFundraise = { ...payload };
    },
    [currentFundraiseCountriesAnalytic.fulfilled]: (state, { payload }) => {
      state.countriesAnalytic = [...payload];
    },
    [donorTotalAnalytic.fulfilled]: (state, { payload }) => {
      state.donorTotal = { ...payload };
    },
    [donorMonthlyAnalytic.fulfilled]: (state, { payload }) => {
      state.monthlyAnalytic = { ...payload };
    },
    [donorCountriesAnalytic.fulfilled]: (state, { payload }) => {
      state.countriesAnalytic = [...payload];
    },
    [donorCategoriesAnalytic.fulfilled]: (state, { payload }) => {
      state.categoriesAnalytic = [...payload];
    },
  },
});

export default userSlice.reducer;
