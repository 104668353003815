/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addDays,
  format,
  formatDistanceToNowStrict,
  fromUnixTime,
} from "date-fns";
import uuid from "react-uuid";
import fundraiseTypes from "../../constans/fundraiseTypes";
import WhiteBadgeCalendar from "../../svg/badgeCalendar/WhiteBadgeCalendar";
import { getFundraiseById } from "../../store/reducers/fundraiseSlice";
import Img from "react-cool-img";
import Ellipse from "../../svg/ellipse/Ellipse";
import BlackBadgeCalendar from "../../svg/badgeCalendar/BlackBadgeCalendar";
import fundraiseStatuses from "../../constans/fundraiseStatuses";
import { getCurrentUser, logout } from "../../store/reducers/userSlice";
import { Spinner } from "flowbite-react";
import { ethers } from "ethers";
import { NonceManager } from "@ethersproject/experimental";
import { abi } from "../../constans/abi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAllDonations,
  getLimitedDonationByFundraise,
} from "../../store/reducers/donationsSlice";
import DonorsDonationsModal from "../modals/DonorsDonationsModal";
import DonationsList from "../donationsList/DonationsList";
import fundraiseGoalStatuses from "../../constans/fundraiseGoalStatuses";
import Logo from "../../svg/logo/Logo";

const MyFundraise = () => {
  const [showMore, setShowMore] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadedFundraise, setIsLoadedFundraise] = useState(false);
  const [isWithdrawn, setIsWithdrawn] = useState(false);

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const fundraise = useSelector((state) => state.fundraise.fundraise);
  const allDonations = useSelector(
    (state) => state.donations.allDonationsByFundraise,
  );
  const limitedDonations = useSelector(
    (state) => state.donations.limitedDonationsByFundraise,
  );
  const currentUser = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    dispatch(
      getFundraiseById({ id, onSuccess: () => setIsLoadedFundraise(true) }),
    );
    dispatch(getCurrentUser());
  }, [id, dispatch]);

  useEffect(() => {
    isLoadedFundraise &&
      state.approvedMyFundraise &&
      dispatch(
        getLimitedDonationByFundraise({
          id: fundraise.fundraise?.idAtContract,
          limit: 3,
          onSuccess: () => setIsLoading(false),
        }),
      );
  }, [dispatch, fundraise, id, isLoadedFundraise]);

  function getTokenAddress(goalCurrency) {
    switch (goalCurrency) {
      case "ETH":
        return "0x0000000000000000000000000000000000000000";
      case "USDT":
        return "0x0Ad4A8293f1df852074bED145a5a435810636B82";
      default:
        return "0x0000000000000000000000000000000000000000";
    }
  }

  // eslint-disable-next-line consistent-return
  function getDeadline(fundraiseMetadata) {
    // eslint-disable-next-line default-case
    switch (fundraiseMetadata.deadlineType) {
      case "DATE": {
        return fundraiseMetadata.deadlineTimestamp;
      }
      case "DAYS": {
        const date = new Date();
        date.setDate(date.getDate() + fundraiseMetadata.deadlineDays);
        return Math.floor(date.getTime() / 1000);
      }
    }
  }

  const createFundraise = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const accounts = await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();
    const nonceManager = new NonceManager(signer);

    const contract = new ethers.Contract(
      "0xA96aF6000E71dc7e4F4De9e2D5F97450E8585f9a",
      abi,
      signer,
    );
    const contractWithSigner = contract.connect(nonceManager);

    const token = getTokenAddress(fundraise.goalCurrency);
    const goal = ethers.utils.parseEther(fundraise.goal.toString());
    const deadline = getDeadline(fundraise);
    const allOrNothing = fundraise.goalType;
    try {
      const tx = await contractWithSigner.createFundraise(
        token,
        goal,
        deadline,
        allOrNothing,
        id,
      );
      await tx.wait();
      setIsPublished(true);
      setIsPublishing(false);
      toast.success("Published successful!");
    } catch (e) {
      console.log(e);
      setIsPublishing(false);
      toast.error("Failed to publish!");
    }
  };

  const finishFundraise = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();

    const contract = new ethers.Contract(
      "0xA96aF6000E71dc7e4F4De9e2D5F97450E8585f9a",
      abi,
      signer,
    );

    try {
      setIsWithdrawn(true);
      const tx = await contract.withdrawFunds(fundraise.fundraise.idAtContract);
      await tx.wait();
    } catch (e) {
      setIsWithdrawn(false);
      console.log(e);
    }
  };

  return fundraise.id && currentUser ? (
    <div className="relative p-6 max-w-[1240px] w-full h-full md:h-auto mx-auto">
      <div className="relative p-6 bg-white rounded-lg shadow">
        <div className="flex gap-9">
          <div className="w-2/3">
            <h2 className="font-bold text-2xl text-gray-900 mb-4">
              {fundraise.title}
            </h2>
            <div className="flex items-center mb-9">
              <div className="flex gap-2">
                {currentUser?.avatarUrl ? (
                  <Img
                    src={currentUser.avatarUrl}
                    alt="avatar"
                    className="h-[25px] w-[25px] rounded-full object-cover"
                  />
                ) : (
                  currentUser?.firstName &&
                  currentUser?.lastName && (
                    <div className="h-[25px] w-[25px] rounded-full bg-gray-500 flex items-center justify-center text-center text-xs font-normal text-white">
                      {currentUser.firstName.split("").shift() +
                        currentUser.lastName.split("").shift()}
                    </div>
                  )
                )}
                <p className="text-gray-600 text-base font-medium">
                  {currentUser.firstName} {currentUser.lastName}
                </p>
              </div>
              <Ellipse />
              <div className="text-sm font-medium text-gray-800 bg-gray-100 px-3 py-0.5 rounded-md select-none">
                {fundraise.categories && fundraise?.categories[0]?.name}
              </div>
              <Ellipse />
              <div className="flex gap-1 items-center text-sm font-medium text-gray-800 bg-gray-100 px-3 py-0.5 rounded-md select-none">
                <BlackBadgeCalendar />
                {fundraise.deadlineDays ? (
                  <span>{fundraise.deadlineDays} days left</span>
                ) : (
                  <span>
                    {formatDistanceToNowStrict(
                      new Date(fromUnixTime(fundraise.deadlineTimestamp)),
                    )}{" "}
                    left
                  </span>
                )}
              </div>
              <Ellipse />
              {fundraise?.status === fundraiseStatuses.PENDING && (
                <span className="text-sm font-medium text-yellow-800 bg-yellow-100 px-3 py-0.5 rounded-md select-none flex items-center">
                  Validation
                </span>
              )}
              {fundraise?.status === fundraiseStatuses.DECLINED && (
                <span className="text-sm font-medium text-red-800 bg-red-100 px-3 py-0.5 rounded-md select-none flex items-center">
                  Decline
                </span>
              )}
              {fundraise?.status === fundraiseStatuses.APPROVED && (
                <span className="text-sm font-medium text-blue-800 bg-blue-100 px-3 py-0.5 rounded-md select-none flex items-center">
                  Approved
                </span>
              )}
            </div>
            <div className="mb-9 flex flex-col gap-4">
              {fundraise?.photos?.length ? (
                fundraise.photos.map((image) => (
                  <img
                    src={image.url}
                    key={uuid()}
                    alt="preview"
                    className="rounded-lg max-w-[784px]"
                  />
                ))
              ) : (
                <div className="rounded-lg max-w-[784px] py-32 flex items-center justify-center shadow">
                  <Logo />
                </div>
              )}
            </div>
            <div className="text-base font-normal text-gray-800 mb-10">
              {!showMore ? (
                <>
                  <p>{`${fundraise.description.substring(0, 190)}...`}</p>
                  <button
                    className="text-primary-100 font-medium"
                    type="button"
                    onClick={() => setShowMore(true)}
                  >
                    Show more
                  </button>
                </>
              ) : (
                <>
                  <p>{fundraise.description}</p>
                  <button
                    className="text-primary-100 font-medium"
                    type="button"
                    onClick={() => setShowMore(false)}
                  >
                    Show less
                  </button>
                </>
              )}
            </div>
            <div className="mb-10">
              <h3 className="mb-4 text-gray-800 font-bold text-2xl">
                Organiser
              </h3>
              <div className="rounded-lg shadow flex items-center gap-2 p-6">
                {currentUser?.avatarUrl ? (
                  <Img
                    src={currentUser.avatarUrl}
                    alt="avatar"
                    className="h-[32px] w-[32px] rounded-full"
                  />
                ) : (
                  currentUser?.firstName &&
                  currentUser?.lastName && (
                    <div className="h-[32px] w-[32px] rounded-full bg-gray-500 flex items-center justify-center text-center text-sm font-normal text-white">
                      {currentUser.firstName.split("").shift() +
                        currentUser.lastName.split("").shift()}
                    </div>
                  )
                )}
                <div className="flex flex-col">
                  <div className="text-base font-medium text-gray-800">
                    {fundraise?.organizationName ? (
                      <p className="text-base font-medium text-gray-800">
                        {fundraise?.organizationName}
                      </p>
                    ) : (
                      <p className="text-base font-medium text-gray-800">
                        {fundraise.fundraiser?.firstName}{" "}
                        {fundraise.fundraiser?.lastName}
                      </p>
                    )}
                  </div>
                  <p className="text-sm font-medium text-gray-500">
                    {fundraise.ownerType === fundraiseTypes.PERSONAL
                      ? "Personal fundraiser"
                      : "Organization fundraiser"}
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-10">
              <h3 className="mb-4 text-gray-800 font-bold text-2xl">
                Fundraiser
              </h3>
              <div className="rounded-lg shadow flex items-center gap-2 p-6">
                <div className="flex flex-col gap-2">
                  <p className="text-lg font-semibold text-gray-800">
                    {fundraise.goalType === "FIXED"
                      ? "Fixed Goal"
                      : "Flexible goal"}
                  </p>
                  <p className="text-base font-medium text-gray-600">
                    {fundraise.goalType === "FIXED"
                      ? "Must fundraise entire amount declared in goal (or more), or else the funds do not get released to then fundraiser and are returned to the donors."
                      : "Any amount fundraised will go to the fundraiser, whether the goal is met or not."}
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-10">
              <h3 className="mb-4 text-gray-800 font-bold text-2xl">
                Timeline
              </h3>
              <div className="rounded-lg shadow flex items-center gap-2 p-6">
                <div className="flex gap-10">
                  <div className="text-base font-medium text-gray-600 flex items-center gap-2.5">
                    <WhiteBadgeCalendar />{" "}
                    {fundraise.publicationStatus === "NOTPUBLISHED" ? (
                      "Not published"
                    ) : (
                      <>
                        Published
                        <span className="text-gray-800">
                          {format(
                            new Date(fundraise?.fundraise?.createdAt),
                            "LLL dd, yyyy",
                          )}
                        </span>
                      </>
                    )}
                  </div>
                  <div className="text-base font-medium text-gray-600 flex items-center gap-2.5">
                    <WhiteBadgeCalendar /> Ends
                    {fundraise.deadlineDays ? (
                      <span className="text-gray-800">
                        {format(
                          addDays(new Date(), fundraise.deadlineDays),
                          "LLL dd, yyyy",
                        )}
                      </span>
                    ) : (
                      <span className="text-gray-800">
                        {format(
                          new Date(fromUnixTime(fundraise.deadlineTimestamp)),
                          "LLL dd, yyyy",
                        )}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-6 justify-start w-1/3 h-fit mt-28 sticky top-[110px] mb-10">
            <div className="p-3 rounded-lg shadow ">
              <div className=" flex flex-col w-full">
                <div className="flex items-center mb-4">
                  <h2 className="font-bold text-2xl text-gray-800 pr-2">
                    {fundraise?.fundraise
                      ? Number(fundraise?.fundraise?.raisedAmount).toFixed(3) ||
                        0
                      : 0}{" "}
                    {fundraise.goalCurrency}
                  </h2>
                  <h5 className="font-normal text-base text-gray-500">
                    raised of {fundraise?.fundraise?.goal || fundraise?.goal}{" "}
                    {fundraise.goalCurrency}
                  </h5>
                </div>
                <div className="w-full bg-gray-100 h-2 mb-5">
                  <div
                    className="bg-primary-100 h-2 rounded-sm"
                    style={{
                      width: `${
                        fundraise.fundraise
                          ? (fundraise.fundraise.raisedAmount * 100) /
                            fundraise.goal
                          : 0
                      }%`,
                    }}
                  />
                </div>
                <div className="flex justify-between gap-4 mb-6">
                  <div className="flex flex-col justify-start bg-gray-100 p-4 w-full rounded-md">
                    <h5 className="font-normal text-base text-gray-500">
                      Donations
                    </h5>
                    <h2 className="font-bold text-2xl text-gray-800">
                      {" "}
                      {limitedDonations?.count && state.approvedMyFundraise
                        ? limitedDonations.count
                        : 0}
                    </h2>
                  </div>
                  <div className="flex flex-col justify-start bg-gray-100 p-4 w-full rounded-md">
                    <h5 className="font-normal text-base text-gray-500">
                      AVG donation
                    </h5>
                    <h2 className="font-bold text-2xl text-gray-800">
                      {limitedDonations?.average && state.approvedMyFundraise
                        ? Number(limitedDonations.average).toFixed(2)
                        : 0}{" "}
                      {fundraise.goalCurrency}
                    </h2>
                  </div>
                </div>
                <div className="flex items-center justify-between gap-[12px]">
                  {(fundraise?.fundraise?.status ===
                    fundraiseGoalStatuses.GOALREACHED ||
                    fundraise?.fundraise?.status ===
                      fundraiseGoalStatuses.DEADLINEPASSED) &&
                  fundraise?.fundraise?.status !==
                    fundraiseGoalStatuses.WITHDRAWN &&
                  !isWithdrawn ? (
                    <button
                      className="py-[12px] px-4 text-white bg-primary-100 rounded-lg font-semibold w-full"
                      type="button"
                      onClick={finishFundraise}
                    >
                      Get donations
                    </button>
                  ) : (
                    <button
                      className="py-[12px] px-4 text-white bg-primary-100 rounded-lg font-semibold w-full"
                      type="button"
                      onClick={() =>
                        dispatch(logout({ onSuccess: () => navigate("/") }))
                      }
                    >
                      Log in as a donor
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="p-6 rounded-lg shadow">
              <div className="flex justify-between">
                <h2 className="font-semibold text-xl text-gray-800">Donors</h2>
                <>
                  {limitedDonations &&
                  limitedDonations?.rows?.length &&
                  state.approvedMyFundraise ? (
                    <button
                      type="button"
                      className="text-base font-medium text-primary-100"
                      onClick={() => {
                        dispatch(
                          getAllDonations({
                            id: fundraise.fundraise?.idAtContract,
                            ignoreFundraiseAttributes: true,
                          }),
                        );
                        setIsOpenModal(true);
                      }}
                    >
                      View all
                    </button>
                  ) : (
                    ""
                  )}
                  {state.approvedMyFundraise && (
                    <DonorsDonationsModal
                      isOpenModal={isOpenModal}
                      setIsOpenModal={setIsOpenModal}
                      allDonations={allDonations}
                      fundraise={fundraise}
                    />
                  )}
                </>
              </div>
              <div>
                {limitedDonations && !isLoading ? (
                  <DonationsList
                    limitedDonations={limitedDonations}
                    fundraise={fundraise}
                    isLoading={isLoading}
                  />
                ) : (
                  state.approvedMyFundraise && (
                    <div className="flex justify-center">
                      <Spinner color="success" size="md" />
                    </div>
                  )
                )}
                {((limitedDonations &&
                  !limitedDonations?.rows?.length &&
                  !isLoading) ||
                  !state.approvedMyFundraise) && (
                  <h5 className="text-base font-normal text-gray-800 text-center mt-6">
                    No donations yet
                  </h5>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="pt-5 bg-white flex justify-between">
          <button
            type="button"
            className="py-2 px-5 text-sm font-medium text-gray-800 focus:outline-none rounded-lg border border-gray-200 hover:bg-gray-100"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          {!isPublished &&
            fundraise?.status === fundraiseStatuses.APPROVED &&
            fundraise.publicationStatus === "NOTPUBLISHED" && (
              <button
                type="submit"
                className={
                  isPublishing
                    ? "py-2 px-5 text-sm font-medium text-white focus:outline-none bg-gray-300 rounded-lg border border-gray-200"
                    : "py-2 px-5 text-sm font-medium text-white focus:outline-none bg-primary-100 rounded-lg border border-gray-200"
                }
                disabled={isPublishing}
                onClick={async () => {
                  setIsPublishing(true);
                  await createFundraise();
                }}
              >
                {isPublishing && <Spinner color="success" size="sm" />}
                <span className={isPublishing ? "pl-3" : undefined}>
                  Publish
                </span>
              </button>
            )}
        </div>
        <ToastContainer autoClose={3000} hideProgressBar pauseOnHover={false} />
      </div>
    </div>
  ) : (
    <div className="flex justify-center mt-[10%] mx-auto">
      <Spinner
        aria-label="Extra large spinner example"
        size="xl"
        color="success"
      />
    </div>
  );
};

export default MyFundraise;
