/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import { Avatar } from "flowbite-react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";
import {
  addDays,
  format,
  formatDistanceToNowStrict,
  fromUnixTime,
} from "date-fns";
import { toast, ToastContainer } from "react-toastify";
import { ethers } from "ethers";
import { Spinner } from "flowbite-react";
import { getFundraiseById } from "../../store/reducers/fundraiseSlice";
import fundraiseTypes from "../../constans/fundraiseTypes";
import WhiteBadgeCalendar from "../../svg/badgeCalendar/WhiteBadgeCalendar";
import Ellipse from "../../svg/ellipse/Ellipse";
import BlackBadgeCalendar from "../../svg/badgeCalendar/BlackBadgeCalendar";
import userRoles from "../../constans/userRoles";
import { getCurrentUser, logout } from "../../store/reducers/userSlice";
import { abi } from "../../constans/abi";
import { tokenAbi } from "../../constans/tokenAbi";
import smartContractCurrency from "../../constans/smartContractCurrency";
import CurrencyInput from "react-currency-input-field";
import {
  getAllDonations,
  getLimitedDonationByFundraise,
} from "../../store/reducers/donationsSlice";
import DonorsDonationsModal from "../modals/DonorsDonationsModal";
import DonationsList from "../donationsList/DonationsList";
import detectEthereumProvider from "@metamask/detect-provider";
import ApiService from "../../store/middleware/api";
import fundraiseGoalStatuses from "../../constans/fundraiseGoalStatuses";
import Logo from "../../svg/logo/Logo";

const ExploreFundraise = () => {
  const [showMore, setShowMore] = useState(false);
  const [donateValue, setDonateValue] = useState("");
  const [isDonating, setIsDonating] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadedFundraise, setIsLoadedFundraise] = useState(false);
  const [isDonated, setIsDonated] = useState(false);
  const [isAuthor, setIsAuthor] = useState(false);
  const [userWalletAddress, setUserWalletAddress] = useState("");
  const [isWithdrawn, setIsWithdrawn] = useState(false);

  console.log(donateValue);

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fundraise = useSelector((state) => state.fundraise.fundraise);
  const limitedDonations = useSelector(
    (state) => state.donations.limitedDonationsByFundraise,
  );
  const allDonations = useSelector(
    (state) => state.donations.allDonationsByFundraise,
  );
  const currentUser = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    detectEthereumProvider()
      .then(() => setIsMetaMask(true))
      .catch(() => setIsMetaMask(false));
  }, []);

  useEffect(() => {
    dispatch(
      getFundraiseById({ id, onSuccess: () => setIsLoadedFundraise(true) }),
    );
    dispatch(getCurrentUser());
  }, [id, dispatch]);

  useEffect(() => {
    isLoadedFundraise &&
      dispatch(
        getLimitedDonationByFundraise({
          id: fundraise.fundraise?.idAtContract,
          limit: 3,
          onSuccess: () => {
            setIsLoading(false);
            dispatch(
              getAllDonations({
                id: fundraise.fundraise?.idAtContract,
                ignoreFundraiseAttributes: true,
              }),
            );
          },
        }),
      );
  }, [dispatch, fundraise, id, isLoadedFundraise]);

  useMemo(() => {
    setUserWalletAddress(currentUser?.addresses?.[0]?.address);
    setIsDonated(
      fundraise?.donorsAddresses?.[
        `${currentUser?.addresses?.[0]?.address}`
      ] === "DONATED",
    );

    fundraise?.fundraiser?.uuid === currentUser?.uuid
      ? setIsAuthor(true)
      : setIsAuthor(false);
  }, [allDonations, currentUser, fundraise, userWalletAddress]);

  const createDonate = async () => {
    !isDonating && setIsDonating(true);
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();

    const contract = new ethers.Contract(
      "0xA96aF6000E71dc7e4F4De9e2D5F97450E8585f9a",
      abi,
      signer,
    );

    const feeData = await provider.getFeeData();

    // Start transaction: populate the transaction
    try {
      if (fundraise.fundraise.currency === smartContractCurrency.ETH) {
        const tx = await contract.donateNativeCurrency(
          fundraise.fundraise.idAtContract,
          {
            value: ethers.utils.parseEther(donateValue),
            gasPrice: feeData.gasPrice,
          },
        );
        await tx.wait();
        setIsDonating(false);
        toast.success(
          <button type="button">
            <a
              href={`https://goerli.etherscan.io/tx/${tx.hash}`}
              target="_blank"
              rel="noreferrer"
            >
              Donate made successfully! <br />{" "}
              <span className="hover:underline text-primary-100">
                Transaction Details
              </span>
            </a>
          </button>,
        );
      }
      if (fundraise.fundraise.currency !== smartContractCurrency.ETH) {
        const tx = await contract.donateToken(
          fundraise.fundraise.idAtContract,
          ethers.utils.parseEther(donateValue),
        );

        await tx.wait();

        setIsDonating(false);
        toast.success(
          <button type="button">
            <a
              href={`https://mumbai.polygonscan.com/tx/${tx.hash}`}
              target="_blank"
              rel="noreferrer"
            >
              Donate made successfully! <br />{" "}
              <span className="hover:underline text-primary-100">
                Transaction Details
              </span>
            </a>
          </button>,
        );
      }
    } catch ({ error }) {
      setIsDonating(false);
      error?.code === Number(-32603)
        ? toast.error("Invalid Metamask network")
        : toast.error("Failed to donate!");
    }
  };

  const createNotETHDonate = async () => {
    setIsDonating(true);
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();

    const contract = new ethers.Contract(
      "0x0Ad4A8293f1df852074bED145a5a435810636B82",
      tokenAbi,
      signer,
    );

    // Start transaction: populate the transaction
    try {
      const tx = await contract.approve(
        "0xA96aF6000E71dc7e4F4De9e2D5F97450E8585f9a",
        ethers.utils.parseEther(donateValue),
      );
      await tx.wait();
      await createDonate();
    } catch (e) {
      toast.error("Failed to donate!");
      setIsDonating(false);
    }
  };

  const [isMetaMask, setIsMetaMask] = useState(true);
  let provider;
  let signer;
  let accounts;

  const validateWallet = () => {
    signer
      .signMessage(`Sign message. Nonce: ${currentUser.nonce}`)
      .then(async (msg) => {
        await ApiService.apiCall({
          endpoint: "/auth/verifyaddress/donor",
          method: "POST",
          query: { address: accounts[0], signedMessage: msg },
          withCredentials: true,
        });
      })
      .then(() => location.reload())
      .catch((e) => toast.error(e.response?.data.message));
  };

  const connectMetamask = async () => {
    await detectEthereumProvider().then(async () => {
      provider = new ethers.providers.Web3Provider(window.ethereum);
      signer = provider.getSigner();
      await provider.send("eth_requestAccounts", []).then((acc) => {
        accounts = acc;
      });
    });
    await validateWallet();
  };

  const finishFundraise = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();

    const contract = new ethers.Contract(
      "0xA96aF6000E71dc7e4F4De9e2D5F97450E8585f9a",
      abi,
      signer,
    );

    try {
      if (currentUser.role === userRoles.FUNDRAISER) {
        setIsWithdrawn(true);
        const tx = await contract.withdrawFunds(
          fundraise.fundraise.idAtContract,
        );
        await tx.wait();
      }
      if (currentUser.role === userRoles.DONOR) {
        setIsWithdrawn(true);
        const tx = await contract.withdrawDonation(
          fundraise.fundraise.idAtContract,
        );
        await tx.wait();
      }
    } catch (e) {
      setIsWithdrawn(false);
    }
  };

  return fundraise.id && currentUser ? (
    <div className="relative p-6 max-w-[1240px] w-full h-full md:h-auto mx-auto">
      <div className="relative p-6 bg-white rounded-lg shadow">
        <div className="flex gap-9">
          <div className="w-2/3">
            <h2 className="font-bold text-2xl text-gray-900 mb-4">
              {fundraise.title}
            </h2>
            <div className="flex items-center mb-9">
              <div className="flex gap-2 ">
                {fundraise.fundraiser?.avatarUrl ? (
                  <img
                    src={fundraise.fundraiser.avatarUrl}
                    alt="avatar"
                    className="h-[25px] w-[25px] rounded-full object-cover"
                  />
                ) : fundraise.fundraiser?.firstName &&
                  fundraise.fundraiser?.lastName ? (
                  <div className="h-[25px] w-[25px] rounded-full bg-gray-500 flex items-center justify-center text-center text-xs font-normal text-white">
                    {fundraise.fundraiser.firstName.split("").shift() +
                      fundraise.fundraiser.lastName.split("").shift()}
                  </div>
                ) : (
                  <Avatar rounded size="sm" />
                )}
                <p className="text-gray-600 text-base font-medium py-0.5">
                  {fundraise?.fundraiser?.firstName ||
                  fundraise?.fundraiser?.lastName
                    ? fundraise?.fundraiser?.firstName +
                      " " +
                      fundraise?.fundraiser?.lastName
                    : "Anonymous"}
                </p>
              </div>
              <Ellipse />
              <div className="text-sm font-medium text-gray-800 bg-gray-100 px-3 py-0.5 rounded-md select-none">
                {(fundraise.categories && fundraise?.categories[0]?.name) ||
                  "No category"}
              </div>
              <Ellipse />
              <div className="flex gap-1 items-center text-sm font-medium text-gray-800 bg-gray-100 px-3 py-0.5 rounded-md select-none">
                <BlackBadgeCalendar />
                {fundraise.deadlineDays ? (
                  <span>{fundraise.deadlineDays} days left</span>
                ) : (
                  <span>
                    {formatDistanceToNowStrict(
                      new Date(fromUnixTime(fundraise.deadlineTimestamp)),
                    )}{" "}
                    left
                  </span>
                )}
              </div>
            </div>
            <div className="mb-9 flex flex-col gap-4">
              {fundraise?.photos?.length ? (
                fundraise.photos.map((image) => (
                  <img
                    src={image.url}
                    key={uuid()}
                    alt="preview"
                    className="rounded-lg max-w-[784px]"
                  />
                ))
              ) : (
                <div className="rounded-lg max-w-[784px] py-32 flex items-center justify-center shadow">
                  <Logo />
                </div>
              )}
            </div>
            <div className="text-base font-normal text-gray-800 mb-10">
              {!showMore ? (
                <>
                  <p>{`${fundraise.description.substring(0, 190)}...`}</p>
                  <button
                    className="text-primary-100 font-medium"
                    type="button"
                    onClick={() => setShowMore(true)}
                  >
                    Show more
                  </button>
                </>
              ) : (
                <>
                  <p>{fundraise.description}</p>
                  <button
                    className="text-primary-100 font-medium"
                    type="button"
                    onClick={() => setShowMore(false)}
                  >
                    Show less
                  </button>
                </>
              )}
            </div>
            <div className="mb-10">
              <h3 className="mb-4 text-gray-800 font-bold text-2xl">
                Organiser
              </h3>
              <div className="rounded-lg shadow flex items-center gap-2 p-6">
                {fundraise.fundraiser?.avatarUrl ? (
                  <img
                    src={fundraise.fundraiser.avatarUrl}
                    alt="avatar"
                    className="h-[32px] w-[32px] rounded-full object-cover"
                  />
                ) : (
                  fundraise.fundraiser?.firstName &&
                  fundraise.fundraiser?.lastName && (
                    <div className="h-[32px] w-[32px] rounded-full bg-gray-500 flex items-center justify-center text-center text-sm font-normal text-white">
                      {fundraise.fundraiser.firstName.split("").shift() +
                        fundraise.fundraiser.lastName.split("").shift()}
                    </div>
                  )
                )}
                <div className="flex flex-col">
                  {fundraise?.organizationName ? (
                    <p className="text-base font-medium text-gray-800">
                      {fundraise.organizationName}
                    </p>
                  ) : (
                    <p className="text-base font-medium text-gray-800">
                      {fundraise.fundraiser.firstName}{" "}
                      {fundraise.fundraiser.lastName}
                    </p>
                  )}
                  <p className="text-sm font-medium text-gray-500">
                    {fundraise.ownerType === fundraiseTypes.PERSONAL
                      ? "Personal fundraiser"
                      : "Organization fundraiser"}
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-10">
              <h3 className="mb-4 text-gray-800 font-bold text-2xl">
                Fundraiser
              </h3>
              <div className="rounded-lg shadow flex items-center gap-2 p-6">
                <div className="flex flex-col gap-2">
                  <p className="text-lg font-semibold text-gray-800">
                    {fundraise.goalType === "FIXED"
                      ? "Fixed Goal"
                      : "Flexible goal"}
                  </p>
                  <p className="text-base font-medium text-gray-600">
                    {fundraise.goalType === "FIXED"
                      ? "Must fundraise entire amount declared in goal (or more), or else the funds do not get released to then fundraiser and are returned to the donors."
                      : "Any amount fundraised will go to the fundraiser, whether the goal is met or not."}
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-10">
              <h3 className="mb-4 text-gray-800 font-bold text-2xl">
                Timeline
              </h3>
              <div className="rounded-lg shadow flex items-center gap-2 p-6">
                <div className="flex gap-10">
                  <div className="text-base font-medium text-gray-600 flex items-center gap-2.5">
                    <WhiteBadgeCalendar /> Published
                    <span className="text-gray-800">
                      {fundraise?.fundraise?.createdAt &&
                        format(
                          new Date(fundraise?.fundraise?.createdAt),
                          "LLL dd, yyyy",
                        )}
                    </span>
                  </div>
                  <div className="text-base font-medium text-gray-600 flex items-center gap-2.5">
                    <WhiteBadgeCalendar /> Ends
                    {fundraise.deadlineDays ? (
                      <span className="text-gray-800">
                        {format(
                          addDays(new Date(), fundraise.deadlineDays),
                          "LLL dd, yyyy",
                        )}
                      </span>
                    ) : (
                      <span className="text-gray-800">
                        {format(
                          new Date(fromUnixTime(fundraise.deadlineTimestamp)),
                          "LLL dd, yyyy",
                        )}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-6 justify-start w-1/3 h-fit mt-28 sticky top-[110px] mb-10">
            <div className="p-3 rounded-lg shadow ">
              <div className=" flex flex-col w-full">
                <div className="flex items-center mb-4">
                  <h2 className="font-bold text-2xl text-gray-800 pr-2">
                    {fundraise.fundraise
                      ? Number(fundraise.fundraise.raisedAmount).toFixed(3)
                      : 0}{" "}
                    {fundraise.fundraise?.currency}
                  </h2>
                  <h5 className="font-normal text-base text-gray-500">
                    raised of {fundraise?.fundraise?.goal}{" "}
                    {fundraise?.fundraise?.currency}
                  </h5>
                </div>
                <div className="w-full bg-gray-100 h-2 mb-5">
                  <div
                    className="bg-primary-100 h-2 rounded-sm"
                    style={{
                      width: `${
                        fundraise.fundraise
                          ? (fundraise.fundraise.raisedAmount * 100) /
                            fundraise.fundraise.goal
                          : 0
                      }%`,
                    }}
                  />
                </div>
                <div className="flex justify-between gap-4 mb-6">
                  <div className="flex flex-col justify-start bg-gray-100 p-4 w-full rounded-md">
                    <h5 className="font-normal text-base text-gray-500">
                      Donations
                    </h5>
                    <h2 className="font-bold text-2xl text-gray-800">
                      {limitedDonations?.count ? limitedDonations.count : 0}
                    </h2>
                  </div>
                  <div className="flex flex-col justify-start bg-gray-100 p-4 w-full rounded-md">
                    <h5 className="font-normal text-base text-gray-500">
                      AVG donation
                    </h5>
                    <h2 className="font-bold text-2xl text-gray-800">
                      {limitedDonations?.average
                        ? Number(limitedDonations?.average).toFixed(2)
                        : 0}{" "}
                      {fundraise.fundraise?.currency}
                    </h2>
                  </div>
                </div>
                <div className="flex items-center justify-between gap-[12px]">
                  {currentUser.role === userRoles.FUNDRAISER &&
                    fundraise.fundraise?.status ===
                      fundraiseGoalStatuses.ACTIVE && (
                      <>
                        <button
                          className="py-[12px] px-4 text-white bg-primary-100 rounded-lg font-semibold w-full"
                          type="button"
                          onClick={() =>
                            dispatch(logout({ onSuccess: () => navigate("/") }))
                          }
                        >
                          Log in as a donor
                        </button>
                      </>
                    )}
                  {fundraise.fundraise?.status ===
                    fundraiseGoalStatuses.ACTIVE &&
                    currentUser.role === userRoles.DONOR && (
                      <>
                        <CurrencyInput
                          className="block py-[12px] px-4 w-full text-base text-gray-500 bg-gray-50 rounded-lg border border-gray-300 focus:ring-gray-200 focus:border-gray-200"
                          value={donateValue}
                          onValueChange={setDonateValue}
                          maxLength={6}
                          placeholder={`Donation amount (${fundraise.fundraise.currency})`}
                          step={1}
                          disableGroupSeparators={true}
                          allowNegativeValue={false}
                          decimalsLimit={4}
                          decimalSeparator="."
                        />
                        <button
                          className={
                            !donateValue ||
                            donateValue === "0" ||
                            donateValue === "0." ||
                            isDonating ||
                            !isMetaMask ||
                            !currentUser?.addresses?.length
                              ? "py-[12px] px-4 text-white bg-gray-300 rounded-lg font-semibold flex gap-2"
                              : "py-[12px] px-4 text-white bg-primary-100 rounded-lg font-semibold"
                          }
                          type="button"
                          disabled={
                            !donateValue ||
                            donateValue === "0" ||
                            donateValue === "0." ||
                            isDonating ||
                            !isMetaMask ||
                            !currentUser?.addresses?.length
                          }
                          onClick={() => {
                            setDonateValue("");
                            fundraise.fundraise.currency ===
                            smartContractCurrency.ETH
                              ? createDonate()
                              : createNotETHDonate();
                          }}
                        >
                          {isDonating && <Spinner color="success" size="sm" />}
                          <span>Donate</span>
                        </button>
                      </>
                    )}
                  {(fundraise.fundraise?.status ===
                    fundraiseGoalStatuses.GOALREACHED ||
                    fundraise.fundraise?.status ===
                      fundraiseGoalStatuses.DEADLINEPASSED) &&
                    fundraise.fundraise?.status !==
                      fundraiseGoalStatuses.WITHDRAWN &&
                    currentUser.role === userRoles.FUNDRAISER &&
                    isAuthor &&
                    !isWithdrawn && (
                      <button
                        className="py-[12px] px-4 text-white bg-primary-100 rounded-lg font-semibold w-full"
                        type="button"
                        onClick={finishFundraise}
                      >
                        Get donations
                      </button>
                    )}
                  {fundraise.fundraise?.status ===
                    fundraiseGoalStatuses.GOALNOTREACHED &&
                    currentUser.role === userRoles.DONOR &&
                    isDonated &&
                    !isWithdrawn && (
                      <button
                        className="py-[12px] px-4 text-white bg-primary-100 rounded-lg font-semibold w-full"
                        type="button"
                        onClick={finishFundraise}
                      >
                        Return my donations
                      </button>
                    )}
                </div>
                <>
                  {!isMetaMask && currentUser.role === userRoles.DONOR && (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://metamask.io/"
                      className="mt-4 bg-primary-100 text-white text-base rounded-md py-2 text-center"
                    >
                      Download MetaMask
                    </a>
                  )}
                  {isMetaMask &&
                    !currentUser?.addresses?.length &&
                    currentUser.role === userRoles.DONOR && (
                      <button
                        type="button"
                        className="mt-4 bg-primary-100 text-white text-base rounded-md py-2 text-center"
                        onClick={() => connectMetamask()}
                      >
                        Connect your MetaMask
                      </button>
                    )}
                </>
              </div>
            </div>
            <div className="p-6 rounded-lg shadow">
              <div className="flex justify-between">
                <h2 className="font-semibold text-xl text-gray-800">Donors</h2>
                <>
                  {limitedDonations && limitedDonations?.rows?.length ? (
                    <button
                      type="button"
                      className="text-base font-medium text-primary-100"
                      onClick={() => {
                        setIsOpenModal(true);
                      }}
                    >
                      View all
                    </button>
                  ) : (
                    ""
                  )}

                  <DonorsDonationsModal
                    isOpenModal={isOpenModal}
                    setIsOpenModal={setIsOpenModal}
                    allDonations={allDonations}
                    fundraise={fundraise}
                  />
                </>
              </div>
              <div>
                {limitedDonations && !isLoading ? (
                  <DonationsList
                    fundraise={fundraise}
                    isLoading={isLoading}
                    limitedDonations={limitedDonations}
                  />
                ) : (
                  <div className="flex justify-center">
                    <Spinner color="success" size="md" />
                  </div>
                )}
                {limitedDonations &&
                  !limitedDonations?.rows?.length &&
                  !isLoading && (
                    <h5 className="text-base font-normal text-gray-800 text-center mt-6">
                      No donations yet
                    </h5>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="pt-5 bg-white flex justify-between">
          <button
            type="button"
            className="py-2 px-5 text-sm font-medium text-gray-800 focus:outline-none rounded-lg border border-gray-200 hover:bg-gray-100"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
        </div>
        <ToastContainer autoClose={3000} hideProgressBar pauseOnHover />
      </div>
    </div>
  ) : (
    <div className="flex justify-center mt-[10%] mx-auto">
      <Spinner
        aria-label="Extra large spinner example"
        size="xl"
        color="success"
      />
    </div>
  );
};

export default ExploreFundraise;
