import * as Yup from "yup";

const EmailValidation = () =>
  Yup.object().shape({
    ssn_1: Yup.number().required(),
    ssn_2: Yup.number().required(),
    ssn_3: Yup.number().required(),
    ssn_4: Yup.number().required(),
    ssn_5: Yup.number().required(),
    ssn_6: Yup.number().required(),
  });

export default EmailValidation;
