import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Spinner } from "flowbite-react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getMyApprovedFundraisers,
  getMyWaitingForApprovalFundraisers,
  setSortByForMyPending,
} from "../../store/reducers/fundraiseSlice";
import CreateFundraisePlus from "../../svg/createFundraisePlus/CreateFundraisePlus";
import MyFundraiseCard from "../../components/myFundraisers/MyFundraiseCard";
import NoFundraisersCard from "../../components/noFundraisers/NoFundraisersCard";

const MyWaitingForApprovalFundraisersPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [isCardsLoaded, setIsCardsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { search } = useSelector((state) => state.fundraise.filtersMy);
  const myDraftFundraisers = useSelector(
    (state) => state.fundraise.myFundraisers,
  );

  const currentUser = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    dispatch(
      getMyApprovedFundraisers({
        search,
        sortByApprovedFundraisers: { publicationDate: "DESC" },
        runningStatus: [],
        onSuccess: () => {
          setIsCardsLoaded(true);
          setIsLoading(false);
        },
        onFailure: () => {
          setIsLoading(false);
        },
        metadataStatus: "PENDING",
      }),
    );
  }, [dispatch, search]);

  return (
    <>
      <div className="justify-between items-center flex mb-6">
        <div className="flex gap-3 items-center">
          <h2 className="font-bold text-2xl text-gray-800">Your fundraisers</h2>
          <h5 className="px-3 py-1 font-bold text-xl text-gray-900 bg-gray-300 rounded-lg">
            {myDraftFundraisers.length}
          </h5>
        </div>
        <div className="flex items-center gap-4">
          <Dropdown label="Sort by" inline>
            <Dropdown.Item
              onClick={() => {
                dispatch(
                  setSortByForMyPending({ sortBy: { deadline: "ASC" } }),
                );
                dispatch(
                  getMyWaitingForApprovalFundraisers({
                    search,
                    sortByPendingFundraisers: { deadline: "ASC" },
                    onSuccess: () => {
                      setIsCardsLoaded(true);
                      setIsLoading(false);
                    },
                    onFailure: () => {
                      setIsLoading(false);
                    },
                  }),
                );
              }}
            >
              Deadline
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                dispatch(
                  setSortByForMyPending({
                    sortBy: { publicationDate: "DESC" },
                  }),
                );
                dispatch(
                  getMyWaitingForApprovalFundraisers({
                    search,
                    sortByPendingFundraisers: { publicationDate: "DESC" },
                    onSuccess: () => {
                      setIsCardsLoaded(true);
                      setIsLoading(false);
                    },
                    onFailure: () => {
                      setIsLoading(false);
                    },
                  }),
                );
              }}
            >
              Published date
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                dispatch(
                  setSortByForMyPending({ sortBy: { targetAmount: "ASC" } }),
                );
                dispatch(
                  getMyWaitingForApprovalFundraisers({
                    search,
                    sortByPendingFundraisers: { targetAmount: "ASC" },
                    onSuccess: () => {
                      setIsCardsLoaded(true);
                      setIsLoading(false);
                    },
                    onFailure: () => {
                      setIsLoading(false);
                    },
                  }),
                );
              }}
            >
              Fundraise target amount
            </Dropdown.Item>
          </Dropdown>

          <div className="inline-flex rounded-md shadow-sm" role="group">
            <button
              type="button"
              className={
                location.pathname.includes("my-fundraisers/approved")
                  ? "py-[5px] px-4 text-base text-white font-medium bg-primary-100 rounded-l-md"
                  : "py-[5px] px-4 text-base font-medium text-gray-900 bg-white rounded-l-md hover:bg-primary-100 hover:text-white"
              }
              onClick={() => {
                navigate("/my-fundraisers/approved");
              }}
            >
              Approved
            </button>
            <button
              type="button"
              className={
                location.pathname.includes(
                  "my-fundraisers/waiting-for-approval",
                )
                  ? "py-[5px] px-4 text-base text-white font-medium bg-primary-100 rounded-r-md"
                  : "py-[5px] px-4 text-base font-medium text-gray-900 bg-white rounded-r-md hover:bg-primary-100 hover:text-white"
              }
              onClick={() => {
                navigate("/my-fundraisers/waiting-for-approval");
              }}
            >
              Waiting for approval
            </button>
          </div>
          <button
            type="button"
            className="text-base text-white font-medium flex gap-[10px] bg-primary-100 py-[5px] px-4 items-center rounded-md"
            onClick={() => navigate("/my-fundraisers/create-goal")}
          >
            <CreateFundraisePlus />
            Create fundraise
          </button>
        </div>
      </div>
      <div className="flex flex-wrap gap-5">
        {isCardsLoaded &&
          !isLoading &&
          myDraftFundraisers.map((fundraise) => {
            return (
              <MyFundraiseCard
                key={fundraise.id}
                fundraise={fundraise}
                currentUser={currentUser}
                approvedMyFundraise={false}
              />
            );
          })}

        {!isCardsLoaded && isLoading && (
          <div className="flex justify-center mt-[10%] mx-auto">
            <Spinner
              aria-label="Extra large spinner example"
              size="xl"
              color="success"
            />
          </div>
        )}
        {!isLoading && !myDraftFundraisers.length && <NoFundraisersCard />}
      </div>
    </>
  );
};

export default MyWaitingForApprovalFundraisersPage;
