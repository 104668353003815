import * as Yup from "yup";

const createProfileValidation = () =>
  Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    country: Yup.string().required(),
  });

export default createProfileValidation;
