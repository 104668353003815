import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiService from "../middleware/api";

const initialState = {
  fundraise: {
    ownerType: null,
    organizationName: null,
    organizationTaxId: null,
    organizationCountry: null,
    title: null,
    categoriesIds: [],
    description: null,
    images: null,
    goal: null,
    goalCurrency: null,
    goalType: null,
    deadlineType: null,
    deadlineDays: null,
    deadlineTimestamp: null,
  },
  myFundraisers: [],
  allFundraisers: [],
  categories: [],
  images: [],
  filtersByAll: {
    country: "",
    categoriesIds: [],
    closeToGoal: false,
    expiresInNext24Hours: false,
    ownerType: "",
    search: "",
    sortBy: "",
  },
  filtersMy: {
    search: "",
    sortByApprovedFundraisers: "",
    sortByPendingFundraisers: "",
  },
};

export const getAllCategories = createAsyncThunk(
  "fundraise/getAllCategories",
  async () => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/fundraise/categories`,
        method: "GET",
      });
      return data;
    } catch (error) {
      return error;
    }
  },
);

export const getMyApprovedFundraisers = createAsyncThunk(
  "fundraise/getMyApprovedFundraisers",
  async ({
    search = "",
    sortByApprovedFundraisers,
    runningStatus,
    onSuccess,
    onFailure,
    metadataStatus,
  }) => {
    const sortByApprovedFundraisersQuery = sortByApprovedFundraisers
      ? `&${Object.keys(sortByApprovedFundraisers)[0]}=${
          Object.values(sortByApprovedFundraisers)[0]
        }`
      : "";
    const searchQuery = search ? `${search}` : "";
    const runningStatusQuery = runningStatus.length
      ? `&runningStatus=${runningStatus.join("&runningStatus=")}`
      : [];

    const metadataStatusQuery = metadataStatus
      ? `&metadataStatus=${metadataStatus}`
      : "";

    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/fundraise/my?title=${searchQuery}${sortByApprovedFundraisersQuery}${runningStatusQuery}${metadataStatusQuery}`,
        method: "GET",
      });
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      if (onFailure) onFailure();
      return error;
    }
  },
);

export const getMyWaitingForApprovalFundraisers = createAsyncThunk(
  "fundraise/getMyWaitingForApprovalFundraisers",
  async ({ search, sortByPendingFundraisers = "", onSuccess, onFailure }) => {
    const sortByPendingFundraisersQuery = sortByPendingFundraisers
      ? `&${Object.keys(sortByPendingFundraisers)[0]}=${
          Object.values(sortByPendingFundraisers)[0]
        }`
      : "";
    const searchQuery = search ? `&title=${search}` : "";
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/fundraise/metadata/my?publicationStatus=NOTPUBLISHED${sortByPendingFundraisersQuery}${searchQuery}`,
        method: "GET",
      });
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      if (onFailure) onFailure();
      return error;
    }
  },
);

export const getAllFundraisers = createAsyncThunk(
  "fundraise/getAllFundraisers",
  async ({
    country,
    categoriesIds,
    closeToGoal,
    expiresInNext24Hours,
    ownerType,
    sortBy,
    search = "",
    onSuccess,
    onFailure,
  }) => {
    const closeToGoalQuery = closeToGoal ? `&closeToGoal=${closeToGoal}` : "";
    const countryQuery = country ? `&country=${country}` : "";
    const categoriesIdsQuery = categoriesIds.length
      ? categoriesIds.map((el) => `&categoriesIds=${el.id}`)
      : "";
    const expiresInNext24HoursQuery = expiresInNext24Hours
      ? `&expiresInNext24Hours=${expiresInNext24Hours}`
      : "";
    const ownerTypeQuery = ownerType ? `&ownerType=${ownerType}` : "";
    const sortByQuery = sortBy
      ? `&${Object.keys(sortBy)[0]}=${Object.values(sortBy)[0]}`
      : "";
    const searchQuery = search ? `${search}` : "";
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/fundraise?title=${searchQuery}${closeToGoalQuery}${countryQuery}${categoriesIdsQuery}${expiresInNext24HoursQuery}${ownerTypeQuery}${sortByQuery}`,
        method: "GET",
      });
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      if (onFailure) onFailure();
      return error;
    }
  },
);

export const uploadFundraiseImages = createAsyncThunk(
  "fundraise/uploadFundraiseImages",
  async (
    { fundraiseId, images, onSuccess, onFailure },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/fundraise/metadata/${fundraiseId}/images`,
        method: "POST",
        query: images,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      if (onFailure) onFailure();
      return rejectWithValue(error);
    }
  },
);

export const createFundraise = createAsyncThunk(
  "fundraise/createFundraise",
  async ({ fundraiseData, onSuccess, onFailure }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/fundraise/metadata`,
        method: "POST",
        query: fundraiseData,
      });
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      if (onFailure) onFailure();
      return rejectWithValue(error);
    }
  },
);

export const getFundraiseById = createAsyncThunk(
  "fundraise/getFundraiseById",
  async ({ id, onSuccess, onFailure }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/fundraise/${Number(id)}`,
        method: "GET",
      });
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      if (onFailure) onFailure();
      return error;
    }
  },
);

const fundraiseSlice = createSlice({
  name: "fundraise",
  initialState,
  reducers: {
    /* eslint-disable */
    setFundraiseType(state, { payload }) {
      state.fundraise.ownerType = payload;
    },
    setCompanyInfo(state, { payload }) {
      state.fundraise.organizationName = payload.organizationName;
      state.fundraise.organizationTaxId = payload.organizationTaxId;
      state.fundraise.organizationCountry = payload.organizationCountry;
    },
    setGeneralFundraiseInfo(state, { payload }) {
      state.fundraise.title = payload.title;
      state.fundraise.categoriesIds = payload.categoriesIds;
      state.fundraise.description = payload.description;
      state.fundraise.images = payload.images;
    },
    setFundingInfo(state, { payload }) {
      state.fundraise.goal = payload.goal;
      state.fundraise.goalCurrency = payload.goalCurrency;
      state.fundraise.goalType = payload.goalType;
      state.fundraise.deadlineType = payload.deadlineType;
      state.fundraise.deadlineDays = payload.deadlineDays;
      state.fundraise.deadlineTimestamp = payload.deadlineTimestamp;
    },
    setFundraiseImages(state, { payload }) {
      state.images = payload;
    },
    setSearchByTitleValue(state, { payload }) {
      state.filtersByAll.search = payload;
      state.filtersMy.search = payload;
    },
    setFiltersByAll(state, { payload }) {
      state.filtersByAll.country = payload.country;
      state.filtersByAll.categoriesIds = payload.categoriesIds;
      state.filtersByAll.closeToGoal = payload.closeToGoal;
      state.filtersByAll.expiresInNext24Hours = payload.expiresInNext24Hours;
      state.filtersByAll.ownerType = payload.ownerType;
    },
    clearFilters(state) {
      state.filtersByAll.country = "";
      state.filtersByAll.categoriesIds = [];
      state.filtersByAll.closeToGoal = false;
      state.filtersByAll.expiresInNext24Hours = false;
      state.filtersByAll.ownerType = "";
    },
    setSortByForExplore(state, { payload }) {
      state.filtersByAll.sortBy = payload.sortBy;
    },
    setSortByForMyApproved(state, { payload }) {
      state.filtersMy.sortByApprovedFundraisers = payload.sortBy;
    },
    setSortByForMyPending(state, { payload }) {
      state.filtersMy.sortByPendingFundraisers = payload.sortBy;
    },
  },
  extraReducers: {
    [createFundraise.fulfilled]: (state, { payload }) => {
      state.fundraise = { ...payload };
    },
    [getFundraiseById.fulfilled]: (state, { payload }) => {
      state.fundraise = { ...payload };
    },
    [getAllCategories.fulfilled]: (state, { payload }) => {
      state.categories = [...payload];
    },
    [uploadFundraiseImages.fulfilled]: (state, { payload }) => {
      state.images = [...payload];
    },
    [getMyApprovedFundraisers.fulfilled]: (state, { payload }) => {
      state.myFundraisers = [...payload];
    },
    [getMyWaitingForApprovalFundraisers.fulfilled]: (state, { payload }) => {
      state.myFundraisers = [...payload];
    },
    [getAllFundraisers.fulfilled]: (state, { payload }) => {
      state.allFundraisers = [...payload];
    },
  },
});

export const {
  setFundraiseType,
  setCompanyInfo,
  setGeneralFundraiseInfo,
  setFundingInfo,
  setFundraiseImages,
  setSearchByTitleValue,
  setFiltersByAll,
  setSortByForExplore,
  setSortByForMyApproved,
  setSortByForMyPending,
  clearFilters,
} = fundraiseSlice.actions;

export default fundraiseSlice.reducer;
