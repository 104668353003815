import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Start from "../../components/createGoal/Start";
import CreateGoalBar from "../../components/createGoal/CreateGoalBar";
import GeneralInfo from "../../components/createGoal/GeneralInfo";
import FundingInfo from "../../components/createGoal/FundingInfo";
import createFundraiseSteps from "../../constans/createFundraiseSteps";
import Company from "../../components/createGoal/Company";
import Preview from "../../components/createGoal/Preview";
import Finish from "../../components/createGoal/Finish";
import { getAllCategories } from "../../store/reducers/fundraiseSlice";

const CreateGoalPage = () => {
  const [createFundraiseStep, setCreateFundraiseStep] = useState(
    createFundraiseSteps.START,
  );
  const [ownerType, setOwnerType] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  const fundraise = useSelector((state) => state.fundraise);

  return (
    <div className="flex flex-col items-center justify-center block">
      {createFundraiseStep !== createFundraiseSteps.FINISH && (
        <CreateGoalBar
          createFundraiseStep={createFundraiseStep}
          ownerType={ownerType}
        />
      )}
      {createFundraiseStep === createFundraiseSteps.START && (
        <Start
          setCreateFundraiseStep={setCreateFundraiseStep}
          setOwnerType={setOwnerType}
          ownerType={ownerType}
          fundraise={fundraise}
        />
      )}
      {createFundraiseStep === createFundraiseSteps.COMPANY && (
        <Company setCreateFundraiseStep={setCreateFundraiseStep} />
      )}
      {createFundraiseStep === createFundraiseSteps.GENERALINFO && (
        <GeneralInfo
          setCreateFundraiseStep={setCreateFundraiseStep}
          ownerType={ownerType}
          fundraise={fundraise}
        />
      )}
      {createFundraiseStep === createFundraiseSteps.FUNDINGINFO && (
        <FundingInfo setCreateFundraiseStep={setCreateFundraiseStep} />
      )}
      {createFundraiseStep === createFundraiseSteps.PREVIEW && (
        <Preview setCreateFundraiseStep={setCreateFundraiseStep} />
      )}
      {createFundraiseStep === createFundraiseSteps.FINISH && (
        <Finish
          setCreateFundraiseStep={setCreateFundraiseStep}
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
        />
      )}
    </div>
  );
};

export default CreateGoalPage;
