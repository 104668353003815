/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ethers } from "ethers";
import detectEthereumProvider from "@metamask/detect-provider";
import ApiService from "../../store/middleware/api";
import AuthValidation from "./config/AuthValidation";
import Facebook from "../../svg/socialMedia/auth/Facebook";
import Linkedin from "../../svg/socialMedia/auth/Linkedin";
import Web3Wallet from "../../svg/socialMedia/auth/Web3Wallet";
import PasswordPopover from "./popovers/PasswordPopover";
import OpenEye from "../../svg/eye/OpenEye";
import ClosedEye from "../../svg/eye/ClosedEye";
import {
  emailLoginDonor,
  emailLoginFundraiser,
  emailSignupDonor,
  emailSignupFundraiser,
  verify,
} from "../../store/reducers/userSlice";
import userRoles from "../../constans/userRoles";
import Google from "../../svg/socialMedia/auth/Google";

const AuthForm = ({ setEmail, setPassword }) => {
  const [error, setError] = useState("");
  const [numberOfCharacters, setNumberOfCharacters] = useState(false);
  const [isLowerCase, setIsLowerCase] = useState(false);
  const [isSymbol, setIsSymbol] = useState(false);
  const [isUpperCase, setIsUpperCase] = useState(false);
  const [isNumber, setIsNumber] = useState(false);

  const [isVisiblePopover, setIsVisiblePopover] = useState(false);
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userRole = localStorage.getItem("userRole");
  const location = useLocation();

  const passwordInputValidation = (e) => {
    if (e.target.value.match(/^.*[A-Z]/)) {
      setIsUpperCase(true);
    } else {
      setIsUpperCase(false);
    }
    if (e.target.value.length >= 8) {
      setNumberOfCharacters(true);
    } else {
      setNumberOfCharacters(false);
    }
    if (e.target.value.match(/^.*[a-z]/)) {
      setIsLowerCase(true);
    } else {
      setIsLowerCase(false);
    }
    if (e.target.value.match(/[0-9]/)) {
      setIsNumber(true);
    } else {
      setIsNumber(false);
    }
    if (e.target.value.match(/[#$&_~!><.,?/@%*)^:;|}{(+=-]/)) {
      setIsSymbol(true);
    } else {
      setIsSymbol(false);
    }
  };

  const progress = () => {
    const bar = [];
    if (numberOfCharacters) bar.push(numberOfCharacters);
    if (isUpperCase) bar.push(isUpperCase);
    if (isLowerCase) bar.push(isLowerCase);
    if (isNumber) bar.push(isNumber);
    if (isSymbol) bar.push(isSymbol);

    return bar.length;
  };

  const emailAuth = {
    onSuccessSignupSubmit: async (values) => {
      setError("");
      if (userRole.includes(userRoles.DONOR)) {
        await dispatch(
          emailLoginDonor({
            userData: values,
            onSuccess: () => navigate("/signup/donor/verification"),
          }),
        );
      }
      if (userRole.includes(userRoles.FUNDRAISER)) {
        await dispatch(
          emailLoginFundraiser({
            userData: values,
            onSuccess: () => navigate("/signup/fundraiser/verification"),
          }),
        );
      }
      await dispatch(verify());
    },
    onSuccessLoginSubmit: () => {
      setError("");
      userRole.includes(userRoles.FUNDRAISER) &&
        navigate("/my-fundraisers/approved");
      userRole.includes(userRoles.DONOR) && navigate("/explore");
    },
    onFailureSignup: () => {
      setError("Email is already registered.");
    },
    onFailureLogin: () => {
      setError("Incorrect email or password. Try again.");
    },
  };

  const googleAuth = () => {
    const donorGoogleAuth = location.pathname.includes("login")
      ? "https://hefetest.com/api/v1/auth/login/donor/google"
      : "https://hefetest.com/api/v1/auth/signup/donor/google";

    const fundraiserGoogleAuth = location.pathname.includes("login")
      ? "https://hefetest.com/api/v1/auth/login/fundraiser/google"
      : "https://hefetest.com/api/v1/auth/signup/fundraiser/google";

    return userRole.includes(userRoles.FUNDRAISER)
      ? fundraiserGoogleAuth
      : donorGoogleAuth;
  };

  const facebookAuth = () => {
    const donorFacebookAuth = location.pathname.includes("login")
      ? "https://hefetest.com/api/v1/auth/login/donor/facebook"
      : "https://hefetest.com/api/v1/auth/signup/donor/facebook";

    const fundraiserFacebookAuth = location.pathname.includes("login")
      ? "https://hefetest.com/api/v1/auth/login/fundraiser/facebook"
      : "https://hefetest.com/api/v1/auth/signup/fundraiser/facebook";

    return userRole.includes(userRoles.FUNDRAISER)
      ? fundraiserFacebookAuth
      : donorFacebookAuth;
  };

  const linkedinAuth = () => {
    const donorLinkedinAuth = location.pathname.includes("login")
      ? "https://hefetest.com/api/v1/auth/login/donor/linkedin"
      : "https://hefetest.com/api/v1/auth/signup/donor/linkedin";

    const fundraiserLinkedinAuth = location.pathname.includes("login")
      ? "https://hefetest.com/api/v1/auth/login/fundraiser/linkedin"
      : "https://hefetest.com/api/v1/auth/signup/fundraiser/linkedin";

    return userRole.includes(userRoles.FUNDRAISER)
      ? fundraiserLinkedinAuth
      : donorLinkedinAuth;
  };

  const [isMetaMask, setIsMetaMask] = useState(false);
  let provider;
  let signer;
  let accounts;

  useEffect(() => {
    localStorage.removeItem("socialNetwork");
    detectEthereumProvider()
      .then(() => setIsMetaMask(true))
      .catch(() => setIsMetaMask(false));
  }, []);

  const web3Login = async () => {
    await detectEthereumProvider().then(async () => {
      provider = new ethers.providers.Web3Provider(window.ethereum);
      signer = provider.getSigner();
      await provider
        .send("eth_requestAccounts", [])
        .then((acc) => {
          accounts = acc;
        })
        .then(() =>
          userRole.includes(userRoles.DONOR)
            ? ApiService.apiCall({
                endpoint: `/auth/donor/${accounts[0]}`,
                method: "GET",
                withCredentials: true,
              })
            : ApiService.apiCall({
                endpoint: `/auth/fundraiser/${accounts[0]}`,
                method: "GET",
                withCredentials: true,
              }),
        )
        .then(async ({ data }) => {
          await signer
            .signMessage(`Message to sign for login. Nonce: ${data.nonce}`)
            .then(async (msg) => {
              userRole.includes(userRoles.DONOR)
                ? await ApiService.apiCall({
                    endpoint: "/auth/login/donor/ethwallet",
                    method: "POST",
                    query: { address: accounts[0], signedMessage: msg },
                    withCredentials: true,
                  })
                : await ApiService.apiCall({
                    endpoint: "/auth/login/fundraiser/ethwallet",
                    method: "POST",
                    query: { address: accounts[0], signedMessage: msg },
                    withCredentials: true,
                  });
            });
        })
        .then(async () => {
          userRole.includes(userRoles.DONOR)
            ? await navigate("/explore")
            : await navigate("/my-fundraisers/approved");
        })
        .catch(() => setError("User with such wallet unauthorized"));
    });
  };

  const web3Signup = async () => {
    await detectEthereumProvider().then(async () => {
      provider = new ethers.providers.Web3Provider(window.ethereum);
      signer = provider.getSigner();
      await provider
        .send("eth_requestAccounts", [])
        .then((acc) => {
          accounts = acc;
        })
        .then(async () => {
          await signer.signMessage(`Message to signup`).then(async (msg) => {
            userRole.includes(userRoles.DONOR)
              ? await ApiService.apiCall({
                  endpoint: "/auth/signup/donor/ethwallet",
                  method: "POST",
                  query: { address: accounts[0], signedMessage: msg },
                  withCredentials: true,
                })
              : await ApiService.apiCall({
                  endpoint: "/auth/signup/fundraiser/ethwallet",
                  method: "POST",
                  query: { address: accounts[0], signedMessage: msg },
                  withCredentials: true,
                });
          });
        })
        .then(async () => {
          userRole.includes(userRoles.DONOR)
            ? await navigate("/signup/donor/donorProfile")
            : await navigate("/signup/fundraiser/fundraiserProfile");
        })
        .catch(() => setError("User with such wallet already exist"));
    });
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
        <div className="p-6 ">
          <div className="space-y-2 mb-6">
            <h1 className="text-2xl font-bold leading-tight tracking-tight text-gray-900">
              {location.pathname.includes("signup")
                ? `Create ${
                    userRole.includes(userRoles.FUNDRAISER)
                      ? "a fundraiser"
                      : "a donor"
                  } account`
                : `Log into ${
                    userRole.includes(userRoles.FUNDRAISER)
                      ? "fundraiser"
                      : "donor"
                  } account`}
            </h1>
            <h2 className="font-medium text-base text-gray-500">
              {location.pathname.includes("signup")
                ? "Get started with an account on Hefe"
                : "Welcome back! Please enter your details."}
            </h2>
          </div>
          <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={async (values) => {
              if (
                location.pathname.includes("signup") &&
                userRole.includes(userRoles.DONOR)
              ) {
                await dispatch(
                  emailSignupDonor({
                    userData: values,
                    onSuccess: () => emailAuth.onSuccessSignupSubmit(values),
                    onFailure: () => emailAuth.onFailureSignup(),
                  }),
                );
              }
              if (
                location.pathname.includes("signup") &&
                userRole.includes(userRoles.FUNDRAISER)
              ) {
                await dispatch(
                  emailSignupFundraiser({
                    userData: values,
                    onSuccess: () => emailAuth.onSuccessSignupSubmit(values),
                    onFailure: () => emailAuth.onFailureSignup(),
                  }),
                );
              }
              if (
                location.pathname.includes("login") &&
                userRole.includes(userRoles.DONOR)
              ) {
                await dispatch(
                  emailLoginDonor({
                    userData: values,
                    onSuccess: () => emailAuth.onSuccessLoginSubmit(),
                    onFailure: () => emailAuth.onFailureLogin(),
                    smsTwofa: () => {
                      setEmail(values.email);
                      setPassword(values.password);
                      navigate("/login/donor/two-factor/verify/sms");
                    },
                    appTwofa: () => {
                      setEmail(values.email);
                      setPassword(values.password);
                      navigate("/login/donor/two-factor/verify/app");
                    },
                  }),
                );
              }
              if (
                location.pathname.includes("login") &&
                userRole.includes(userRoles.FUNDRAISER)
              ) {
                await dispatch(
                  emailLoginFundraiser({
                    userData: values,
                    onSuccess: () => emailAuth.onSuccessLoginSubmit(),
                    onFailure: () => emailAuth.onFailureLogin(),
                    smsTwofa: () => {
                      setEmail(values.email);
                      setPassword(values.password);
                      navigate("/login/fundraiser/two-factor/verify/sms");
                    },
                    appTwofa: () => {
                      setEmail(values.email);
                      setPassword(values.password);
                      navigate("/login/fundraiser/two-factor/verify/app");
                    },
                  }),
                );
              }
            }}
            validationSchema={AuthValidation}
          >
            {({ values, handleChange, handleBlur, errors, touched }) => (
              <Form className="min-w-[370px] mb-4">
                {error && (
                  <div
                    className="p-4 mb-6 text-sm text-red-700 bg-red-100 rounded-lg"
                    role="alert"
                  >
                    <span className="font-medium">{error}</span>
                  </div>
                )}
                <div className="space-y-5 mb-6">
                  <div>
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Email:
                    </label>
                    <input
                      type="text"
                      name="email"
                      id="email"
                      className={
                        errors.email && touched.email
                          ? "text-base bg-gray-50 border-2 border-red-300 text-gray-500 rounded-lg focus:ring-red-300 focus:border-red-300 block w-full p-2.5"
                          : "text-base bg-gray-50 border border-gray-300 text-gray-500 rounded-lg focus:ring-gray-300 focus:border-gray-300 block w-full p-2.5"
                      }
                      placeholder="Enter your email"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                  </div>

                  <div style={{ position: "relative" }}>
                    <label
                      htmlFor="password"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Password:
                    </label>
                    <input
                      type={isVisiblePassword ? "text" : "password"}
                      name="password"
                      id="password"
                      placeholder="Enter your password"
                      className={
                        errors.password && touched.password
                          ? "text-base bg-gray-50 border-2 border-red-300 text-gray-500 rounded-lg focus:ring-red-300 focus:border-red-300 block w-full p-2.5"
                          : "text-base bg-gray-50 border border-gray-300 text-gray-500 rounded-lg focus:ring-gray-300 focus:border-gray-300 block w-full p-2.5"
                      }
                      value={values.password}
                      onChange={(e) => {
                        handleChange(e);
                        passwordInputValidation(e);
                      }}
                      onBlur={handleBlur}
                      onMouseEnter={() =>
                        location.pathname.includes("signup") &&
                        setIsVisiblePopover(true)
                      }
                      onMouseOut={() =>
                        location.pathname.includes("signup") &&
                        setIsVisiblePopover(false)
                      }
                    />
                    {isVisiblePassword ? (
                      <OpenEye setIsVisiblePassword={setIsVisiblePassword} />
                    ) : (
                      <ClosedEye setIsVisiblePassword={setIsVisiblePassword} />
                    )}

                    {isVisiblePopover &&
                      location.pathname.includes("signup") && (
                        <PasswordPopover
                          numberOfCharacters={numberOfCharacters}
                          isLowerCase={isLowerCase}
                          isUpperCase={isUpperCase}
                          isSymbol={isSymbol}
                          isNumber={isNumber}
                          progress={progress()}
                        />
                      )}
                  </div>
                </div>
                <button
                  type="submit"
                  className={
                    errors.password || errors.email
                      ? "w-full focus:outline-none text-gray-900 bg-gray-300 hover:bg-gray-400 font-medium rounded-lg text-base py-3"
                      : "w-full focus:outline-none text-white bg-primary-100 hover:bg-green-500 font-medium rounded-lg text-base py-3"
                  }
                  disabled={
                    (errors.email && location.pathname.includes("signup")) ||
                    (errors.password && location.pathname.includes("signup"))
                  }
                >
                  {location.pathname.includes("signup") ? "Sign up" : " Log in"}
                </button>
              </Form>
            )}
          </Formik>
          <button
            type="button"
            onClick={() => navigate("/resetPassword/email")}
            className="font-medium text-base text-primary-100 hover:underline"
          >
            Forgot password?
          </button>
          <div className="flex items-center my-4">
            <div className="w-28 h-0.5 bg-gray-200" />
            <div className="px-2 text-center text-gray-500">
              Or continue with
            </div>
            <div className="w-28 h-0.5 bg-gray-200" />
          </div>
          <div className="space-y-4">
            {isMetaMask ? (
              <div className="items-center">
                <button
                  type="button"
                  className="w-full inline-flex items-center justify-center py-4 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100"
                  onClick={() => {
                    location.pathname.includes("login")
                      ? web3Login()
                      : web3Signup();
                  }}
                >
                  <Web3Wallet />
                </button>
              </div>
            ) : (
              <a
                className="w-full inline-flex items-center justify-center py-4 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100"
                href="https://metamask.io/"
                target="_blank"
                rel="noreferrer"
              >
                <Web3Wallet />
              </a>
            )}

            <div className="items-center sm:flex sm:space-x-4 sm:space-y-0">
              <a
                href={facebookAuth()}
                onClick={() => {
                  location.pathname.includes("signup") &&
                    localStorage.setItem("socialNetwork", "facebook");
                }}
                className="w-full inline-flex items-center justify-center py-4 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 "
              >
                <Facebook />
              </a>

              <a
                href={googleAuth()}
                onClick={() => {
                  location.pathname.includes("signup") &&
                    localStorage.setItem("socialNetwork", "google");
                }}
                className="w-full inline-flex items-center justify-center py-4 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100"
              >
                <Google />
              </a>
              <a
                href={linkedinAuth()}
                onClick={() => {
                  location.pathname.includes("signup") &&
                    localStorage.setItem("socialNetwork", "linkedin");
                }}
                className="w-full inline-flex items-center justify-center py-4 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100"
              >
                <Linkedin />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthForm;
