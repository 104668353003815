import React from "react";

const CreateFundraisePlus = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 5H1H5Z" fill="white" />
      <path
        d="M5 1V5M5 5V9M5 5H9M5 5H1"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CreateFundraisePlus;
