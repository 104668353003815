import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Logo from "../svg/logo/Logo";
import { logout } from "../store/reducers/userSlice";
import userRoles from "../constans/userRoles";

const AuthHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const userRole = localStorage.getItem("userRole");

  return (
    <header>
      <nav className="bg-white border-b border-gray-100 px-4 py-2.5">
        <div className="flex flex-wrap justify-between items-center max-w-[1440px] max-2xl:mx-[50px] mx-auto">
          <Link to="/" className="flex items-center">
            <Logo />
          </Link>
          <div className="flex items-center lg:order-2 cursor-pointer">
            <button
              type="button"
              onClick={() => {
                if (
                  location.pathname.includes("signup") &&
                  userRole.includes(userRoles.DONOR)
                ) {
                  navigate("/login/donor/form");
                }
                if (
                  location.pathname.includes("signup") &&
                  userRole.includes(userRoles.FUNDRAISER)
                ) {
                  navigate("/login/fundraiser/form");
                }
                if (
                  (location.pathname.includes("login") &&
                    userRole.includes(userRoles.FUNDRAISER)) ||
                  (location.pathname.includes("resetPassword") &&
                    userRole.includes(userRoles.FUNDRAISER))
                ) {
                  navigate("/signup/fundraiser/form");
                }
                if (
                  (location.pathname.includes("login") &&
                    userRole.includes(userRoles.DONOR)) ||
                  (location.pathname.includes("resetPassword") &&
                    userRole.includes(userRoles.DONOR))
                ) {
                  navigate("/signup/donor/form");
                }
                if (
                  location.pathname.includes("signup") &&
                  location.pathname.includes("welcome")
                ) {
                  dispatch(logout({ onSuccess: () => navigate("/") }));
                }
              }}
              className="text-primary-100 hover:bg-gray-100 font-medium rounded-lg text-base px-4 lg:px-5 py-2 lg:py-2.5 mr-2 focus:outline-none"
            >
              {location.pathname.includes("signup") &&
                location.pathname.includes("welcome") &&
                "Log out"}
              {location.pathname.includes("signup") &&
                !location.pathname.includes("welcome") &&
                "Log in"}
              {(location.pathname.includes("login") ||
                location.pathname.includes("resetPassword")) &&
                "Sign up"}
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default AuthHeader;
