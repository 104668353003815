import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  format,
  formatDistanceToNowStrict,
  addDays,
  fromUnixTime,
} from "date-fns";
import uuid from "react-uuid";
import BlackBadgeCalendar from "../../svg/badgeCalendar/BlackBadgeCalendar";
import Ellipse from "../../svg/ellipse/Ellipse";
import WhiteBadgeCalendar from "../../svg/badgeCalendar/WhiteBadgeCalendar";
import createFundraiseSteps from "../../constans/createFundraiseSteps";
import { createFundraise } from "../../store/reducers/fundraiseSlice";
import fundraiseTypes from "../../constans/fundraiseTypes";

const Preview = ({ setCreateFundraiseStep }) => {
  const [showMore, setShowMore] = useState(false);
  const fundraise = useSelector((state) => state.fundraise.fundraise);
  const currentUser = useSelector((state) => state.user.currentUser);

  const dispatch = useDispatch();

  const create = () => {
    dispatch(
      createFundraise({
        fundraiseData: {
          ownerType: fundraise.ownerType,
          organizationName: fundraise.organizationName,
          organizationTaxId: fundraise.organizationTaxId,
          organizationCountry: fundraise.organizationCountry,
          title: fundraise.title,
          categoriesIds: [{ categoryId: fundraise.categoriesIds.id }],
          description: fundraise.description,
          goal: Number(fundraise.goal),
          goalCurrency: fundraise.goalCurrency,
          goalType: fundraise.goalType,
          deadlineType: fundraise.deadlineType,
          deadlineDays: Number(fundraise.deadlineDays),
          deadlineTimestamp: fundraise.deadlineTimestamp,
        },
        onSuccess: () => setCreateFundraiseStep(createFundraiseSteps.FINISH),
      }),
    );
  };

  return (
    <div className="relative p-6 max-w-[1240px] w-full h-full md:h-auto">
      <div className="relative p-6 bg-white rounded-lg shadow">
        <div className="flex gap-9">
          <div className="w-2/3">
            <h2 className="font-bold text-2xl text-gray-900 mb-4">
              {fundraise.title}
            </h2>
            <div className="flex items-center mb-9">
              <div className="flex gap-2">
                {currentUser?.avatarUrl ? (
                  <img
                    src={currentUser.avatarUrl}
                    alt="avatar"
                    className="h-[25px] w-[25px] rounded-full"
                  />
                ) : (
                  currentUser?.firstName &&
                  currentUser?.lastName && (
                    <div className="h-[25px] w-[25px] rounded-full bg-gray-500 flex items-center justify-center text-center text-xs font-normal text-white">
                      {currentUser.firstName.split("").shift() +
                        currentUser.lastName.split("").shift()}
                    </div>
                  )
                )}
                <p className="text-gray-600 text-base font-medium">
                  {currentUser.firstName} {currentUser.lastName}
                </p>
              </div>
              <Ellipse />
              <div className="text-sm font-medium text-gray-800 bg-gray-100 px-3 py-0.5 rounded-md select-none">
                {fundraise.categoriesIds.name}
              </div>
              <Ellipse />
              <div className="flex gap-1 items-center text-sm font-medium text-gray-800 bg-gray-100 px-3 py-0.5 rounded-md select-none">
                <BlackBadgeCalendar />
                {fundraise.deadlineDays ? (
                  <span>{fundraise.deadlineDays} days left</span>
                ) : (
                  <span>
                    {formatDistanceToNowStrict(
                      new Date(fromUnixTime(fundraise.deadlineTimestamp)),
                    )}{" "}
                    left
                  </span>
                )}
              </div>
            </div>
            <div className="mb-9 flex flex-col gap-4">
              {fundraise.images &&
                fundraise.images.map((image) => (
                  <img
                    src={image}
                    key={uuid()}
                    alt="preview"
                    className="rounded-lg max-w-[784px]"
                  />
                ))}
            </div>
            <div className="text-base font-normal text-gray-800 mb-10">
              {!showMore ? (
                <>
                  <p>{`${fundraise.description.substring(0, 190)}...`}</p>
                  <button
                    className="text-primary-100 font-medium"
                    type="button"
                    onClick={() => setShowMore(true)}
                  >
                    Show more
                  </button>
                </>
              ) : (
                <>
                  <p>{fundraise.description}</p>
                  <button
                    className="text-primary-100 font-medium"
                    type="button"
                    onClick={() => setShowMore(false)}
                  >
                    Show less
                  </button>
                </>
              )}
            </div>
            <div className="mb-10">
              <h3 className="mb-4 text-gray-800 font-bold text-2xl">
                Organiser
              </h3>
              <div className="rounded-lg shadow flex items-center gap-2 p-6">
                {currentUser?.avatarUrl ? (
                  <img
                    src={currentUser.avatarUrl}
                    alt="avatar"
                    className="h-[32px] w-[32px] rounded-full"
                  />
                ) : (
                  currentUser?.firstName &&
                  currentUser?.lastName && (
                    <div className="h-[32px] w-[32px] rounded-full bg-gray-500 flex items-center justify-center text-center text-sm font-normal text-white">
                      {currentUser.firstName.split("").shift() +
                        currentUser.lastName.split("").shift()}
                    </div>
                  )
                )}
                <div className="flex flex-col">
                  <p className="text-base font-medium text-gray-800">
                    {currentUser.firstName} {currentUser.lastName}
                  </p>
                  <p className="text-sm font-medium text-gray-500">
                    {fundraise.ownerType === fundraiseTypes.PERSONAL
                      ? "Personal fundraiser"
                      : "Organization fundraiser"}
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-10">
              <h3 className="mb-4 text-gray-800 font-bold text-2xl">
                Fundraiser
              </h3>
              <div className="rounded-lg shadow flex items-center gap-2 p-6">
                <div className="flex flex-col gap-2">
                  <p className="text-lg font-semibold text-gray-800">
                    {fundraise.goalType === "FIXED"
                      ? "Fixed Goal"
                      : "Flexible goal"}
                  </p>
                  <p className="text-base font-medium text-gray-600">
                    {fundraise.goalType === "FIXED"
                      ? "Must fundraise entire amount declared in goal (or more), or else the funds do not get released to then fundraiser and are returned to the donors."
                      : "Any amount fundraised will go to the fundraiser, whether the goal is met or not."}
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-10">
              <h3 className="mb-4 text-gray-800 font-bold text-2xl">
                Timeline
              </h3>
              <div className="rounded-lg shadow flex items-center gap-2 p-6">
                <div className="flex gap-10">
                  <div className="text-base font-medium text-gray-600 flex items-center gap-2.5">
                    <WhiteBadgeCalendar /> Not published
                  </div>
                  <div className="text-base font-medium text-gray-600 flex items-center gap-2.5">
                    <WhiteBadgeCalendar /> Ends
                    {fundraise.deadlineDays ? (
                      <span className="text-gray-800">
                        {format(
                          addDays(new Date(), fundraise.deadlineDays),
                          "LLL dd, yyyy",
                        )}
                      </span>
                    ) : (
                      <span className="text-gray-800">
                        {format(
                          new Date(fromUnixTime(fundraise.deadlineTimestamp)),
                          "LLL dd, yyyy",
                        )}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-6 justify-start w-1/3 h-fit mt-28 sticky top-[110px] mb-10">
            <div className="p-6 rounded-lg shadow ">
              <div className=" flex flex-col w-full">
                <div className="flex items-center mb-4">
                  <h2 className="font-bold text-2xl text-gray-800 pr-2">
                    0 ETH
                  </h2>
                  <h5 className="font-normal text-base text-gray-500">
                    raised of {fundraise.goal} {fundraise.goalCurrency}
                  </h5>
                </div>
                <div className="w-full bg-gray-100 h-2 mb-5">
                  <div
                    className="bg-primary-100 h-2 rounded-sm"
                    style={{ width: "0%" }}
                  />
                </div>
                <div className="flex justify-between gap-4 mb-6">
                  <div className="flex flex-col justify-start bg-gray-100 p-4 w-full rounded-md">
                    <h5 className="font-normal text-base text-gray-500">
                      Donations
                    </h5>
                    <h2 className="font-bold text-2xl text-gray-800">0</h2>
                  </div>
                  <div className="flex flex-col justify-start bg-gray-100 p-4 w-full rounded-md">
                    <h5 className="font-normal text-base text-gray-500">
                      AVG donation
                    </h5>
                    <h2 className="font-bold text-2xl text-gray-800">0 ETH</h2>
                  </div>
                </div>
                <div className="flex items-center justify-between gap-[12px]">
                  <input
                    type="text"
                    className="block py-[12px] px-4 w-full text-base text-gray-500 bg-gray-50 rounded-lg border border-gray-300 focus:ring-gray-200 focus:border-gray-200"
                    placeholder="Donation amount (ETH)"
                    disabled
                  />
                  <button
                    className="py-[12px] px-4 text-white bg-primary-100 rounded-lg font-semibold"
                    type="button"
                    disabled
                  >
                    Donate
                  </button>
                </div>
              </div>
            </div>
            <div className="p-6 rounded-lg shadow">
              <h2 className="font-semibold text-xl text-gray-800 mb-6">
                Donors
              </h2>
              <div>
                <h5 className="text-base font-normal text-gray-800 text-center">
                  No donations yet
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-5 bg-white flex justify-between">
          <button
            type="button"
            className="py-2 px-5 text-sm font-medium text-gray-800 focus:outline-none rounded-lg border border-gray-200 hover:bg-gray-100"
            onClick={() =>
              setCreateFundraiseStep(createFundraiseSteps.FUNDINGINFO)
            }
          >
            Back
          </button>

          <button
            type="submit"
            className="py-2 px-5 text-sm font-medium text-white focus:outline-none bg-primary-100 rounded-lg border border-gray-200"
            onClick={create}
          >
            Create
          </button>
        </div>
      </div>
    </div>
  );
};

export default Preview;
