import React from "react";

const AnalyticCard = ({ title, amount, icon }) => {
  return (
    <div className="p-6 shadow rounded-md flex flex-col gap-8 w-[274px] bg-white">
      <div className="flex items-center gap-3">
        {icon}
        <p className="text-gray-500 text-base font-normal">{title}</p>
      </div>
      <h3 className="text-2xl font-bold">{amount}</h3>
    </div>
  );
};

export default AnalyticCard;
