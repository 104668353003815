/* eslint-disable */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import GeneralInfoValidation from "./config/GeneralInfoValidation";
import CategoryGeneralPopover from "./popovers/CategoryGeneralPopover";
import StoryGeneralPopover from "./popovers/StoryGeneralPopover";
import {
  getAllCategories,
  setFundraiseImages,
  setGeneralFundraiseInfo,
} from "../../store/reducers/fundraiseSlice";
import createFundraiseSteps from "../../constans/createFundraiseSteps";
import fundraiseTypes from "../../constans/fundraiseTypes";
import PhotoPreview from "./PhotoPreview";

const GeneralInfo = ({ setCreateFundraiseStep, ownerType }) => {
  const dispatch = useDispatch();

  const [isVisibleCategoryInfo, setIsVisibleCategoryInfo] = useState(false);
  const [isVisibleStoryInfo, setIsVisibleStoryInfo] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [images, setImages] = useState([]);
  const [imagesUrl, setImagesUrl] = useState([]);
  const [imagesFile, setImagesFile] = useState([]);

  useMemo(() => {
    setImagesUrl(images.map((image) => image?.data_url));
    setImagesFile(images.map((image) => image?.file));
  }, [images.length]);

  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  const categoriesDB = useSelector((state) => state.fundraise.categories);

  const uploadImg = useCallback((img) => {
    return setImages(img);
  }, []);

  return (
    <div className="relative p-4 w-full max-w-3xl h-full md:h-auto">
      <div className="relative p-4 bg-white rounded-lg shadow sm:p-5">
        <Formik
          initialValues={{
            title: "",
            story: "",
          }}
          onSubmit={(values) => {
            dispatch(
              setGeneralFundraiseInfo({
                title: values.title,
                categoriesIds: selectedCategory,
                description: values.story,
                images: imagesUrl,
              }),
            );
            dispatch(setFundraiseImages(imagesFile));
            setCreateFundraiseStep(createFundraiseSteps.FUNDINGINFO);
          }}
          validationSchema={GeneralInfoValidation}
        >
          {({ values, handleChange, handleBlur, errors, touched }) => (
            <Form encType="multipart/form-data">
              <>
                <div className="flex-col">
                  <div className="mb-6">
                    <label
                      htmlFor="name"
                      className="block text-base font-medium text-gray-900 mb-2"
                    >
                      Add fundraiser title
                    </label>
                    <input
                      type="text"
                      name="title"
                      id="name"
                      className={
                        touched && errors.title
                          ? "bg-gray-50 border-2 border-red-300 text-gray-500 text-base rounded-lg focus:ring-red-300 focus:border-red-300 block w-full p-2.5"
                          : "bg-gray-50 border-2 border-gray-300 text-gray-500 text-base rounded-lg focus:ring-gray-300 focus:border-gray-300 block w-full p-2.5"
                      }
                      placeholder="Enter a title that inspires"
                      required=""
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                    />
                    <p className="text-end text-gray-500 text-xs pt-2 font-medium">
                      255 characters (minimum 5 characters)
                    </p>
                  </div>
                  <div className="mb-6">
                    <div className="flex gap-2 mb-4 items-center">
                      <h3 className="block  text-base font-medium text-gray-900 ">
                        Category
                      </h3>
                      <div className="cursor-pointer relative">
                        <img
                          src="/images/info.png"
                          alt="info"
                          onMouseEnter={() => setIsVisibleCategoryInfo(true)}
                          onMouseOut={() => setIsVisibleCategoryInfo(false)}
                        />
                        {isVisibleCategoryInfo && <CategoryGeneralPopover />}
                      </div>
                    </div>
                    <div className="flex flex-wrap gap-4 ">
                      {categoriesDB.map((category) => (
                        <span
                          key={category.id}
                          className={
                            category.id === selectedCategory?.id
                              ? "cursor-pointer text-sm font-medium text-white bg-primary-100 px-2.5 py-0.5 rounded-md select-none"
                              : "cursor-pointer text-sm font-medium text-gray-800 bg-gray-100 px-2.5 py-0.5 rounded-md select-none"
                          }
                          onClick={() => {
                            setSelectedCategory(category);
                          }}
                        >
                          {category.name}
                        </span>
                      ))}
                    </div>
                  </div>

                  <PhotoPreview images={images} setImages={uploadImg} />

                  <div className="col-span-2 pb-5">
                    <div className="flex gap-2 mb-2 items-center">
                      <label
                        htmlFor="description"
                        className="block text-base font-medium text-gray-900 "
                      >
                        Tell your story
                      </label>
                      <div className="cursor-pointer relative">
                        <img
                          src="/images/info.png"
                          alt="info"
                          onMouseEnter={() => setIsVisibleStoryInfo(true)}
                          onMouseOut={() => setIsVisibleStoryInfo(false)}
                        />
                        {isVisibleStoryInfo && <StoryGeneralPopover />}
                      </div>
                    </div>
                    <textarea
                      id="description"
                      className={
                        touched && errors.story
                          ? "block p-2.5 w-full text-base text-gray-500 bg-gray-50 rounded-lg border-2 border-red-300 focus:ring-red-300 focus:border-red-300 block w-full"
                          : "block p-2.5 w-full text-base text-gray-500 bg-gray-50 rounded-lg border-2 border-gray-300 focus:ring-gray-300 focus:border-gray-300 block w-full"
                      }
                      placeholder="Tell your story to inform and inspire people"
                      rows={6}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.story}
                      name="story"
                    />
                    <p className="text-end text-gray-500 text-xs pt-2 font-medium">
                      Minimum 200 characters
                    </p>
                  </div>
                </div>
                <div className="pt-5 bg-white flex justify-between">
                  <button
                    type="button"
                    className="py-2 px-5 text-sm font-medium text-gray-800 focus:outline-none rounded-lg border border-gray-200 hover:bg-gray-100"
                    onClick={() => {
                      if (ownerType === fundraiseTypes.ORGANIZATION) {
                        setCreateFundraiseStep(createFundraiseSteps.COMPANY);
                      } else {
                        setCreateFundraiseStep(createFundraiseSteps.START);
                      }
                    }}
                  >
                    Back
                  </button>

                  <button
                    type="submit"
                    className={
                      errors.title ||
                      errors.story ||
                      !selectedCategory ||
                      !images.length
                        ? "py-2 px-5 text-sm font-medium text-white focus:outline-none bg-gray-300 rounded-lg border border-gray-200"
                        : "py-2 px-5 text-sm font-medium text-white focus:outline-none bg-primary-100 rounded-lg border border-gray-200"
                    }
                    disabled={!selectedCategory || !images.length}
                  >
                    Continue
                  </button>
                </div>
              </>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default GeneralInfo;
