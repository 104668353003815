/* eslint-disable */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { uploadFundraiseImages } from "../../store/reducers/fundraiseSlice";
import createFundraiseSteps from "../../constans/createFundraiseSteps";
import CreateFundraiseMedal from "../../svg/createFundraiseMedal/createFundraiseMedal";

const Finish = ({ setCreateFundraiseStep, setIsDisabled, isDisabled }) => {
  const fundraise = useSelector((state) => state.fundraise.fundraise);
  const images = useSelector((state) => state.fundraise.images);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  console.log(images);
  const submit = async () => {
    await setIsDisabled(true);
    const formData = new FormData();
    formData.append("name", fundraise.title);
    images.forEach((image) => {
      formData.append("photos[]", image, image.name);
    });
    await dispatch(
      uploadFundraiseImages({
        fundraiseId: fundraise.id,
        images: formData,
        onSuccess: () => {
          navigate("/my-fundraisers/waiting-for-approval");
          setCreateFundraiseStep(createFundraiseSteps.START);
        },
      }),
    );
  };

  return (
    <div className="relative p-6 w-[430px] w-full h-full md:h-auto">
      <div className="relative p-6 bg-white rounded-lg shadow flex flex-col justify-center items-center">
        <CreateFundraiseMedal />
        <h2 className="text-2xl font-bold text-gray-800 mt-7 mb-2 flex justify-center">
          Congratulations! Your goal has been successfully created
        </h2>
        <h5 className="text-base text-gray-500 font-normal mb-6 text-center flex justify-center items-center">
          You can check them on the &quot;My Fundraisers&quot; page.
        </h5>
        <button
          type="submit"
          className="py-3 text-base font-medium text-white focus:outline-none bg-primary-100 rounded-lg border border-gray-200 w-full"
          disabled={isDisabled}
          onClick={async () => {
            await submit();
          }}
        >
          See my fundraisers
        </button>
      </div>
    </div>
  );
};

export default Finish;
