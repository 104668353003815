import * as Yup from "yup";

const CreateProfileWithSocialValidation = (socialNetwork) =>
  socialNetwork === "facebook"
    ? Yup.object().shape({
        country: Yup.string().required(),
        email: Yup.string().email().required(),
      })
    : Yup.object().shape({
        country: Yup.string().required(),
        email: Yup.string().email(),
      });
export default CreateProfileWithSocialValidation;
