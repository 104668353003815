import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiService from "../middleware/api";

const initialState = {
  limitedDonationsByFundraise: {},
  allDonationsByFundraise: {},
};

export const getLimitedDonationByFundraise = createAsyncThunk(
  "donations/getDonationByFundraise",
  async ({ id, limit, onSuccess, onFailure }) => {
    const limitQuery = limit ? `&limit=${limit}` : "";
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/donation/?fundraiseIdAtContract=${id}&ignoreFundraiseAttributes=true${limitQuery}`,
        method: "GET",
      });
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      if (onFailure) onFailure();
      return error;
    }
  },
);

export const getAllDonations = createAsyncThunk(
  "donations/getAllDonations",
  async ({
    id,
    ignoreFundraiseAttributes,
    donorUuid,
    onSuccess,
    onFailure,
  }) => {
    const idQuery = id ? `&fundraiseIdAtContract=${id}` : "";
    const donorUuidQuery = donorUuid ? `&donorUuid=${donorUuid}` : "";
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/donation/?ignoreFundraiseAttributes=${ignoreFundraiseAttributes}${idQuery}${donorUuidQuery}`,
        method: "GET",
      });
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      if (onFailure) onFailure();
      return error;
    }
  },
);

const fundraiseSlice = createSlice({
  name: "donations",
  initialState,
  reducers: {},
  extraReducers: {
    [getLimitedDonationByFundraise.fulfilled]: (state, { payload }) => {
      state.limitedDonationsByFundraise = { ...payload };
    },
    [getAllDonations.fulfilled]: (state, { payload }) => {
      state.allDonationsByFundraise = { ...payload };
    },
  },
});

export default fundraiseSlice.reducer;
