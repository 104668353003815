import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Spinner } from "flowbite-react";
import {
  getAllCategories,
  getAllFundraisers,
  setSortByForExplore,
} from "../../store/reducers/fundraiseSlice";
import Filter from "../../svg/filter/Filter";
import ExploreFundraiseCard from "../../components/exploreFundraisers/ExploreFundraiseCard";
import FilterModal from "../../components/modals/FilterModal";
import NoFundraisersCard from "../../components/noFundraisers/NoFundraisersCard";

const ExplorePage = () => {
  const dispatch = useDispatch();
  const [isCardsLoaded, setIsCardsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const allFundraisers = useSelector((state) => state.fundraise.allFundraisers);
  const currentUser = useSelector((state) => state.user.currentUser);
  const categoriesDB = useSelector((state) => state.fundraise.categories);

  const {
    country,
    categoriesIds,
    closeToGoal,
    expiresInNext24Hours,
    ownerType,
    search,
    sortBy,
  } = useSelector((store) => store.fundraise.filtersByAll);

  useEffect(() => {
    dispatch(
      getAllFundraisers({
        country,
        categoriesIds,
        closeToGoal,
        expiresInNext24Hours,
        ownerType,
        search,
        sortBy,
        onSuccess: () => {
          setIsCardsLoaded(true);
          setIsLoading(false);
        },
        onFailure: () => {
          setIsLoading(false);
        },
      }),
    );
  }, [
    dispatch,
    search,
    categoriesIds,
    closeToGoal,
    expiresInNext24Hours,
    ownerType,
    country,
    sortBy,
  ]);

  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  return (
    <>
      <div className="justify-between items-center flex mb-6">
        <div className="flex gap-3 items-center">
          <h2 className="font-bold text-2xl text-gray-800">All fundraisers</h2>
          <h5 className="px-3 py-1 font-bold text-xl text-gray-900 bg-gray-300 rounded-lg">
            {allFundraisers.length}
          </h5>
        </div>
        <div className="flex items-center gap-4">
          <Dropdown label="Sort by" inline>
            <Dropdown.Item
              onClick={() => {
                dispatch(setSortByForExplore({ sortBy: { deadline: "ASC" } }));
                dispatch(
                  getAllFundraisers({
                    country,
                    categoriesIds,
                    closeToGoal,
                    expiresInNext24Hours,
                    ownerType,
                    sortBy: { deadline: "ASC" },
                    search,
                    onSuccess: () => {
                      setIsCardsLoaded(true);
                      setIsLoading(false);
                    },
                    onFailure: () => {
                      setIsLoading(false);
                    },
                  }),
                );
              }}
            >
              Deadline
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                dispatch(
                  setSortByForExplore({ sortBy: { publicationDate: "DESC" } }),
                );
                dispatch(
                  getAllFundraisers({
                    country,
                    categoriesIds,
                    closeToGoal,
                    expiresInNext24Hours,
                    ownerType,
                    sortBy: { publicationDate: "DESC" },
                    search,
                    onSuccess: () => {
                      setIsCardsLoaded(true);
                      setIsLoading(false);
                    },
                    onFailure: () => {
                      setIsLoading(false);
                    },
                  }),
                );
              }}
            >
              Published date
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                dispatch(
                  setSortByForExplore({ sortBy: { raisedAmount: "DESC" } }),
                );
                dispatch(
                  getAllFundraisers({
                    country,
                    categoriesIds,
                    closeToGoal,
                    expiresInNext24Hours,
                    ownerType,
                    sortBy: { raisedAmount: "DESC" },
                    search,
                    onSuccess: () => {
                      setIsCardsLoaded(true);
                      setIsLoading(false);
                    },
                    onFailure: () => {
                      setIsLoading(false);
                    },
                  }),
                );
              }}
            >
              Fundraise raised amount
            </Dropdown.Item>
          </Dropdown>
          <button
            type="button"
            className="text-base text-white font-medium flex gap-[10px] bg-primary-100 py-[5px] px-4 items-center rounded-md"
            onClick={() => setIsOpenModal(true)}
          >
            <Filter />
            Filters
          </button>
          <FilterModal
            isOpenModal={isOpenModal}
            setIsOpenModal={setIsOpenModal}
            categoriesDB={categoriesDB}
          />
        </div>
      </div>
      <div className="flex flex-wrap gap-5">
        {isCardsLoaded &&
          !isLoading &&
          allFundraisers.map((fundraise) => {
            return (
              <ExploreFundraiseCard
                key={fundraise.id}
                fundraise={fundraise}
                currentUser={currentUser}
              />
            );
          })}
        {!isCardsLoaded && isLoading && (
          <div className="flex justify-center mt-[10%] mx-auto">
            <Spinner
              aria-label="Extra large spinner example"
              size="xl"
              color="success"
            />
          </div>
        )}
        {!isLoading && !allFundraisers.length && <NoFundraisersCard />}
      </div>
    </>
  );
};

export default ExplorePage;
