const deadlineOptions = [
  { select: 20 },
  { select: 40 },
  { select: 60 },
  { select: 80 },
  { select: 100 },
  { select: 120 },
  { select: 140 },
  { select: 160 },
  { select: 180 },
  { select: 200 },
];

export default deadlineOptions;
