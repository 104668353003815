import React from "react";

const Ellipse = () => {
  return (
    <svg
      width="4"
      height="4"
      viewBox="0 0 4 4"
      fill="#9CA3AF"
      xmlns="http://www.w3.org/2000/svg"
      className="mx-4"
    >
      <circle cx="2" cy="2" r="2" fill="#9CA3AF" />
    </svg>
  );
};

export default Ellipse;
