import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ApiService from "../middleware/api";

export const changeFundraiserPassword = createAsyncThunk(
  "user/changeFundraiserPassword",
  async ({ email, onSuccess, onFailure }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/auth/reset/fundraiser/sendcode`,
        method: "POST",
        query: email,
      });
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      if (onFailure) onFailure();
      return rejectWithValue(error);
    }
  },
);

export const changeDonorPassword = createAsyncThunk(
  "user/changeDonorPassword",
  async ({ email, onSuccess, onFailure }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/auth/reset/donor/sendcode`,
        method: "POST",
        query: email,
      });
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      if (onFailure) onFailure();
      return rejectWithValue(error);
    }
  },
);

export const verifyFundraiserCodeForReset = createAsyncThunk(
  "user/verifyFundraiserCodeForReset",
  async ({ verificationData, onSuccess, onFailure }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/auth/reset/fundraiser/verifyCode`,
        method: "POST",
        query: verificationData,
      });
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      if (onFailure) onFailure();
      return rejectWithValue(error);
    }
  },
);

export const verifyDonorCodeForReset = createAsyncThunk(
  "user/verifyDonorCodeForReset",
  async ({ verificationData, onSuccess, onFailure }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/auth/reset/donor/verifyCode`,
        method: "POST",
        query: verificationData,
      });
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      if (onFailure) onFailure();
      return rejectWithValue(error);
    }
  },
);

export const donorChangePassword = createAsyncThunk(
  "user/donorChangePassword",
  async ({ password, onSuccess, onFailure }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/auth/reset/donor/changePassword`,
        method: "POST",
        query: password,
      });
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      if (onFailure) onFailure();
      return rejectWithValue(error);
    }
  },
);

export const fundraiserChangePassword = createAsyncThunk(
  "user/fundraiserChangePassword",
  async ({ password, onSuccess, onFailure }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/auth/reset/fundraiser/changePassword`,
        method: "POST",
        query: password,
      });
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      if (onFailure) onFailure();
      return rejectWithValue(error);
    }
  },
);

const userSlice = createSlice({
  name: "resetPassword",
});

export default userSlice.reducer;
