import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../svg/logo/Logo";
import Discord from "../svg/socialMedia/footer/Discord";
import Twitter from "../svg/socialMedia/footer/Twitter";
import Telegram from "../svg/socialMedia/footer/Telegram";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className="p-4 bg-white border-t border-gray-100">
      <div className="max-w-[1440px] max-2xl:mx-[50px] mx-auto">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0">
            <Logo />
          </div>
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
            <button
              type="button"
              className="text-gray-500 hover:underline"
              onClick={() => navigate("/terms-of-use")}
            >
              Terms of use
            </button>
            <button
              type="button"
              className="text-gray-500 hover:underline"
              onClick={() => navigate("/privacy-policy")}
            >
              Privacy policy
            </button>

            <button
              type="button"
              className="text-gray-500 hover:underline"
              onClick={() => navigate("/contact-us")}
            >
              Contact us
            </button>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto " />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-gray-500 sm:text-center">
            © 2023 Hefe, Inc. All rights reserved.
          </span>
          <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
            <button type="button">
              <Discord />
            </button>
            <button type="button">
              <Twitter />
            </button>
            <button type="button">
              <Telegram />
            </button>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
