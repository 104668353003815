import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Form, Formik } from "formik";
import EmailValidation from "../config/EmailValidation";
import {
  donorSocialTwofaSendSMS,
  donorTwofaEnableSMS,
  donorTwofaSendSMS,
  donorTwofaVerifySMS,
  emailLoginDonor,
  emailLoginFundraiser,
  fundraiserSocialTwofaSendSMS,
  fundraiserTwofaEnableSMS,
  fundraiserTwofaSendSMS,
  fundraiserTwofaVerifySMS,
} from "../../../store/reducers/userSlice";
import userRoles from "../../../constans/userRoles";

const TwoFactorSmsVerify = ({ phoneNumber, email, password }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const userRole = localStorage.getItem("userRole");
  const socialNetwork = localStorage.getItem("socialNetwork");

  /* eslint-disable */
  useEffect(() => {
    if (pathname.includes("login") && email) {
      userRole.includes(userRoles.DONOR)
        ? dispatch(donorTwofaSendSMS({ email }))
        : dispatch(fundraiserTwofaSendSMS({ email }));
    }
    if (pathname.includes("login") && !email) {
      userRole.includes(userRoles.DONOR)
        ? dispatch(donorSocialTwofaSendSMS())
        : dispatch(fundraiserSocialTwofaSendSMS());
    }
  }, []);

  const handleChangeNextInput = (e) => {
    const { maxLength, value, name } = e.target;
    const [, fieldIndex] = name.split("_");

    if (value.length >= maxLength) {
      if (parseInt(fieldIndex, 10) < 6) {
        const nextSibling = document.querySelector(
          `input[name=ssn_${parseInt(fieldIndex, 10) + 1}]`,
        );

        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }
  };
  const [isDisabled, setIsDisabled] = useState(false);

  return (
    <div className="flex flex-col items-center bg-[#FFFF] p-6 shadow-lg rounded-t-lg">
      <h1 className="mb-2 text-2xl font-bold tracking-tight text-gray-800 leding-tight">
        Two-step verification
      </h1>
      {phoneNumber ? (
        <p className="font-medium text-gray-500">
          We`ve sent a code to <span />
          <span className="font-medium text-gray-600 text-base">
            +{phoneNumber}
          </span>
        </p>
      ) : (
        <p className="font-medium text-gray-500 text-base">
          We`ve sent a code to your phone number
        </p>
      )}
      <Formik
        initialValues={{
          ssn_1: "",
          ssn_2: "",
          ssn_3: "",
          ssn_4: "",
          ssn_5: "",
          ssn_6: "",
        }}
        onSubmit={(values) => {
          if (pathname.includes("signup") && socialNetwork) {
            return userRole.includes(userRoles.FUNDRAISER)
              ? dispatch(
                  fundraiserTwofaVerifySMS({
                    phone: `+${phoneNumber}`,
                    code: String(Object.values(values).join("")),
                    onSuccess: () => navigate("/signup/fundraiser/socialAuth"),
                  }),
                )
              : dispatch(
                  donorTwofaVerifySMS({
                    phone: `+${phoneNumber}`,
                    code: String(Object.values(values).join("")),
                    onSuccess: () => navigate("/signup/donor/socialAuth"),
                  }),
                );
          }
          if (pathname.includes("signup")) {
            return userRole.includes(userRoles.FUNDRAISER)
              ? dispatch(
                  fundraiserTwofaVerifySMS({
                    phone: `+${phoneNumber}`,
                    code: String(Object.values(values).join("")),
                    onSuccess: () =>
                      navigate("/signup/fundraiser/fundraiserProfile"),
                  }),
                )
              : dispatch(
                  donorTwofaVerifySMS({
                    phone: `+${phoneNumber}`,
                    code: String(Object.values(values).join("")),
                    onSuccess: () => navigate("/signup/donor/donorProfile"),
                  }),
                );
          }
          if (pathname.includes("login")) {
            return userRole.includes(userRoles.FUNDRAISER)
              ? dispatch(
                  emailLoginFundraiser({
                    userData: {
                      email,
                      password,
                      code: String(Object.values(values).join("")),
                    },
                    onSuccess: () => navigate("/my-fundraisers/approved"),
                  }),
                )
              : dispatch(
                  emailLoginDonor({
                    userData: {
                      email,
                      password,
                      code: String(Object.values(values).join("")),
                    },
                    onSuccess: () => navigate("/explore"),
                  }),
                );
          }
        }}
        validationSchema={EmailValidation}
      >
        {({ values, handleChange, errors, handleBlur }) => (
          <Form className="mb-6">
            <div className="flex my-4 space-x-2 sm:space-x-4 md:my-6">
              <div>
                <label htmlFor="code-1" className="sr-only">
                  First code
                </label>
                <input
                  type="text"
                  maxLength="1"
                  name="ssn_1"
                  id="code-1"
                  className="block w-12 h-12 py-3 text-2xl font-extrabold text-center text-gray-900 bg-white border border-gray-300 rounded-lg sm:py-4 sm:text-4xl sm:w-16 sm:h-16 focus:ring-primary-100 focus:border-primary-100"
                  required
                  onChange={(e) => {
                    handleChange(e);
                    handleChangeNextInput(e);
                  }}
                  value={values.ssn_1}
                  onBlur={handleBlur}
                />
              </div>
              <div>
                <label htmlFor="code-2" className="sr-only">
                  Second code
                </label>
                <input
                  type="text"
                  maxLength="1"
                  name="ssn_2"
                  id="code-2"
                  className="block w-12 h-12 py-3 text-2xl font-extrabold text-center text-gray-900 bg-white border border-gray-300 rounded-lg sm:py-4 sm:text-4xl sm:w-16 sm:h-16 focus:ring-primary-100 focus:border-primary-100"
                  required
                  onChange={(e) => {
                    handleChange(e);
                    handleChangeNextInput(e);
                  }}
                  value={values.ssn_2}
                  onBlur={handleBlur}
                />
              </div>
              <div>
                <label htmlFor="code-3" className="sr-only">
                  Third code
                </label>
                <input
                  type="text"
                  maxLength="1"
                  name="ssn_3"
                  id="code-3"
                  className="block w-12 h-12 py-3 text-2xl font-extrabold text-center text-gray-900 bg-white border border-gray-300 rounded-lg sm:py-4 sm:text-4xl sm:w-16 sm:h-16 focus:ring-primary-100 focus:border-primary-100"
                  required
                  onChange={(e) => {
                    handleChange(e);
                    handleChangeNextInput(e);
                  }}
                  value={values.ssn_3}
                  onBlur={handleBlur}
                />
              </div>
              <span> </span>
              <span> </span>
              <div>
                <label htmlFor="code-4" className="sr-only">
                  Fourth code
                </label>
                <input
                  type="text"
                  maxLength="1"
                  name="ssn_4"
                  id="code-4"
                  className="block w-12 h-12 py-3 text-2xl font-extrabold text-center text-gray-900 bg-white border border-gray-300 rounded-lg sm:py-4 sm:text-4xl sm:w-16 sm:h-16 focus:ring-primary-100 focus:border-primary-100"
                  required
                  onChange={(e) => {
                    handleChange(e);
                    handleChangeNextInput(e);
                  }}
                  value={values.ssn_4}
                  onBlur={handleBlur}
                />
              </div>
              <div>
                <label htmlFor="code-5" className="sr-only">
                  Fifth code
                </label>
                <input
                  type="text"
                  maxLength="1"
                  name="ssn_5"
                  id="code-5"
                  className="block w-12 h-12 py-3 text-2xl font-extrabold text-center text-gray-900 bg-white border border-gray-300 rounded-lg sm:py-4 sm:text-4xl sm:w-16 sm:h-16 focus:ring-primary-100 focus:border-primary-100"
                  required
                  onChange={(e) => {
                    handleChange(e);
                    handleChangeNextInput(e);
                  }}
                  value={values.ssn_5}
                  onBlur={handleBlur}
                />
              </div>

              <div>
                <label htmlFor="code-6" className="sr-only">
                  Sixth code
                </label>
                <input
                  type="text"
                  maxLength="1"
                  name="ssn_6"
                  id="code-6"
                  className="block w-12 h-12 py-3 text-2xl font-extrabold text-center text-gray-900 bg-white border border-gray-300 rounded-lg sm:py-4 sm:text-4xl sm:w-16 sm:h-16 focus:ring-primary-100 focus:border-primary-100"
                  required
                  onChange={(e) => {
                    handleChange(e);
                    handleChangeNextInput(e);
                  }}
                  onBlur={handleBlur}
                  value={values.ssn_6}
                />
              </div>
            </div>
            <button
              type="submit"
              disabled={
                errors.ssn_1 ||
                errors.ssn_2 ||
                errors.ssn_3 ||
                errors.ssn_4 ||
                errors.ssn_5 ||
                errors.ssn_6
              }
              className={
                errors.ssn_1 ||
                errors.ssn_2 ||
                errors.ssn_3 ||
                errors.ssn_4 ||
                errors.ssn_5 ||
                errors.ssn_6
                  ? "w-full focus:outline-none text-gray-900 bg-gray-300 hover:bg-gray-400 font-medium rounded-lg text-base py-3"
                  : "w-full focus:outline-none text-white bg-primary-100 hover:bg-green-500 font-medium rounded-lg text-base py-3"
              }
            >
              Verify
            </button>
          </Form>
        )}
      </Formik>
      <button
        type="button"
        className="text-primary-100 font-medium text-base"
        disabled={isDisabled}
        onClick={() => {
          setIsDisabled(true);
          if (pathname.includes("signup")) {
            return userRole.includes(userRoles.FUNDRAISER)
              ? dispatch(
                  fundraiserTwofaEnableSMS({
                    phone: `+${phoneNumber}`,
                  }),
                )
              : dispatch(
                  donorTwofaEnableSMS({
                    phone: `+${phoneNumber}`,
                  }),
                );
          }
          if (pathname.includes("login")) {
            return userRole.includes(userRoles.FUNDRAISER)
              ? dispatch(fundraiserTwofaSendSMS({ email }))
              : dispatch(donorTwofaSendSMS({ email }));
          }
          setTimeout(() => {
            setIsDisabled(false);
          }, "60000");
        }}
      >
        Resend code{" "}
        <span className="font-medium text-gray-500 text-base">
          in 15 minutes
        </span>
      </button>
    </div>
  );
};

export default TwoFactorSmsVerify;
