const createGoalProgressBar = {
  start: (createGoalStep) => {
    if (createGoalStep.includes("start")) {
      return "bg-primary-20 h-2.5 rounded-sm";
    }
    return "bg-primary-100 h-2.5 rounded-sm";
  },
  company: (createGoalStep) => {
    if (createGoalStep.includes("company")) {
      return "bg-primary-20 h-2.5 rounded-sm";
    }
    if (
      createGoalStep.includes("general") ||
      createGoalStep.includes("funding") ||
      createGoalStep.includes("preview")
    ) {
      return "bg-primary-100 h-2.5 rounded-sm";
    }
    return "bg-gray-100 h-2.5 rounded-sm";
  },
  generalInfo: (createGoalStep) => {
    if (createGoalStep.includes("general")) {
      return "bg-primary-20 h-2.5 rounded-sm";
    }
    if (
      createGoalStep.includes("funding") ||
      createGoalStep.includes("preview")
    ) {
      return "bg-primary-100 h-2.5 rounded-sm";
    }
    return "bg-gray-100 h-2.5 rounded-sm";
  },
  fundingInfo: (createGoalStep) => {
    if (createGoalStep.includes("funding")) {
      return "bg-primary-20 h-2.5 rounded-sm";
    }
    if (createGoalStep.includes("preview")) {
      return "bg-primary-100 h-2.5 rounded-sm";
    }
    return "bg-gray-100 h-2.5 rounded-sm";
  },
  preview: (createGoalStep) => {
    if (createGoalStep.includes("preview")) {
      return "bg-primary-20 h-2.5 rounded-sm";
    }
    return "bg-gray-100 h-2.5 rounded-sm";
  },
};

export default createGoalProgressBar;
