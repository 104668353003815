import React from "react";
import { Avatar } from "flowbite-react";
import { formatDistanceToNowStrict, fromUnixTime } from "date-fns";
import uuid from "react-uuid";

const DonationsList = ({ limitedDonations, fundraise, isLoading }) => {
  return (
    <div>
      {limitedDonations?.rows.map((donation) => {
        return (
          donation.wallet.donor &&
          !isLoading && (
            <div
              className="flex flex-col justify-start items-start mt-6"
              key={uuid()}
            >
              <div className="pb-4 border-b-2 border-gray-200 w-full flex justify-between items-center">
                <div className="flex justify-start gap-4 items-center">
                  {donation?.wallet?.donor?.avatarUrl ? (
                    <img
                      className="w-[32px] h-[32px] rounded-full object-cover"
                      src={donation.wallet.donor.avatarUrl}
                      alt="donor avatar"
                    />
                  ) : (
                    <Avatar rounded size="md" />
                  )}
                  <div>
                    {donation?.wallet?.donor?.firstName &&
                    donation?.wallet?.donor?.lastName ? (
                      <h4 className="text-base font-semibold">
                        {donation.wallet.donor.firstName}{" "}
                        {donation.wallet.donor.lastName}
                      </h4>
                    ) : (
                      <h4 className="text-base font-semibold">Anonymous</h4>
                    )}
                    <h5 className="text-xs font-normal text-gray-500">
                      {formatDistanceToNowStrict(
                        new Date(fromUnixTime(donation.createdAt)),
                      )}{" "}
                      ago
                    </h5>
                  </div>
                </div>{" "}
                <div className="text-base font-semibold">
                  {donation.amount} {fundraise.fundraise.currency}
                </div>
              </div>
            </div>
          )
        );
      })}
    </div>
  );
};

export default DonationsList;
