import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import AuthForm from "../../components/auth/AuthForm";
import TwoFactorSmsVerify from "../../components/auth/2FA/TwoFactorSMSVerify";
import TwoFactorAppVerify from "../../components/auth/2FA/TwoFactorAppVerify";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  return (
    <Routes>
      <Route
        path="form"
        element={<AuthForm setPassword={setPassword} setEmail={setEmail} />}
      />
      <Route
        path="two-factor/verify/sms"
        element={<TwoFactorSmsVerify email={email} password={password} />}
      />
      <Route
        path="two-factor/verify/app"
        element={<TwoFactorAppVerify email={email} password={password} />}
      />
    </Routes>
  );
};

export default LoginPage;
