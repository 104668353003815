import React from "react";

const Linkedin = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_964_34191)">
        <path
          d="M3.72448 5.14734L0.512459 5.14734C0.369904 5.14734 0.254395 5.2629 0.254395 5.4054L0.254395 15.7243C0.254395 15.8668 0.369905 15.9823 0.51246 15.9823L3.72449 15.9823C3.86704 15.9823 3.98255 15.8668 3.98255 15.7243L3.98255 5.4054C3.98255 5.2629 3.86704 5.14734 3.72448 5.14734Z"
          fill="#1F2A37"
        />
        <path
          d="M2.11954 0.0175779C0.950813 0.017578 8.30325e-08 0.967359 1.85093e-07 2.13479C2.87198e-07 3.30274 0.950813 4.25288 2.11954 4.25288C3.28733 4.25288 4.23737 3.30269 4.23737 2.13479C4.23742 0.967358 3.28733 0.0175778 2.11954 0.0175779Z"
          fill="#1F2A37"
        />
        <path
          d="M11.8943 4.89087C10.6043 4.89087 9.65061 5.44545 9.07219 6.07559L9.07219 5.4054C9.07219 5.26289 8.95668 5.14733 8.81412 5.14733L5.73804 5.14733C5.59549 5.14733 5.47998 5.2629 5.47998 5.4054L5.47998 15.7243C5.47998 15.8668 5.59549 15.9823 5.73805 15.9823L8.94305 15.9823C9.08561 15.9823 9.20112 15.8668 9.20112 15.7243L9.20112 10.6188C9.20112 8.8984 9.66842 8.22816 10.8677 8.22816C12.1738 8.22816 12.2776 9.30264 12.2776 10.7073L12.2776 15.7243C12.2776 15.8669 12.3931 15.9824 12.5357 15.9824L15.7419 15.9824C15.8844 15.9824 15.9999 15.8669 15.9999 15.7243L15.9999 10.0642C15.9999 7.50604 15.5121 4.89087 11.8943 4.89087Z"
          fill="#1F2A37"
        />
      </g>
      <defs>
        <clipPath id="clip0_964_34191">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Linkedin;
