import * as Yup from "yup";

const AuthValidation = () =>
  Yup.object().shape({
    email: Yup.string().email("Please enter a valid email").required(),
    password: Yup.string()
      .required()
      .min(8, "8 or more characters required")
      .matches(/^.*[A-Z]/, "upperCase required")
      .matches(/[a-z]/, "lowerCase required")
      .matches(/[0-9]/, "number required")
      .matches(/[#$&_"'!><.,?/@%*)^:;|}{(+=-]/, "symbol required")
      .matches(/^\S*$/, "withoutSpaceOnly"),
  });

export default AuthValidation;
