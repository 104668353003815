import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import AuthForm from "../../components/auth/AuthForm";
import EmailVerification from "../../components/auth/EmailVerification";
import CreateDonorProfile from "../../components/auth/createProfile/CreateDonorProfile";
import CreateFundraiserProfile from "../../components/auth/createProfile/CreateFundraiserProfile";
import WalletAddress from "../../components/auth/wallet/WalletAddress";
import CreateProfileWithSocialNetwork from "../../components/auth/createProfile/CreateProfileWithSocialNetwork";
import TwoFactorAuthenticationOptions from "../../components/auth/2FA/TwoFactorAuthenticationOptions";
import TwoFactorPhoneNumberInput from "../../components/auth/2FA/TwoFactorPhoneNumberInput";
import TwoFactorSmsVerify from "../../components/auth/2FA/TwoFactorSMSVerify";
import TwoFactorAppVerify from "../../components/auth/2FA/TwoFactorAppVerify";

const SignUpPage = () => {
  const [phoneNumber, setPhoneNumber] = useState("");

  return (
    <Routes>
      <Route path="form" element={<AuthForm />} />
      <Route path="verification" element={<EmailVerification />} />
      <Route
        path="two-factor-options"
        element={<TwoFactorAuthenticationOptions />}
      />
      <Route
        path="two-factor-options/phone"
        element={
          <TwoFactorPhoneNumberInput
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
          />
        }
      />
      <Route path="two-factor-options/app" element={<TwoFactorAppVerify />} />
      <Route
        path="two-factor-options/phone/code"
        element={<TwoFactorSmsVerify phoneNumber={phoneNumber} />}
      />
      <Route path="donorProfile" element={<CreateDonorProfile />} />
      <Route path="fundraiserProfile" element={<CreateFundraiserProfile />} />
      <Route path="socialAuth" element={<CreateProfileWithSocialNetwork />} />
      <Route path="connect-wallet" element={<WalletAddress />} />
    </Routes>
  );
};

export default SignUpPage;
