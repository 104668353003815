import React from "react";

const Logo = () => {
  return (
    <svg
      width="78"
      height="39"
      viewBox="0 0 78 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.754 30.5381H6.74512C7.07528 24.593 10.3123 19.8939 14.2494 19.8939C18.1866 19.8939 21.4236 24.593 21.754 30.5381Z"
        fill="#30D893"
      />
      <path
        d="M6.74477 30.538C7.07512 24.5929 10.3119 19.8939 14.249 19.8939C18.1865 19.8939 21.4235 24.5929 21.7536 30.538H6.74477ZM18.1203 19.144C22.4671 17.4451 25.585 12.9867 25.585 7.74926H23.7356C23.7356 13.3544 19.4811 17.9123 14.249 17.9123H9.46655C5.09319 17.9123 1.34116 22.2152 0.336914 28.358L2.16036 28.6982C3.00574 23.5457 5.96534 19.9506 9.4004 19.8939C6.69181 22.2859 4.86875 26.6033 4.86875 31.5291V32.5196H10.5104V36.6807H7.498V38.6621H12.3598V32.6756H16.1386V38.6621H21.0004V36.6807H17.9882V32.5196H23.6297V31.5291C23.6297 26.0366 21.3705 21.2954 18.1203 19.144Z"
        fill="#206B9F"
      />
      <path
        d="M16.1386 20.2774C15.5339 20.0342 14.9026 19.8939 14.2494 19.8939C10.3121 19.8939 7.07528 24.5928 6.74512 30.5381H10.5235C10.799 25.5792 13.0994 21.4987 16.1386 20.2774Z"
        fill="#29B277"
      />
      <path
        d="M16.6408 12.7316C17.0239 11.585 17.2353 10.1696 17.3015 8.7404H19.4155C19.1247 10.4952 18.0677 11.9388 16.6408 12.7316ZM13.0472 6.75883C13.1925 3.8851 13.9324 2.29964 14.1966 2.13026L14.2098 2.11572C14.5535 2.29964 15.2932 3.87137 15.4519 6.75883H13.0472ZM14.3023 13.3827H14.2757C13.9456 13.2133 13.1925 11.6279 13.0472 8.7404H15.4519C15.3064 11.6279 14.5665 13.1988 14.3023 13.3827ZM11.1974 6.75883H9.08357C9.37416 5.01792 10.4314 3.55965 11.8581 2.76682C11.475 3.91336 11.2637 5.32882 11.1974 6.75883ZM9.08357 8.7404H11.1974C11.2637 10.1696 11.475 11.585 11.8581 12.7316C10.4314 11.9388 9.37416 10.4952 9.08357 8.7404ZM19.4155 6.75883H17.3015C17.2353 5.32882 17.0239 3.91336 16.6408 2.76682C18.0677 3.55965 19.1247 5.01792 19.4155 6.75883ZM14.2494 0.134354C10.3255 0.134354 7.1416 3.55965 7.1416 7.74931C7.1416 11.9533 10.3255 15.3643 14.2494 15.3643C18.1734 15.3643 21.3575 11.9533 21.3575 7.74931C21.3575 3.55965 18.1734 0.134354 14.2494 0.134354Z"
        fill="#206B9F"
      />
      <path
        d="M19.4154 6.75879H17.3013C17.2352 5.32899 17.0239 3.91332 16.6406 2.76678C18.0678 3.55961 19.1246 5.01788 19.4154 6.75879Z"
        fill="#30D893"
      />
      <path
        d="M15.4517 6.75879H13.0469C13.1924 3.88506 13.932 2.2996 14.1964 2.13022L14.2096 2.11588C14.5532 2.2996 15.293 3.87133 15.4517 6.75879Z"
        fill="#30D893"
      />
      <path
        d="M11.8576 2.76678C11.4746 3.91332 11.2632 5.32878 11.1968 6.75879H9.08301C9.3736 5.01788 10.4308 3.55961 11.8576 2.76678Z"
        fill="#30D893"
      />
      <path
        d="M11.8576 12.7314C10.4308 11.9386 9.3736 10.4951 9.08301 8.74025H11.1968C11.2632 10.1694 11.4746 11.5849 11.8576 12.7314Z"
        fill="#30D893"
      />
      <path
        d="M15.4517 8.74027C15.3062 11.6277 14.5663 13.1989 14.3019 13.3826H14.2756C13.9454 13.2132 13.1924 11.6277 13.0469 8.74027H15.4517Z"
        fill="#30D893"
      />
      <path
        d="M19.4154 8.7405C19.1246 10.4953 18.0678 11.9391 16.6406 12.7317C17.0239 11.5851 17.2352 10.1697 17.3013 8.7405H19.4154Z"
        fill="#30D893"
      />
      <path
        d="M40.5515 20.1539V28.4785H38.3494V20.2891C38.3494 18.0929 37.1595 16.8179 35.211 16.8179C33.0079 16.8179 31.5399 18.2553 31.5399 21.2922V28.4785H29.3379V9.49623H31.5399V16.872C32.426 15.3266 33.7933 14.5666 35.6412 14.5666C38.6019 14.5666 40.5515 16.7091 40.5515 20.1539Z"
        fill="#206B9F"
      />
      <path
        d="M44.9046 20.7219H53.3599C52.9801 18.1462 51.3086 16.7901 49.2843 16.7901C46.9303 16.7901 45.2845 18.3364 44.9046 20.7219ZM44.9046 22.7829C45.3347 25.1973 47.0823 26.6072 49.4865 26.6072C51.1566 26.6072 52.3723 25.9561 53.1319 24.6275L55.0044 25.7658C53.7645 27.8006 51.9166 28.8311 49.4362 28.8311C47.4363 28.8311 45.7907 28.1523 44.5261 26.8239C43.2846 25.4678 42.6523 23.7597 42.6523 21.6986C42.6523 19.6651 43.2601 17.9557 44.5003 16.6009C45.7404 15.2448 47.3346 14.566 49.2843 14.566C51.1322 14.566 52.6505 15.271 53.8146 16.6822C55.0044 18.0646 55.5866 19.7464 55.5866 21.7261C55.5866 22.078 55.5619 22.4313 55.5107 22.7829H44.9046Z"
        fill="#206B9F"
      />
      <path
        d="M63.9726 11.4201C61.7448 11.231 60.631 12.2615 60.631 14.5117V14.9187H63.9726V17.1964H60.631V28.4775H58.4289V17.1964H56.4043V14.9187H58.4289V14.5117C58.4289 10.8503 60.4029 8.89823 63.9726 9.14236V11.4201Z"
        fill="#206B9F"
      />
      <path
        d="M66.7933 20.7219H75.2486C74.8686 18.1462 73.1973 16.7901 71.1727 16.7901C68.8188 16.7901 67.1731 18.3364 66.7933 20.7219ZM66.7933 22.7829C67.2234 25.1973 68.9708 26.6072 71.375 26.6072C73.0453 26.6072 74.261 25.9561 75.0205 24.6275L76.8931 25.7658C75.653 27.8006 73.805 28.8311 71.3247 28.8311C69.3248 28.8311 67.6792 28.1523 66.4145 26.8239C65.1732 25.4678 64.541 23.7597 64.541 21.6986C64.541 19.6651 65.1486 17.9557 66.3889 16.6009C67.6289 15.2448 69.2233 14.566 71.1727 14.566C73.0206 14.566 74.5392 15.271 75.703 16.6822C76.8931 18.0646 77.4751 19.7464 77.4751 21.7261C77.4751 22.078 77.4506 22.4313 77.3992 22.7829H66.7933Z"
        fill="#206B9F"
      />
    </svg>
  );
};

export default Logo;
