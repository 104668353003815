import { configureStore } from "@reduxjs/toolkit";
import fundraiseSlice from "./reducers/fundraiseSlice";
import userSlice from "./reducers/userSlice";
import donationsSlice from "./reducers/donationsSlice";
import analyticSlice from "./reducers/analyticSlice";

function createMyStore() {
  return configureStore({
    reducer: {
      user: userSlice,
      fundraise: fundraiseSlice,
      donations: donationsSlice,
      analytic: analyticSlice,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }),
  });
}

export const store = createMyStore();
