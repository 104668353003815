/* eslint-disable */
const createFundraiseSteps = {
  START: "start",
  COMPANY: "company",
  GENERALINFO: "general",
  FUNDINGINFO: "funding",
  PREVIEW: "preview",
  FINISH: "finish",
};

export default createFundraiseSteps;
