import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../Footer";
import MainHeader from "../MainHeader";
import { checkAuth, getCurrentUser } from "../../store/reducers/userSlice";

const ProtectedMainLayout = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [isAuth, setIsAuth] = useState(true);

  useEffect(() => {
    dispatch(
      checkAuth({
        onSuccess: () => setIsAuth(true),
        onFailure: () => setIsAuth(false),
      }),
    );
    dispatch(getCurrentUser());
  }, [dispatch]);

  return isAuth ? (
    <>
      <MainHeader />
      <main>
        <section className="max-w-[1440px] max-2xl:mx-[50px] mx-auto">
          <Outlet />
        </section>
      </main>
      {!pathname.includes("create-goal") && <Footer />}
    </>
  ) : (
    window.location.replace("/")
  );
};

export default ProtectedMainLayout;
