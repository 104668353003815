import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ImageUploading from "react-images-uploading";
import { Avatar } from "flowbite-react";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import countries from "../../constans/countries";
import userRoles from "../../constans/userRoles";
import {
  getCurrentUser,
  updateDonor,
  updateDonorAvatar,
  updateFundraiser,
  updateFundraiserAvatar,
} from "../../store/reducers/userSlice";

const UserProfile = () => {
  const currentUser = useSelector((state) => state.user.currentUser);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [images, setImages] = useState([]);
  const onChange = (imageList) => setImages(imageList);

  const uploadAvatar = async () => {
    const formData = new FormData();
    formData.append("name", images[0].file.name);
    formData.append("avatar", images[0].file);
    if (currentUser.role === userRoles.FUNDRAISER)
      await dispatch(
        updateFundraiserAvatar({
          avatar: formData,
        }),
      );
    if (currentUser.role === userRoles.DONOR)
      await dispatch(
        updateDonorAvatar({
          avatar: formData,
        }),
      );
  };

  const onSuccessSubmit = () => {
    dispatch(getCurrentUser());
  };
  /* eslint-disable no-nested-ternary */

  // for the future functionality of change password flow - change password modal in the end of this component
  // const [isOpenChangePasswordModal, setIsOpenChangePasswordModal] =
  //   useState(false);

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        country: "",
        bio: "",
      }}
      onSubmit={async (values, formikHelpers) => {
        if (images.length) await uploadAvatar();
        if (currentUser.role === userRoles.FUNDRAISER)
          await dispatch(
            updateFundraiser({
              userData: {
                firstName: values.firstName
                  ? values.firstName
                  : currentUser.firstName || "",
                lastName: values.lastName
                  ? values.lastName
                  : currentUser.lastName || "",
                country: values.country
                  ? values.country
                  : currentUser.country || "",
                bio: values.bio ? values.bio : currentUser.bio || "",
              },
              onSuccess: () => {
                formikHelpers.resetForm();
                onSuccessSubmit();
              },
            }),
          );

        if (currentUser.role === userRoles.DONOR)
          await dispatch(
            updateDonor({
              userData: {
                firstName: values.firstName
                  ? values.firstName
                  : currentUser.firstName || "",
                lastName: values.lastName
                  ? values.lastName
                  : currentUser.lastName || "",
                country: values.country
                  ? values.country
                  : currentUser.country || "",
              },
              onSuccess: () => {
                formikHelpers.resetForm();
                onSuccessSubmit();
              },
            }),
          );
      }}
    >
      {({ values, handleChange, handleBlur }) => (
        <Form>
          <div className="w-1/3 mx-auto">
            <h2 className="font-bold text-2xl text-gray-800 mb-6">
              Account settings
            </h2>
            <div className="bg-white rounded-md shadow p-8">
              <div className="space-y-4">
                <div className="space-y-4 mb-10 flex flex-col justify-center items-center">
                  {currentUser?.avatarUrl || images[0]?.data_url ? (
                    <img
                      src={images[0]?.data_url || currentUser?.avatarUrl}
                      alt="avatar"
                      className="w-[140px] h-[140px] object-cover rounded-full"
                    />
                  ) : (
                    !currentUser?.firstName &&
                    !currentUser?.lastName && (
                      <div className="flex justify-start">
                        <Avatar size="xl" rounded />{" "}
                      </div>
                    )
                  )}
                  {currentUser?.firstName &&
                    currentUser?.lastName &&
                    !images?.length &&
                    !currentUser?.avatarUrl && (
                      <div className="w-[140px] h-[140px] rounded-full bg-gray-500 flex items-center justify-center text-center text-7xl font-medium text-white">
                        {currentUser.firstName.split("").shift() +
                          currentUser.lastName.split("").shift()}
                      </div>
                    )}
                  <ImageUploading
                    value={images}
                    onChange={onChange}
                    dataURLKey="data_url"
                    acceptType={["jpg", "png"]}
                  >
                    {({ onImageUpload }) => (
                      <button
                        type="button"
                        className="bg-primary-100 rounded-md py-1.5 px-4 text-white text-base font-medium"
                        onClick={onImageUpload}
                      >
                        Upload photo
                      </button>
                    )}
                  </ImageUploading>
                </div>

                <div className="flex gap-4">
                  <div className="block w-full">
                    <label
                      className="block mb-2 text-sm font-medium text-gray-900"
                      htmlFor="firstName"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      className="border-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-2 focus:border-primary-100 block w-full p-2.5"
                      value={values?.firstName}
                      placeholder={currentUser?.firstName || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>

                  <div className="block w-full">
                    <label
                      className="block mb-2 text-sm font-medium text-gray-900 "
                      htmlFor="lastName"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      className="border-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-2 focus:border-primary-100 block w-full p-2.5"
                      placeholder={currentUser?.lastName || ""}
                      value={values?.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div>
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                    htmlFor="country"
                  >
                    Country
                  </label>
                  <select
                    name="country"
                    className="border-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-2 focus:border-primary-100 block w-full p-2.5"
                    value={values.country}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value={currentUser?.country}>
                      {currentUser?.country}
                    </option>

                    {countries.map((country) => (
                      <option key={country.id} value={country.en}>
                        {country.en}
                      </option>
                    ))}
                  </select>
                </div>
                {currentUser?.role === userRoles.FUNDRAISER && (
                  <div>
                    <label
                      className="block mb-2 text-sm font-medium text-gray-900"
                      htmlFor="bio"
                    >
                      A few words about you
                    </label>
                    <textarea
                      name="bio"
                      placeholder={currentUser?.bio || "Write text here ..."}
                      className="text-base border-2 block pt-2.5 w-full bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-primary-100 focus:border-2 block"
                      value={values.bio}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                )}
                {currentUser?.email && (
                  <button
                    type="button"
                    onClick={() => navigate("/resetPassword/email")}
                    // onClick={() => setIsOpenChangePasswordModal(true)}
                    className="text-primary-100 hover:underline"
                  >
                    Change password
                  </button>
                )}
                <div className="flex gap-8">
                  <button
                    type="button"
                    className="w-full py-2 text-base font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    className="w-full focus:outline-none text-white bg-primary-100 hover:bg-green-500 font-medium rounded-lg text-base py-2"
                  >
                    Update my account
                  </button>
                </div>
              </div>
            </div>
          </div>

          {
            /* eslint-disable */
            // for future functionality
            //<ChangePasswordModal
            //setIsOpenChangePasswordModal={setIsOpenChangePasswordModal}
            //isOpenChangePasswordModal={isOpenChangePasswordModal}
            ///>
            //<ToastContainer autoClose={3000} hideProgressBar />
          }
        </Form>
      )}
    </Formik>
  );
};

export default UserProfile;
