import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import ApiService from "../middleware/api";

const initialState = {
  currentUser: null,
};
export const getCurrentUser = createAsyncThunk(
  "user/getCurrentUser",
  async () => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/users/user`,
        method: "GET",
      });
      return data;
    } catch (error) {
      return error;
    }
  },
);

export const getDonorByMetaMaskAcc = createAsyncThunk(
  "user/getCurrentByMetaMaskAcc",
  async ({ accounts, onSuccess, onFailure }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/auth/donor/${accounts[0]}`,
        method: "GET",
      });
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      if (onFailure) onFailure();
      return rejectWithValue(error);
    }
  },
);

export const emailSignupDonor = createAsyncThunk(
  "user/emailSignupDonor",
  async ({ userData, onSuccess, onFailure }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/auth/signup/donor`,
        method: "POST",
        query: userData,
      });
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      if (onFailure) onFailure();
      return rejectWithValue(error);
    }
  },
);

export const emailSignupFundraiser = createAsyncThunk(
  "user/emailSignupFundraiser",
  async ({ userData, onSuccess, onFailure }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/auth/signup/fundraiser`,
        method: "POST",
        query: userData,
      });
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      if (onFailure) onFailure();
      return rejectWithValue(error);
    }
  },
);

export const emailLoginDonor = createAsyncThunk(
  "user/emailLoginDonor",
  async (
    { userData, onSuccess, onFailure, smsTwofa, appTwofa },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/auth/login/donor`,
        method: "POST",
        query: userData,
      });
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      if (error.response.data.twofa === "SMS" && smsTwofa) smsTwofa();
      if (error.response.data.twofa === "OTP" && appTwofa) appTwofa();
      if (onFailure) onFailure();
      return rejectWithValue(error);
    }
  },
);

export const emailLoginFundraiser = createAsyncThunk(
  "user/emailLoginFundraiser",
  async (
    { userData, onSuccess, onFailure, smsTwofa, appTwofa },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/auth/login/fundraiser`,
        method: "POST",
        query: userData,
      });
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      if (error.response.data.twofa === "SMS" && smsTwofa) smsTwofa();
      if (error.response.data.twofa === "OTP" && appTwofa) appTwofa();
      if (onFailure) onFailure();
      return rejectWithValue(error);
    }
  },
);

export const logout = createAsyncThunk(
  "user/logout",
  async ({ onSuccess, onFailure }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/auth/logout`,
        method: "GET",
      });
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      if (onFailure) onFailure();
      return rejectWithValue(error);
    }
  },
);

export const updateDonor = createAsyncThunk(
  "user/updateDonor",
  async ({ userData, onSuccess, onFailure }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/auth/update/donor`,
        method: "PUT",
        query: userData,
      });
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      if (onFailure) onFailure();
      return rejectWithValue(error);
    }
  },
);

export const updateDonorAvatar = createAsyncThunk(
  "user/updateDonorAvatar",
  async ({ avatar, onSuccess, onFailure }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/auth/update/donor/avatar`,
        method: "POST",
        query: avatar,
      });
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      if (onFailure) onFailure();
      return rejectWithValue(error);
    }
  },
);

export const updateFundraiser = createAsyncThunk(
  "user/updateFundraiser",
  async ({ userData, onSuccess, onFailure }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/auth/update/fundraiser`,
        method: "PUT",
        query: userData,
      });
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      if (onFailure) onFailure();
      return rejectWithValue(error);
    }
  },
);

export const updateFundraiserAvatar = createAsyncThunk(
  "user/updateFundraiserAvatar",
  async ({ avatar, onSuccess, onFailure }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/auth/update/fundraiser/avatar`,
        method: "POST",
        query: avatar,
      });
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      if (onFailure) onFailure();
      return rejectWithValue(error);
    }
  },
);

export const verify = createAsyncThunk("user/verify", async () => {
  try {
    const { data } = await ApiService.apiCall({
      endpoint: `/auth/verify`,
      method: "POST",
    });
    return data;
  } catch (error) {
    return error;
  }
});

export const verification = createAsyncThunk(
  "user/verification",
  async ({ code, onSuccess, onFailure }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/auth/verification`,
        method: "POST",
        query: code,
      });
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      if (onFailure) onFailure();
      return rejectWithValue(error);
    }
  },
);

export const fundraiserTwofaEnableSMS = createAsyncThunk(
  "user/FundraiserTwofaEnableSMS",
  async ({ phone, onSuccess, onFailure }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/auth/twofa/fundraiser/enable/sms`,
        method: "POST",
        query: { phone },
      });
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      if (onFailure) onFailure();
      return rejectWithValue(error);
    }
  },
);

export const fundraiserTwofaVerifySMS = createAsyncThunk(
  "user/FundraiserTwofaVerifySMS",
  async ({ phone, code, onSuccess, onFailure }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/auth/twofa/fundraiser/verify/sms`,
        method: "POST",
        query: { phone, code },
      });
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      if (onFailure) onFailure();
      return rejectWithValue(error);
    }
  },
);

export const donorTwofaEnableSMS = createAsyncThunk(
  "user/donorTwofaEnableSMS",
  async ({ phone, onSuccess, onFailure }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/auth/twofa/donor/enable/sms`,
        method: "POST",
        query: { phone },
      });
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      if (onFailure) onFailure();
      return rejectWithValue(error);
    }
  },
);

export const donorTwofaVerifySMS = createAsyncThunk(
  "user/donorTwofaVerifySMS",
  async ({ phone, code, onSuccess, onFailure }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/auth/twofa/donor/verify/sms`,
        method: "POST",
        query: { phone, code },
      });
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      if (onFailure) onFailure();
      return rejectWithValue(error);
    }
  },
);

export const donorTwofaSendSMS = createAsyncThunk(
  "user/donorTwofaSendSMS",
  async ({ email }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/auth/login/donor/send/sms`,
        method: "POST",
        query: { email },
      });

      return data;
    } catch (error) {
      return error;
    }
  },
);

export const fundraiserTwofaSendSMS = createAsyncThunk(
  "user/fundraiserTwofaSendSMS",
  async ({ email }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/auth/login/fundraiser/send/sms`,
        method: "POST",
        query: { email },
      });
      return data;
    } catch (error) {
      return error;
    }
  },
);

export const donorSocialTwofaSendSMS = createAsyncThunk(
  "user/donorSocialTwofaSendSMS",
  async () => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/auth/login/donor/send/sms/social`,
        method: "POST",
      });
      return data;
    } catch (error) {
      return error;
    }
  },
);

export const fundraiserSocialTwofaSendSMS = createAsyncThunk(
  "user/fundraiserSocialTwofaSendSMS",
  async () => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/auth/login/fundraiser/send/sms/social`,
        method: "POST",
      });
      return data;
    } catch (error) {
      return error;
    }
  },
);

export const fundraiserTwofaVerifyOTP = createAsyncThunk(
  "user/fundraiserTwofaVerifyOTP",
  async ({ code, onSuccess, onFailure }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/auth/twofa/fundraiser/verify/otp`,
        method: "POST",
        query: { code },
      });
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      if (onFailure) onFailure();
      return rejectWithValue(error);
    }
  },
);

export const donorTwofaVerifyOTP = createAsyncThunk(
  "user/donorTwofaVerifyOTP",
  async ({ code, onSuccess, onFailure }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `/auth/twofa/donor/verify/otp`,
        method: "POST",
        query: { code },
      });
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      if (onFailure) onFailure();
      return rejectWithValue(error);
    }
  },
);
export const checkAuth = createAsyncThunk(
  "user/checkAuth",
  async ({ onSuccess, onFailure }) => {
    try {
      const { data } = await axios.post(
        "https://hefetest.com/api/v1/auth/refresh",
        {},
        { withCredentials: true },
      );
      onSuccess();
      return data;
    } catch (error) {
      onFailure();
      return error;
    }
  },
);

const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: {
    [getCurrentUser.fulfilled]: (state, { payload }) => {
      state.currentUser = { ...payload };
    },
    [getDonorByMetaMaskAcc.fulfilled]: (state, { payload }) => {
      state.currentUser = { ...payload };
    },
    [emailLoginDonor.fulfilled]: (state, { payload }) => {
      state.currentUser = { ...payload };
    },
    [emailLoginFundraiser.fulfilled]: (state, { payload }) => {
      state.currentUser = { ...payload };
    },
    [logout.fulfilled]: (state) => {
      state.currentUser = null;
    },
  },
});

export default userSlice.reducer;
