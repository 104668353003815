import React from "react";

const NoFundraisersCard = () => {
  return (
    <div className="w-full flex justify-center h-full md:h-auto mt-12">
      <div className="p-4 bg-white rounded-lg shadow flex flex-col justify-center min-w-[370px] items-center text-center gap-2">
        <h2 className="text-lg font-medium text-gray-500">No fundraisers</h2>
        <svg
          className="w-10 h-10 mx-auto text-gray-500"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
    </div>
  );
};

export default NoFundraisersCard;
