import React, { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { formatDistanceToNowStrict, fromUnixTime } from "date-fns";
import Ellipse from "../../svg/ellipse/Ellipse";
import BlackBadgeCalendar from "../../svg/badgeCalendar/BlackBadgeCalendar";
import AnalyticCard from "./AnalyticCard";
import TotalDonationAnalyticIcon from "../../svg/analytics/TotalDonationAnalyticIcon";
import TopDonate from "../../svg/analytics/TopDonate";
import TopDonateCountry from "../../svg/analytics/TopDonateCountry";
import TotalDonors from "../../svg/analytics/TotalDonors";
import { AnalyticDonutChart } from "./AnalyticDonutChart";
import BackArrow from "../../svg/analytics/BackArrow";
import AnalyticOfFundraiseTable from "./AnalyticOfFundraiseTable";
import { getFundraiseById } from "../../store/reducers/fundraiseSlice";
import {
  getAllDonations,
  getLimitedDonationByFundraise,
} from "../../store/reducers/donationsSlice";
import {
  currentFundraiseAnalytic,
  currentFundraiseCountriesAnalytic,
} from "../../store/reducers/analyticSlice";

const AnalyticOfFundraise = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const fundraise = useSelector((state) => state.fundraise.fundraise);
  const limitedDonations = useSelector(
    (state) => state.donations.limitedDonationsByFundraise,
  );
  const allDonations = useSelector(
    (state) => state.donations.allDonationsByFundraise,
  );
  const fundraiseAnalytic = useSelector(
    (state) => state.analytic.currentFundraise,
  );

  const donutAnalytic = useSelector(
    (state) => state.analytic.countriesAnalytic,
  );

  useEffect(() => {
    if (fundraise.id) {
      dispatch(
        getLimitedDonationByFundraise({
          id: fundraise?.fundraise?.idAtContract,
          limit: 5,
          onSuccess: () => {
            dispatch(
              getAllDonations({
                id: fundraise?.fundraise?.idAtContract,
                ignoreFundraiseAttributes: true,
              }),
            );
          },
        }),
      );
    }
    if (fundraise?.fundraise?.idAtContract) {
      dispatch(currentFundraiseAnalytic(fundraise?.fundraise?.idAtContract));
      dispatch(
        currentFundraiseCountriesAnalytic({
          idAtContract: fundraise?.fundraise?.idAtContract,
        }),
      );
    }
  }, [dispatch, fundraise.id, fundraise?.fundraise?.idAtContract]);

  useEffect(() => {
    dispatch(getFundraiseById({ id }));
  }, [id, dispatch]);

  const [donutCountries, setDonutCountries] = useState([]);
  const [donutPercentage, setDonutPercentage] = useState([]);

  useMemo(() => {
    setDonutCountries(donutAnalytic.map(({ country }) => country));
    setDonutPercentage(donutAnalytic.map(({ percentage }) => percentage * 100));
  }, [donutAnalytic]);

  return (
    <div className="w-3/4 mx-auto">
      <Link className="flex gap-2 items-center mb-4" to="/analytics">
        <BackArrow />
        <h3 className="text-base font-medium text-gray-800">
          Back to all analytics
        </h3>
      </Link>
      {fundraise.id && (
        <div className="mb-6">
          <h2 className="font-bold text-3xl text-gray-900 mb-5">
            {fundraise?.title}
          </h2>
          <div className="flex items-center">
            <div className="flex gap-2">
              <img
                src={fundraise?.fundraiser?.avatarUrl}
                alt="avatar"
                className="h-[25px] w-[25px] rounded-full object-cover"
              />
              <p className="text-gray-600 text-base font-medium">
                {fundraise?.fundraiser?.firstName}{" "}
                {fundraise?.fundraiser?.lastName}
              </p>
            </div>
            <Ellipse />
            <div className="text-sm font-medium text-white bg-primary-100 px-3 py-0.5 rounded-md select-none">
              {fundraise?.categories?.[0]?.name}
            </div>
            <Ellipse />
            <div className="flex gap-1 items-center text-sm font-medium text-gray-800 bg-gray-100 px-3 py-0.5 rounded-md select-none">
              <BlackBadgeCalendar />
              <span>
                {" "}
                {fundraise.deadlineDays ? (
                  <span>{fundraise?.deadlineDays} days left</span>
                ) : (
                  <span>
                    {formatDistanceToNowStrict(
                      new Date(fromUnixTime(fundraise?.deadlineTimestamp)),
                    )}{" "}
                    left
                  </span>
                )}
              </span>
            </div>
            <Ellipse />
            <div className="flex gap-1 items-center text-sm font-medium text-gray-800 bg-gray-100 px-3 py-0.5 rounded-md select-none">
              {fundraise?.goalType?.[0]}
              {fundraise?.goalType?.slice(1)?.toLowerCase()}
            </div>
          </div>
        </div>
      )}

      <div className="flex gap-6 justify-center mb-8">
        <AnalyticCard
          title={`Total donation (${fundraise?.goalCurrency})`}
          amount={
            <>
              <div className="text-base font-normal text-gray-500 mb-2">
                <span className="text-base font-medium text-gray-800">
                  {fundraiseAnalytic?.totalDonationsAmount || 0}{" "}
                  {fundraise.goalCurrency}{" "}
                </span>
                raised of {fundraise?.goal} {fundraise?.goalCurrency}
              </div>
              <div className="w-full bg-gray-100 rounded-md">
                <div
                  className="bg-primary-100 h-1.5 rounded-md"
                  style={{
                    width: `${
                      fundraiseAnalytic?.totalDonationsAmount && fundraise?.goal
                        ? (fundraiseAnalytic.totalDonationsAmount * 100) /
                          fundraise.goal
                        : 0
                    }%`,
                  }}
                />
              </div>
            </>
          }
          icon={<TotalDonationAnalyticIcon />}
        />
        <AnalyticCard
          title={`Top donate (${fundraise?.goalCurrency})`}
          amount={fundraiseAnalytic?.topDonationAmount || 0}
          icon={<TopDonate />}
        />
        <AnalyticCard
          title="Top donate country"
          amount={fundraiseAnalytic?.topCountry || "-"}
          icon={<TopDonateCountry />}
        />
        <AnalyticCard
          title="Total donors"
          amount={fundraiseAnalytic?.donorsCount || 0}
          icon={<TotalDonors />}
        />
      </div>

      {donutCountries?.length && donutPercentage?.length ? (
        <div className="flex flex-wrap items-center justify-center gap-4 items-start mb-4">
          <div className="w-full shadow p-2 rounded-md bg-white">
            <h2 className="text-center text-2xl font-bold text-gray-800 py-6">
              Donation countries
            </h2>
            <AnalyticDonutChart
              dataArr={donutPercentage}
              margin={100}
              labels={donutCountries}
            />
          </div>
        </div>
      ) : (
        ""
      )}

      {limitedDonations?.rows?.length ? (
        <AnalyticOfFundraiseTable
          limitedDonations={limitedDonations}
          allDonations={allDonations}
          fundraise={fundraise}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default AnalyticOfFundraise;
